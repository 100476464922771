<script type="text/javascript"></script>

<template> 
	<div class="mb-3 p-2 editlink" :id="rowkey">

		<label :for="'rich_'+item._id" class="form-label" >{{ item.name }}  <a href="#" v-if="editmode!=rowkey && canwrite" class="editicon" @click.stop.prevent="toeditmode(rowkey, item._id)"><i class="ml-2 far fa-pencil"></i></a></label>

		<div @click.stop.prevent="toeditmode(rowkey)" v-if="editmode!=rowkey" class="border p-2 rounded " style="border-color: var(--N200); min-height: 120px; background-color: #fff" v-html="itemvalue"></div>
		<froala v-show="editmode==rowkey" v-if="loadfroala" id="statuscommenteditor" :config="froalaconfig" v-model="itemvalue"></froala>

		<input type="hidden" :name="item._id" v-model="itemvalue" v-if="haschanged">

		<div class="text-right mb-2 mt-2" v-if="editmode==rowkey">
			<button type="button" class="btn btn-secondary" @click.stop.prevent="canceledit">Cancel</button> <button type="button" class="btn btn-primary" @click.stop.prevent="onsavevalues">Save</button>
		</div>

	</div>
</template> 

<style type="text/css">

	.stillediting .fr-wrapper{
		border-right-color: var(--R500) !important;
		border-left-color: var(--R500) !important;
		border-radius: 0 !important;
	}
	.stillediting .fr-toolbar.fr-top{
		border-top-color: var(--R500);
		border-right-color: var(--R500);
		border-left-color: var(--R500);
	}
	.stillediting .fr-second-toolbar{
		border-bottom-color: var(--R500);
		border-right-color: var(--R500);
		border-left-color: var(--R500);
	}
	
	.haschanges{
		font-weight: bold;
		color: red;
	}

</style>

<script>
	//:onManualControllerReady="initializefroala" 
	import Portfolioapi from '@/services/api/portfolio'

	export default{
		name:'pRich',
		props:{
			canwrite:Boolean,
			viewmode:String,
			projectid:String,
			item:Object,
			s3token:Object,
			editmode:String,
			rowkey:String,			
		},

		data(){
			return{
				haschanged:false,
				portfolios:[],
				name:"",
				error:"",
				tmr:null,
				mode:'',
				itemvalue:'',
				origvalue:'',
				inputname:null,
				linkInitControls : null,
				imageuploading:false,
				loadfroala:false,
				froalaconfig : {
					fileUploadToS3 : true,
					toolbarSticky: true,
					imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'rotateImg'],
					pasteDeniedAttrs: ['style', 'class', 'id', 'width', 'height'],
					htmlAllowedTags: ['p', 'div', 'span', 'i', 'u', 'a' ],
					imageInsertButtons: ['imageBack','|','imageUpload'],
					imageUpload: false,
					requestWithCORS: false,
					heightMin:200,
					heightMax :'none',
					imageUploadToS3:this.s3token,      
					key: '0BA3jA6D7C5F5gA-9mnE2fmmtaG4ohtA2B2C2B1C5F1F1F1h3B9==',
					attribution: false,
					linkAlwaysBlank:true,
					listAdvancedTypes: false,
					wordPasteModal: false,
					wordPasteKeepFormatting: false,
					toolbarButtons : [ 'bold', 'italic', 'underline', '|', 'insertFile', 'insertLink']
					}
			}
		},

		 mounted() {

		 	let self = this

		 	//console.log( editorconfig.imageUploadToS3 , "editorconfigxxx")

	 		self.froalaconfig.imageUploadToS3 = self.s3token
        	self.froalaconfig.fileUploadToS3 = self.s3token
        	self.froalaconfig.events = { 'contentChanged': function(){
        		self.haschanged = true
        	} }
       	

        	setTimeout( function(){ self.loadfroala = true }, 100)
		 	

		 	this.gettext()

		 	/*let self = this

	        this.froalaconfig = froalaconfig
	        this.froalaconfig.placeholderText = 'Add description…'
	        this.froalaconfig.heightMax = 400
	        this.froalaconfig.events = {
	          'image.beforeUpload': function (images, lo ) {
	            self.imageuploading = true        
	          },
	          'image.inserted': function ($img, response) {
	            self.imageuploading = false
	          }
	        }

	        */
		 },

		watch:{

			/*item : function( e ){
				alert(e)
			}*/
			
		},
		methods:{

			canceledit(){

				let self = this

				$('.stillediting').removeClass('stillediting')
				
				this.itemvalue = this.origvalue
				this.$emit('oneditmode', '')

				this.haschanged = false

				setTimeout( function(){
					self.haschanged = false
				}, 100)
			},

			onsavevalues(){
				let self = this

				$('.stillediting').removeClass('stillediting')
				
				/*setTimeout( function(){
					self.haschanged = false
				}, 100)*/

				//this.itemvalue = this.origvalue
				this.$emit('onsavevalues', function(){
					self.gettext()
					self.$emit('oneditmode', '')
					self.haschanged = false
				})
			},

			initializefroala: function(initControls) {
		        this.linkInitControls = initControls;
		      },

			toeditmode( toedit ){

				if( !this.canwrite ){

					let toview = document.getElementById(this.editmode)

					if( toview ){
						toview.classList.add("stillediting");
						toview.scrollIntoView();
					}

				
					return false
				}

				this.$emit('oneditmode', toedit, 'rich')
			},


			dosave( element ){

				this.editmode = ''				
				this.$emit('onformsaved', {"value":this.itemvalue, "parameterid":this.item._id} )
				//this.itemvalue = element.target.value
			},

			getmounted(){

				let self = this

				/*setTimeout( function(){
					self.gettext()
				},100)*/

			},

			gettext(){

				let self = this

				if( this.item && this.item._id ){

					Portfolioapi.getFormvalue( { parameterid:this.item._id, projectid:this.projectid, token:this.token, context:this.orgkey } ).then( function( result ){

		                    if( result.data.ok ){
		                    	self.itemvalue = result.data.formvalue.value
		                    	self.origvalue = self.itemvalue
		                    }
	                })

				}				
				
			},

			gettext2( formvalue ){

				if( formvalue ){
					return formvalue.value
				}

								
				
			},

			textupdated( el ){
			}
		},

		computed:{

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{}
	}
	</script>