<script type="text/javascript"></script>

<template>  
	<div @click="panelclicked">
		
		<form class="text-left" id="projectformdetails">

		<div v-for="( row, key ) in portfolioforms" :key="key">

		<template v-if="row.active">

			<template v-if="row.type=='input'">

				<pText :rowkey="'key_'+key" v-if="row.size=='small'" :item="row" :projectid="projectid" :canwrite="canwrite" :editmode="editmode" @oneditmode="toeditmode" @onsavevalues="savevalues"></pText>				
			</template>


			<template v-if="row.type=='selection'">

				<pSelect :rowkey="'key_'+key" v-if="row.size=='selection'" :item="row" :projectid="projectid" @withchange="withchange" :canwrite="canwrite" :editmode="editmode" @onselectionupdated="savevalues"></pSelect>


				<pCheck :rowkey="'key_'+key" v-if="row.size=='check'" :item="row" :projectid="projectid" :canwrite="canwrite" :editmode="editmode" @oneditmode="toeditmode" @onselectionupdated="savevalues"></pCheck>


			</template>

			<template v-if="row.size=='rich'">
				
				<pRich :rowkey="'key_'+key" v-if="row.size=='rich'" :item="row" :projectid="projectid" :canwrite="canwrite" :editmode="editmode" :s3token="s3token" @oneditmode="toeditmode" @onsavevalues="savevalues"></pRich>

			</template>
			<template v-if="row.type=='phase' && group">

				<div class="mb-3 p-2 editlink" v-if="canwrite">
					<label :for="'phase_'+row._id" class="form-label">Project phase</label>

					 <div class="pl-0" >
						<b-dropdown no-caret :text="group.name" class="w-100" >

							<template #button-content>
								<div class="d-flex"  >
								  <div class="flex-grow-1 text-left">	

								  {{ group.name }}				  	
								  </div>
								  <div class="">
								  	<i class="fa-solid fa-caret-down"></i>
								  </div>
								</div>
		    				</template>

							<template v-for="(phase,phaseid) in phases">
								<b-dropdown-item :key="phaseid" @click.prevent.stop="changephase(projectid, phase)">{{ phase.name }}</b-dropdown-item>
							</template>
						</b-dropdown>

					</div>
				</div>

				<div class="mb-3 p-2 editlink" v-else>

					<label :for="'phase_'+row._id" class="form-label">Project phase </label>

					<input readonly  type="text" class="form-control" style="border-color: var(--N200);" :value="group.name" @click="checkactiveeditmode">
					
				</div>
				
			</template>

		</template>
		

		</div>


		<div class="text-right mb-2 hide">
			<button type="button" class="btn btn-secondary" @click="cancelsaveform">Cancel</button> <button type="submit" class="btn btn-primary" @click.stop.prevent="savevalues">Save</button>
		</div>
	</form>
		
	</div>
</template> 

<style type="text/css">

.form-control:focus{
	border-color: var(--N200);
}

.stillediting .highlight, .stillediting .form-control{
	border: solid 1px var(--R500) !important;
}

.bg-edit{
	background: var(--N100);
}

.fr-view .fr-file::after {
	/*position: relative;
	content: "\1F4CE";
	font-weight: normal;*/
	display: none !important;
}
	

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'
	import Cockpitsapi from '@/services/api/cockpits'

	import pText from '@/views/portfolio/forms/pText.vue'
	import pSelect from '@/views/portfolio/forms/pSelect.vue'
	import pCheck from '@/views/portfolio/forms/pCheck.vue'
	import pRich from '@/views/portfolio/forms/pRich.vue'

	export default{
		name:'portfolioProjectmodal',
		props:{
			canwrite:Boolean,
			portfolioid:String,
			portfoliodata:Object,
			parametersdata:Array,
			board:Object,
			projectdata:Object,
			s3token:Object
		},
		data(){
			return{
				project:{},
				portfolio:{},
				portfolios:[],
				projectid:"",
				name:"",
				error:"",				
				portfolioforms:[],
				defaultgroup:{"_id":null,name:"Select"},
				group:{"_id":null,name:"Select"},
				withchanges:0,
				inputphase:null,
				pendingmovegroup:{},
				editmode:'',
				editmodesize:'',
				updatekey:0
			}
		},

		 mounted() {

		 	let self = this

		 	this.project = this.board
		 	this.projectid = this.project._id
		 	this.getportfolio()
            
		 },

		watch:{
			board : function(){
				this.getmounted()
			}
		},
		methods:{

			checkactiveeditmode(){

				let toview = document.getElementById(this.editmode)

				if( toview ){
					toview.classList.add("stillediting");
					toview.scrollIntoView();
				}
					
			},

			toeditmode( editmode , size ){

				this.editmode = editmode
				this.editmodesize = size

				this.$emit('onchangeisediting', editmode )

				this.$nextTick(function(){
					let toview = document.getElementById(editmode)
					console.log( toview , "167")
					if( toview ){
						toview.scrollIntoView();
					}
				})
				

				

			},
			panelclicked( element ){

				/*if( this.editmodesize == 'check' ){

					let classlist = element.target.classList

					if( classlist.contains('checks') || classlist.contains('custom-control-input') || classlist.contains('custom-control-label') ){

					}else{
						this.editmode = ''
						this.editmodesize = ''
						this.updateportfoliodata()
					}

				}*/

								

			},

			withchange(){

				this.withchanges++
			},

			cancelsaveform(){

				//self.$emit('oncancelsaveform' )
				this.getgroup()
				this.updatekey++
				this.portfolioforms = []//this.parametersdata
	            this.portfolioforms = this.parametersdata
				this.editmode = false
			},

			savevalues( callback ){

                let self = this

                let updateview = 0

                /*if( this.pendingmovegroup.from && this.pendingmovegroup.to ){
                    
                    this.moveToGroup( this.pendingmovegroup.from, this.pendingmovegroup.to, this.pendingmovegroup.projectid , function(){

                    	self.$emit( 'onformsaved' )
                    	//alert('')
                    })

                }*/
                //alert(this.editmode)
                //this.editmode = ''

                let projectid = this.board._id

                let form = document.getElementById('projectformdetails')
                //let form = document.createElement("form");
                //form.appendChild(div);
                let formserialized = new URLSearchParams(new FormData(form))
                ////////$( '#projectformdetails' ).serialize()
				
				let saveform = []

				let toupdate = 0

                formserialized.forEach( function( value, parameterid ){

                	if( parameterid == self.portfolio.grouper._id ){
                		toupdate++
                	}

                	//alert(parameterid)
                	//alert(self.portfolio.grouper._id)

                    saveform.push( {"value":value, "parameterid":parameterid} )
                })

                Portfolioapi.saveFormValue( { "saveform":JSON.stringify( saveform ), projectid:projectid,  portfolioid:this.pid, t:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok ){

                    	if( toupdate > 0 ){
                    		self.editmodesize = ''
                    		self.editmode = ''
                    		self.updateportfoliodata()
                    	}
                        
                        if( callback && ( typeof callback === 'function') ){
                        	callback()
                        }
                    }
                })
                /**/

            },

            formsaved(){},

			/*formsaved( saveformdata ){

				let self = this/
				
				let saveform = []
                saveform.push( saveformdata )

				Portfolioapi.saveFormValue( { "saveform":JSON.stringify( saveform ), projectid:this.projectid,  portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

                    if( result.data.ok ){

                    	self.$emit('onformsaved' )
                    }
                })


			},*/

			getmounted(){

				let self = this

			 	this.project = this.board
			 	this.projectid = this.project._id
			 	this.getportfolio()

			},

			updateportfoliodata( ){

				this.$emit( 'onformvaluesupdated' )
				this.$emit( 'onformsaved' )

			},

			/*	let self = this

				Portfolioapi.getOne( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey, parameters:true }).then( function( result ){

	                if( result.data.ok && result.data.portfolio ){
	                    self.portfolio = result.data.portfolio
	                    self.portfolioforms = result.data.parameters

	                    self.getgroup()

	                    if( callback ){
	                    	callback()
	                    }
	                }

            	})
			},*/

			getportfolio(){

				let self = this
				//, values:true
				/**/
            	this.portfolio = this.portfoliodata
	            this.portfolioforms = []//this.parametersdata
	            this.portfolioforms = this.parametersdata
	            this.getgroup()
	            //this.getformvalues()

			},

			/*getformvalues(){

				let self = this
				let cntr = 0
				let newparameters = []
				this.parametersdata.forEach( function( parameter ){
					parameter.projectid = self.projectid
		            newparameters.push(parameter)

		            cntr++

                    if( cntr == self.parametersdata.length ){
                    	self.portfolioforms = newparameters
                    }
				})

				//this.portfolioforms = newparameters
			},*/

			getformvalues(){

				if( !this.projectid ){
					return false
				}

				

				//this.portfolioforms = this.parametersdata

				//return false

				let self = this
				
				let newparameters = []


				this.portfolioforms = this.parametersdata


				/*setTimeout( function(){

					

					Portfolioapi.getFormvalues( { projectid:self.projectid, token:self.token, context:self.orgkey } ).then( function( result ){

				    if( result.data.ok ){
				    	
				    	result.data.formvalues.forEach( function( formvalue ){

				    		for( var i=0;i<self.portfolioforms.length;i++){
				    			console.log("xxxxxxxx22")
				    			self.portfolioforms[i].value = "xxxxxxxxxxxx"
				    		}

				    	})


				    }

				    

				})


				}, 1000)*/

				

				


			},

			moveToGroup( fromgroup, togroup, projectid, callback ){

                let self = this

                Portfolioapi.moveProject( { gid:fromgroup, groupid:togroup, projectid:projectid, portfolioid:this.portfolio._id, t:this.token, context:this.orgkey } ).then( function( result ){

                	if( callback ){
                		callback()
                	}
                   	//self.getportfolio()

                })
            },

			changephase( projectid, phase ){

				let self = this
				
				this.pendingmovegroup = { "from":this.group._id, "to":phase._id, "projectid":projectid }

				//console.log( this.pendingmovegroup.from, this.pendingmovegroup.to, projectid )

				this.group = phase

				//this.phasechanged = true

				//this.$emit('onchangephase', projectid, this.group._id, phase._id )
				//this.group = phase

				if( this.pendingmovegroup.from && this.pendingmovegroup.to ){
                    
                    this.moveToGroup( this.pendingmovegroup.from, this.pendingmovegroup.to, this.pendingmovegroup.projectid , function(){

                    	self.$emit( 'onformsaved' )
                    	//alert('')
                    })

                }

			},

			getgroup(){

				let self = this

				let phase = {}

				if( this.portfolio && this.portfolio.groups ){

					 this.group = this.portfolio.groups.find((item) => item._id === self.project.groupid );
				}


			},
		},

		computed:{

			phases(){

				let phases = []

				if( this.portfolio && this.portfolio.groups ){

					this.portfolio.groups.forEach(function( group ){
						phases.push(group)
					})

				}

				return phases
			},

			
			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{ pText, pSelect, pCheck, pRich }
	}
	</script>