<template>
 
  <div class="">


    <usersTable :working="isworking" :members="members"  @onupdated="onusersupdated" @onusersupgraded="usersupgraded" :membersmodaltype="membersmodaltype" :showtitle="showtitle"></usersTable>
  </div>

</template>

<script>
    import usersTable from './usersTable'
    import {bus} from '@/main'
  //  import Cockpitsapi from '@/services/api/cockpits'
    import Teamsapi from '@/services/api/teams'
    import Portfolioapi from '@/services/api/portfolio'

  export default {
    name:'membersModal',
    props:{
      showtitle:String,
      members:{
        type:Array,
        default:()=>[]
      },
      checkforinvalidlink: Boolean,
      membersmodaleid:String,
      membersmodaltype:String,
      shown:Boolean,
      myrole:String,
      loggedinuser:Object,
      cockpitid:String,
      token:String,
      isactive:Boolean,
      subject:Object,
      
    },
    data(){
      return{
        isshown : true,
        cockpit : {},
        objective: {},
        showbanner:true,
        isworking:false
      }
    },
    watch:{
     // shown : function( e ){
        //this.isshown = e
     // }
    },
    components:{      
      usersTable
    },
    mounted(){ 

      //this.cockpit = {}
      this.modalshown()
    },

    computed:{

      orgkey(){
        return this.$route.params.orgname ? this.$route.params.orgname : null
      }
      
    },

    methods:{

      usersupgraded(){
        //onusersupgraded
        //this.isshown = false
      },

       onusersupdated( field, measureids, removed ){

        if( removed ){
        
          /*if( removed.selected ){
            removed.selected.subitems.forEach( function( subitem ){
                console.log( subitem.email, "removedxx")
            })            
          }*/

          if(this.membersmodaltype == "portfolio"){
            this.portfolioupdate( field, measureids, removed)
          } else if(this.membersmodaltype == "portfolioteam"){
             this.portfolioteamupdate( field, measureids, removed)
          }
        }

         

      },


     modalhidden(){


      //history.pushState("", document.title, "/dashboard/cockpit/id_" + this.cockpit.id );

      bus.$off('userstableloadusers')
      bus.$off('onafterupdated')

      this.$emit('onmodalhidden')
      this.$func.removeQuery(['page','oid'],this.$route,this.$router)
      //this.isshown = false
      this.cockpit={}
      this.objective={}
      },


  modalshown(){

      //let self = this

       if(this.membersmodaltype == 'portfolio'){

          bus.$emit('userstableloadusers', this.subject, this.token, this.loggedinuser, this.myrole, 'objective', true )
       }

       if(this.membersmodaltype == 'portfolioteam'){

          bus.$emit('userstableloadusers', this.subject, this.token, this.loggedinuser, this.myrole, 'objective', true )
       }
  },


    portfolioteamupdate( field, value, removed ){
      
      let self = this

      if( field == 'teams' ){
        self.$emit('onmembersupdated',  { 'approved':removed.approved, 'removed':removed.remove?removed.remove:removed.rejected, 'added':removed.added, 'selected':removed.selected } )
      }
      

    },

    portfolioupdate( field, value, removed, callback ){

    //console.log( removed, 'membersmodal')

    this.isworking = true

      var  savedvalue = JSON.stringify(value)     
      

      let self = this

      Portfolioapi.Updateusers( { "value":savedvalue, "t":this.token, "portfolioid":this.subject._id, "context":this.orgkey, "removed":JSON.stringify(removed) } )
      .then( function(response){

        if( response.data.ok ){

          self.isworking = false

          if( removed.remove ){
            Teamsapi.Updateuserstatus( removed.remove, self.orgkey, "remove" )
          }

          if( removed.added ){
            Teamsapi.Updateuserstatus( removed.added, self.orgkey, "add" )
          }  

          self.$emit('onmembersupdated',  { 'approved':removed.approved, 'removed':removed.remove?removed.remove:removed.rejected, 'added':removed.added, 'selected':removed.selected } )

          if( callback ){
            callback( response.data )
          }
        }


      })
      .catch( function(){

        self.isworking = false
            //self.cockpitsettingsmodalshown = false
          })
      .finally( () => {

        self.isworking = false
      })
      

    },

    }
}

</script>