<template>
	<div class="d-flex align-items-center ">
		<template v-if="icon=='small' || icon=='phase' || icon=='selection' || icon=='check' || icon=='rich'">
		<i title="Text" v-if="icon=='small'" class="form-icon fa-regular fa-input-text align-self-center"></i> <span class="" v-if="showtext && icon=='small'">Text</span>
		<i title="Phase" v-else-if="icon=='phase'" class="form-icon fa-regular fa-columns-3 align-self-center"></i> <span class="" v-if="showtext && icon=='phase'">Phase</span>
		<i title="Checkbox" v-if="icon=='check'" class="form-icon fa-regular fa-square-check align-self-center"></i> <span class="" v-if="showtext && icon=='check'">Checkbox</span>
		<i title="Dropdown" v-if="icon=='selection'" class="form-icon fa-regular fa-square-caret-down align-self-center"></i> <span class="" v-if="showtext && icon=='selection'">Dropdown</span>
		<i title="Multiline" v-if="icon=='rich'" class="form-icon fa-regular fa-keyboard align-self-center"></i> <span class="" v-if="showtext && icon=='rich'">Multiline</span>
		</template>
	</div></template>
<style type="text/css">

	.fa-grip-lines{
		font-size: 16px;
	}

	.paramitem a, .paramitem a:hover{
		color: var(--N900) !important;
	}
	
	.form-icon{
		
		width: 48px;
		text-align: center;
		font-size: 16px;
		/*display:inline-block;
		
		line-height: 33px;
		
		height: 30px;
		
		vertical-align: bottom;*/
	}

	.form-icon i {
		font-size: 16px;
	}

	.dropdown-item .form-icon{
		width: 16px !important;
		margin-right: 10px;
	}

</style>
<script>
export default{
name:'formicon',
props:{
	icon:String,
	showtext:Boolean
},

data(){
	return{
	}
},

 mounted() {
 },

watch:{			
},
methods:{
},
computed:{
},
components:{}
}
</script>