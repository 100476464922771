<script type="text/javascript"></script>

<template>

	<div :data-id="groupid+'_'+project._id" class="card portfolioitem mb-2" :id="projectid" @click.stop.prevent="projectclicked(project)" :class="{'active': project._id == activeboardid, 'project' : !viewcompact, 'projectcompact': viewcompact, 'inactiveboard':!project.isactive }">

		<div class="d-flex" :class="{ 'projectfullcontents': !viewcompact }" >
		  <div class="flex-grow-1 p-2" :class="{ 'p-2':viewcompact }"><h5 :title="project.name">{{ project.name }}</h5>
		  	<span class="badge badge-light" v-if="!project.isactive">Inactive</span>
		  </div>
		  <div class="p-2" :class="{'accent-right':!viewcompact }">
		  	
		  	<div class="d-flex flex-column align-items-center text-center" style="height: 100%;" >

		  	<div class="flex-grow-1" v-if="boardstatusdata && boardstatusdata.indexcolor">
				<span v-b-tooltip.hover title="Yellow" v-if="boardstatusdata && boardstatusdata.indexcolor=='yellow'" class="fa-stack">
				    <i class="fas fa-circle fa-stack-2x" style="color: var(--Y500);"></i>
				    <i class="fas fa-exclamation fa-stack-1x fa-inverse"></i>
				 </span>

				<span v-b-tooltip.hover title="Red" v-if="boardstatusdata && boardstatusdata.indexcolor=='red'" class="fa-stack">
				    <i class="fas fa-circle fa-stack-2x" style="color: var(--R500);"></i>
				    <i class="fas fa-times fa-stack-1x fa-inverse"></i>
				  </span>

				<span v-b-tooltip.hover title="Green" v-if="boardstatusdata && boardstatusdata.indexcolor=='green'" class="fa-stack">
				    <i class="fas fa-circle fa-stack-2x" style="color: var(--G500);"></i>
				    <i class="fas fa-check fa-stack-1x fa-inverse"></i>
				  </span>	

				<div v-if="boardstatusdata && boardstatusdata.statusdate" :title="localedate(boardstatusdata.statusdate)" style="white-space: nowrap;" v-b-tooltip.hover>
					{{ timeAgo( boardstatusdata.statusdate)  }}
				</div>

			</div>
			<div class="flex-grow-1" v-else>
				<span class="fa-stack" title="No status value" v-b-tooltip.hover>
				    <i class="fas fa-circle fa-stack-2x" style="color: var(--N500);"></i>
				  </span>
			</div>

			<div v-if="boardstatusdata && boardstatusdata.numusers && !viewcompact" class="d-flex flex-column">
				  <div class="text-center"><i class="fas fa-users"></i></div>
				  <div class="text-center">{{ boardstatusdata.numusers  }}</div>
				</div>
				
			</div>

		  </div>
		</div>

	</div>
</template> 

<style type="text/css">

	html {
  scroll-behavior: smooth;
}

	.portfolioitem:hover, .portfolioitem.active{
	  -webkit-box-shadow: 0 14px 26px -12px rgba(73, 96, 146, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(73, 96, 146, 0.2);
	  -moz-box-shadow: 0 14px 26px -12px rgba(73, 96, 146, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(73, 96, 146, 0.2);

	}

	.status.fas.yellow {
	  display: inline-block;
	  border-radius: 60px;
	  box-shadow: 0 0 2px #888;
	  padding: 0.7em 1.0em;
	  background-color: var(--Y500) !important;
	  color: #FFF;
	}

	.status.fas.red {
	  display: inline-block;
	  border-radius: 60px;
	  box-shadow: 0 0 2px #888;
	  padding: 0.7em 0.8em;
	  background-color: var(--R500) !important;
	  color: #FFF;
	}

	.status.fas.green {
	  display: inline-block;
	  border-radius: 60px;
	  box-shadow: 0 0 2px #888;
	  padding: 0.6em 0.6em;
	  background-color: var(--G500) !important;
	  color: #FFF;
	}

	.status_yellow{
		
	}
	.status_red{
		background-color: var(--R500) !important;
	}
	.status_green{
		background-color: var(--G500) !important;
	}

	.statusicon{
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}

	.project{
		background-image: url('/static/bgaccent.png');	
		/*height: 140px;*/
	}

	.project.inactiveboard{
		background-image: url('/static/bgaccentgray.png') !important;	
	}

	.pproject{
			
		/*width: 170px;
		padding-left: 20px;*/
	}

	.projectname{

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}
	
	.compact{
		/*max-height: 65px;
		overflow: hidden;*/
	}

	.card2{
		background: #fff;
	}

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'
	import Cockpitsapi from '@/services/api/cockpits'
	import moment from 'moment';

	import {bus} from '@/main'

	export default{
		name:'portfolioProject',
		props:{
			viewcompact:Boolean,
			canwrite:Boolean,
			groupid:String,
			activeboardid:String,
			portfolioid:String,
			group:Object,
			portfoliodata:Object,
			parametersdata:Array,
			projectdata:Object		
		},

		data(){
			return{
				projectid:"",
				project:{},
				forms:{},
				formsaved:{},
				newforms:{},
				selectedoption:null,
				portfolio:{},
				portfolioforms:[],
				expand:false,
				boardstatusdata:{}
			}
		},

		 mounted() {

		 	//if( this.projectid ){
		 		let self = this



		 		setTimeout( function(){
		 			self.loadportfolio()
		 		}, 50)
		 		
		 		


		 	//}

		 	
		 },

		watch:{

			/*'portfolio.forms' : function(e){

				alert('cc')
			}*/

			/*projectid : function( e ) {
				alert( e )
			}*/
		},
		methods:{

			getMemberscount(){

				return ''
			},

			getIcon( data ){

				let toreturn = ""

				if( data && data.indexvalue ){
					toreturn = data && data.indexvalue >= 1.0 ? 'green' : 'red'
				}

				return toreturn

			},

			getStatusDate( dte ){

				let toreturn = ""

				toreturn = moment(dte).format("DD MMM, YYYY")

				return toreturn
			},

			localedate( timestamp ){


				const monthNames = ["Jan", "Feb", "Mar", "Apr",
                        "May", "Jun", "Jul", "Aug",
                        "Sep", "Oct", "Nov", "Dec"];
    
			    const tt = new Date(timestamp)

			    const day = tt.getDate();
			    
			    const monthIndex = tt.getMonth();
			    const monthName = monthNames[monthIndex];
			    
			    const year = tt.getFullYear();
			    
			    return `${day} ${monthName}, ${year}`;  


				//return new Date(timestamp).toLocaleDateString()

			},

			timeAgo( timestamp, min ){

			    var	now = new Date(),
			    difference = now.getTime() - new Date(timestamp).getTime()

			    if( isNaN(difference) ){
			    	return ""
			    }

			    difference = Math.floor(difference / 1000);

			    if (difference < 60) return '<1m';

			    difference = Math.floor(difference / 60);
			    if (difference < 60) return difference + 'm';

			    difference = Math.floor(difference / 60);
			    if (difference < 24) return difference + 'h';

			    difference = Math.floor(difference / 24);
			    if (difference < 30) return difference + 'D';

			    difference = Math.floor(difference / 30);
			    if (difference < 12) return difference + 'M';

			    difference = Math.floor(difference / 12);
			    return difference + 'Y';

			},

			boarddata(){

				let self = this

				Cockpitsapi.Cockpitschartdatalatest( { "_id": this.project._id, "token": this.token, "limit":"latest" } ).then( function(response){

					self.boardstatusdata = response.data.status
					
					
				})

			},

			getvalue( parameterid ){

				//this.projectdata.parametervalues []

				let toreturn = ""

				let param = this.portfolioforms.find((item) => item._id === parameterid);
				let values = this.projectdata.parametervalues.find((item) => item.parameterid === parameterid);


				if( values ){

					let tovalue = values.value

					let tovalues = []

					let x = tovalue.split(",")



					x.forEach( function( o ){



						if( o != '' ){


							if( param.options && param.options.length > 0 ){

								let option = param.options.find((item) => item._id === o);

								if( option ){
									tovalues.push(option.name)
								}else{
									if( values.parameter && values.parameter.options ){
										let option = values.parameter.options.find((item) => item._id === o);
										if( option ){
											tovalues.push(option.name)
										}
									}
								}
							}else{

								tovalues.push(o)

							}


						}

					})

					toreturn = tovalues.join(", ")

				}					

				/*this.portfolioforms.forEach( function( form ){
					if( parameterid == form._id){
					}
					console.log( parameterid, form._id  , "xx")
				})*/

				return toreturn

			},

			tryremovefromgroup(){
				this.$emit('onremoveproject', this.project._id, this.groupid )				
			},


			projectclicked( project ){
				this.$emit('onprojectclicked', project, this.groupid )
			},

			loadportfolio(){


				let self = this

				this.portfolio = this.portfoliodata
	            this.portfolioforms = this.parametersdata
	            this.project = this.projectdata
	            this.projectid = this.projectdata._id

	            
	            if( this.project ){
	            	setTimeout( function(){
						self.boarddata()
					}, 200)
	            }
	            

				/*Portfolioapi.getOne( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey, parameters:true }).then( function( result ){

	                if( result.data.ok && result.data.portfolio ){
	                    self.portfolio = result.data.portfolio
	                    self.portfolioforms = result.data.parameters

	                   // self.getgroup()

	                    
	                }

            	})*/

				/*Portfolioapi.getOne( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.portfolio ){

						self.portfolio = result.data.portfolio

						setTimeout( function(){
							self.loadboard()
						}, 10)

					}
				})*/

			},

			loadboard(){



				let self = this

				Cockpitsapi.Cockpitspreload( {"_id": this.projectid, "token": this.token } ).then( function( result ){

		 			if( result.data.ok && result.data.cockpits ){


						self.project = result.data.cockpits


						////self.getformvalue()
					}

		 		})


			},

			getformvalue(){

				//console.log( "getformvalue" )

				if( this.portfolio ){

					let self = this


					Portfolioapi.getformvalue(  { "portfolioid":this.portfolioid, "projectid":this.projectid, "token": this.token } ).then( function( result ){

						let values = result.data.formvalue.values

						console.log( values, "values")

						if( values && values.length > 0 ){

							//self.forms = formvalue.form

							for( let i = 0; i < self.portfolio.forms.length; i++  ){

								let form = self.portfolio.forms[i]

								for( let o = 0; o < values.length; o++  ){

									if( form.uuid == values[o].formid ){
										self.portfolio.forms[i].value = values[o].value
										self.portfolio.forms[i].values = values[o].values
									}
									
								}
							}
							
						}

						

					})

				}

				
			},

			updatevalue( item, item2 ){


				for( let i = 0; i < this.portfolio.forms.length; i++  ){

					let form = this.portfolio.forms[i]
					//console.log( form.uuid + "-" + item.uuid)	
					if( form && form.uuid == item.uuid ){						

						let val = this.portfolio.forms[i]

						let newval = val//.concat({ "value":"", "type" : "", "text" : "", "selected" : "" })

						val = newval

						val.value = item.value

						if( item.type && ( item.type == 'selection' || item.type == 'checks' ) ){


							if( val.options ){

								//console.log( val.options, item ,"lkk")

								for( let k = 0; k < val.options.length ; k++  ){

									if(val.options[k].selected){
										delete(val.options[k].selected)
									}
									if(val.options[k].text){
										delete(val.options[k].text)
									}

								}

								for( let k = 0; k < val.options.length ; k++  ){
									

									if( Array.isArray(item.value) ){

										val.values = item.value
										val.value = null

										if( item.value.indexOf( val.options[k].uuid ) > -1 ){

											val.options[k].selected = true
											val.options[k].text = item.text

										}

									}else{

										if( val.options[k].uuid == item.value ){

											val.options[k].selected = true
											val.options[k].text = item.text

										}

									}


								}
							}

							

						}

						//console.log( val, "val")

						this.portfolio.forms[i] = val

						let formsaved = this.portfolio.forms

						/*this.$nextTick(function() {
							bus.$emit('onformupdated', { "projectid":this.projectid } )
						})*/
						

						this.saveformvalue( formsaved )

						//console.log( this.portfolio.forms, "update")
					}

				}
				
				console.log("updatevalue")

			},

			saveformvalue( formsaved ){

				let self = this;

				Portfolioapi.saveFormValue( { "portfolioid":this.portfolio._id, "projectid":this.projectid, "formvalue": JSON.stringify(formsaved), t:this.token }  ).then( function( result ){

					console.log( result )

				})

			},


			changephase( projectid, groupid){


				this.$emit('onmoveToGroup', this.groupid, groupid, projectid)

			},

			addnewgroup(){

				this.$emit('onaddnewgroup')

			},

			removeFromGroup( projectid ){

				this.$emit('onremoveFromGroup', this.groupid, this.projectid)	


			}
		},

		computed:{

			/*portfolioforms(){

				let toreturn = []

				if( this.portfolio.forms ){					

				    toreturn = this.portfolio.forms

				}

				return toreturn

			},*/

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{}
	}
	</script>