   <script type="text/javascript"></script>
   
   <template>




    <div class="innerbody form-group">

      <h3 class="overflow-string2 font-weight-bold mb-2">{{ parent.name }} </h3>

  
      <b-alert v-if="cockpitupgradestatus != 'pro'" :variant="showbannervariant" id="maxusers" :show.sync="showbanner" class ="mb-2 alert-nopadding clicktoupgrade mb-2">
      <template v-if="cockpitupgradestatus == 'limited' || cockpitupgradestatus == 'free'"><a href="#" :class="{'actioninfodark':showbannervariant == 'info', 'actionwarningdark':showbannervariant == 'warning'} " @click="toupgrade = true">
        <span v-if="maxusersboard>0 && userids">{{  userids.length }} out of {{maxusersboard}}</span>
        <span v-if="maxusersboard==0">{{userscount}}</span> members added. Click to upgrade</a>
      </template> 
      <template v-if="cockpitupgradestatus == 'request'"><a href="https://flowe.io/#pricing" class="actioninfodark" target="_blank">This board is being upgraded. Learn more <i class="fal fa-external-link"></i>.</a>
      </template> 
      <template  v-slot:dismiss>
          <i class=" mr-2 fal fa-times fa-sm" ></i>
      </template>
      </b-alert>

      <upgradeMaxusersdialog :opendialog="toupgrade" :cockpitid="cockpit.id" :token="token" @onUpgrademodalhidden="upgrademodalhidden" @onwaitingforupgrade="waitingforupgrade"></upgradeMaxusersdialog>   


      <h3 class ="font-weight-bold mb-1" v-if="showtitle && showtitle != ''">{{showtitle}}</h3>

      

      <memberlistfull 
      :cockpit="cockpit"
      :orgcontext="orgkey" 
      :allowadduser="allowadduser" 
      @onupdated = "onupdated"
      @onupdateuserslist = "updateuserslist" 
      :membersmodaltype="membersmodaltype"
      :roles ="roles" 
      :myrole="myrole" 
      :adminrole="adminrole"
      :defaultrole="defaultrole"
      :isactive ="isactive" 
      :numowners="numowners"
      :users="members"
      :teamusers="teamusers"
      :includeteams="true"
      :isworking="working"
      :cockpitupgradestatus="cockpitupgradestatus"
      :Hasbeenapprovedemailtouser="hasbeenapprovedemailtouser"
      :Hasbeenapprovedemailtoowner="hasbeenapprovedemailtoowner"
      :Hasbeenrejectedemailtouser="hasbeenrejectedemailtouser"
      :Hasbeenrejectedemailtoowner="hasbeenrejectedemailtoowner"/>
      
      <div  class="text-danger" v-if="errors.useraccess"><span v-if="errors.useraccess == 'oneowner'">Cockpit requires atleast 1 owner</span></div>

      <p class="hide"><router-link :to="'/'+orgkey+'/organizations/teams?manage=true'" >Manage teams</router-link></p>

    </div> 

  </template>
  <style type="text/css" lang = "scss">

    .actionlink{
      font-weight: 700;      
    }

    .actionlink:hover, .actionlink:focus, .actionlink:active{
      text-decoration: underline;
    }
 
    .alert-nopadding{
      margin-left: -16px;
      margin-right: -16px;
      margin-top: 0;
      border-radius: 0;
    }

    .objectname{
      color: var(--N800);
       margin-top: -8px;
    }

    @media screen and (max-width: 400px) {
      .clicktoupgrade {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .innerbody{
      /*margin-top: -24px;*/
    }

</style>

<script>
 import avatar from '@/views/tools/avatar'

 import vueselect from '@/views/tools/vueselect'
 import Userapi from '@/services/api/users'
 import Cockpitsapi from '@/services/api/cockpits'
 import Emailsapi from '@/services/api/emails'

 import upgradeMaxusersdialog from '@/views/cockpit/components/upgradeMaxusersdialog'
 import memberlistfull from '@/components/members/memberlistfull' 

 import {bus} from '@/main'
 
 export default {
  name:'usersTable',
  props:{    
    membersmodaltype:{
            type:String,
            default: ''
        },
    showtitle:"",
    parent:Object,
    userids:Array,
    members:Array,
    working:{
          type:Boolean,
          default: false
        },
  },
  data(){
    return{
      toupgrade : false,
      availableusers:[],
      activeuser:{},
      torole : "",
      cockpit:{},
      myrole:'',
      loggedinuser : {},
      errors:[],
      numowners:0,
      sendaninvite : false,
      sendaninvite:false,
      showapproval:false,
      alreadyonlist:false,
      approvalneed:'',
      type:'',
      removeuser:false,
      removerole:false,
      searchquery:'',
      isactive:true,
      usersselect : "",
      searchbounce : null,
      roles:[ { label:"Viewer", value:"Viewer" } , { label:"Contributor", value:"Contributor" } , { label:"Owner", value:"Owner" } ],
      adminrole: "Owner",
      defaultrole: "Viewer",
      users: []
    }
  },
  watch:{
  },
  components:{vueselect, avatar, upgradeMaxusersdialog, memberlistfull},
  computed:{

    truemembers(){

      let self = this

      let members = []

      this.members.forEach( function( user ){
        if( !self.systemgenerated(user) ){
          members.push(user)
        }
      })

      return members
      
    },

    teamusers(){

      return this.cockpit.tcusers
    },

    orgkey(){
      return this.$route.params.orgname ? this.$route.params.orgname : null
    },

    token(){
        return localStorage.token
    },

    userscount(){
      return this.cockpit.users ? this.cockpit.users.length : 0
    },

    maxusersboard(){
      return this.cockpit.setmaxuser ? ( this.cockpit.setmaxuser.maxuser > -1 ? this.cockpit.setmaxuser.maxuser : 10 ) : 10
    },

    allowadduser(){
      return this.maxusersboard == 0 ? true : ( this.userscount < this.maxusersboard ? true : false ) 

    },

    cockpitupgradestatus(){     


      var ispro = this.cockpit.setmaxuser ? this.cockpit.setmaxuser.licensetype : ''

      if( ispro == "pro" ){
        return "pro"
      }else if( ispro == "free" ){
        return "free"
      }else{
         return this.cockpit.setmaxuser ? this.cockpit.setmaxuser.status : 'limited'
      }

      //setmaxuser.licensetype

    },

    showbanner(){

      var toreturn = false

      if( this.cockpit.myrole == 'owner' && this.cockpit.id ){
        toreturn = true
      }

      if( this.cockpit.setmaxuser && ( this.cockpit.setmaxuser.licensetype == 'pro' || this.cockpit.setmaxuser.status == 'unlimited' )  ){
        toreturn = false
      }

      if( this.cockpit.setmaxuser && ( this.cockpit.setmaxuser.licensetype == 'pro' && this.cockpit.setmaxuser.status == 'limited' )  ){
        toreturn = true
      }

      if( this.cockpit.setmaxuser && ( this.cockpit.setmaxuser.licensetype == 'pro' && this.cockpit.setmaxuser.status == 'request' )  ){
        toreturn = true
      }

      return toreturn



    },
    orgcontext(){
      return this.$store.state.organizations.selectedorganizations.key
    },
    showbannervariant(){

      var toreturn = this.allowadduser ? 'info' : 'warning'

      if( this.cockpitupgradestatus == 'request' ){
        toreturn = 'info'
      }

      return toreturn
    }
  },

  mounted(){     
      //seting up a bus envet when members modal opens


      bus.$off('userstableloadusers' )
      bus.$off('onafterupdated' )
      bus.$on('userstableloadusers', this.initcomponent )
      bus.$on('onafterupdated', this.loadusers )


    },
    methods:{

      systemgenerated( object ){

        let toreturn = false
        if( object.measuretype && ( object.measuretype == "risk" || object.measuretype == "masterplan") ){
          toreturn = true
        }

        if( object.type && object.type == "stakeholder" ){
          toreturn = true
        }

        if( object.type && object.type == "risk" ){
          toreturn = true
        }

        if( object.type && object.type == "masterplan" ){
          toreturn = true
        }

        if( object.type && object.type == "everyone" ){
          toreturn = true
        }        

        return toreturn

      },

      updateuserslist( users ){

        this.users = users

      },
      onupdated(field, value, removed ){
        this.$emit('onupdated',field,value, removed)
      },

      initcomponent( cockpit, token, loggedinuser, myrole, type, isactive){

        this.cockpit = cockpit
        this.isactive = isactive

        let role = myrole.charAt(0).toUpperCase() + myrole.slice(1)

        this.myrole = role;// this.cockpit.measuretype && this.cockpit.measuretype == 'risk' ? 'Viewer' : role;
        this.loggedinuser = this.$store.state.user.userInfo
        this.type = type
        this.numowners = 0

        // count number of owner in board/objetive
        for( var i in this.cockpit.users ){
          if( this.cockpit.users[i].role.toLowerCase() == "owner"){
            this.numowners++
          }
        }

       /* for( var i in this.cockpit.users ){
          console.log( i, this.cockpit.users[i] , "ppppp")
        }*/

        if( this.cockpit.users ){
          var ckusers = this.cockpit.users   
          bus.$emit('initmemberslist', ckusers , this.cockpit.userids, this.cockpit.tcusers)
        }
             
        //if( this.cockpit.tcusers ){
        //  ckusers = ckusers.concat( this.cockpit.tcusers )
        //} 
        
        
      },


      

      waitingforupgrade : function( callback ){
        this.toupgrade = false

        if( this.cockpit.setmaxuser ){
          this.cockpit.setmaxuser.status = "request"
        }else{
          this.$emit('onusersupgraded' )
        }

      setTimeout( function(){
          $('.actioninfodark').blur()
        },200)
      },

      upgrademodalhidden : function( callback ){

        this.toupgrade = false

        if( callback == 'closemodal' ){
           this.$emit('onusersupgraded' )
        }
      },

      hasbeenapprovedemailtouser : function( userid, cockpitid, loggedinuserid ){

        Emailsapi.Hasbeenapprovedemailtouser( userid, cockpitid, loggedinuserid )
        //console.log( userid, cockpitid, loggedinuserid , "hasbeenapprovedemailtouser")
      },
      hasbeenapprovedemailtoowner : function( userid, cockpitid, ownerid, loggedinuserid ){

        Emailsapi.Hasbeenapprovedemailtoowner( userid, cockpitid, ownerid, loggedinuserid )

        //console.log( userid, cockpitid, loggedinuserid , "hasbeenapprovedemailtoowner")
      },
      hasbeenrejectedemailtouser : function( userid, cockpitid, loggedinuserid ){

        Emailsapi.Hasbeenrejectedemailtouser( userid, cockpitid, loggedinuserid )

        //console.log( userid, cockpitid, loggedinuserid , "hasbeenrejectedemailtouser")
      },
      hasbeenrejectedemailtoowner : function( userid, cockpitid, ownerid, loggedinuserid ){

        Emailsapi.Hasbeenrejectedemailtoowner( userid, cockpitid, ownerid, loggedinuserid )

        //hasbeenrejectedemailtoowner
        //console.log( userid, cockpitid, loggedinuserid , "hasbeenrejectedemailtoowner")
      },      

      accessrequest : function( user, isapproved , ev ){

        var newroles = []
        var newusers = []
        var newuserids = []

        var userid = ""

        var ownerids = []

        let self = this

        $.each( this.cockpit.users, function(i,o){

          if( o._id == user._id ){

            if( isapproved ){

              if( o.status == 'pendingjoin' ){
                //o.status = "active"
                newusers.push(o)
                newroles.push( { "id":o._id, "role":o.role} )

                userid = o._id
              }                   

              newuserids.push(o._id)  
            }

          }else{

            if( o.role.toLowerCase() == "owner" && o._id != self.loggedinuser._id ){
              ownerids.push( o._id )
            }

            newusers.push(o)
            newroles.push( { "id":o._id, "role":o.role, "status":o.status } )
            newuserids.push(o._id) 

          }
        })

        this.cockpit.userids = newuserids
        this.cockpit.users = newusers
          //this.cockpit.approvedusers = newroles

          this.$emit('onupdated', "users", newroles, { "action":"requestaccess" } )
          
          //modalmeasuresettings.linesave( null, true, 'accessrequest' , function(){
            user.status = "active"

      //approve or denied

      if( isapproved ){


        Emailsapi.Hasbeenapprovedemailtouser( user._id, this.cockpit._id, this.loggedinuser._id)

        for (var i in ownerids){

          Emailsapi.Hasbeenapprovedemailtoowner( user._id , self.cockpit._id , ownerids[i], self.loggedinuser._id)
        }

      }else{

        Emailsapi.Hasbeenrejectedemailtouser( user._id, this.cockpit._id, this.loggedinuser._id)              

        for (var i in ownerids){
          Emailsapi.Hasbeenrejectedemailtoowner( user._id , self.cockpit._id, ownerids[i],  self.loggedinuser._id) 
        }

      }
      this.ajustlengthup()

          //})
          this.showapproval = false
        },

        inviteuser(newuser){

          let self = this

          var email = this.sendaninvitetouser

          Userapi.Registeruser( email, this.token )
          .then( function(response){
            var str1 = 'Invite sent'
            if( response.data.ok ){

              setTimeout( function(){
                $('.vs__search')[0].blur()
              }, 100)






              if( response.data.user ){

                self.userSelect( response.data.user , true )


                var str = ""

                Emailsapi.Invitationemail( self.loggedinuser._id, email, self.orgkey)

                str = "We have sent an invitation email to the address you indicated. Once they sign up, they will automatically have the accesses you have indicated in the members list."
               
                bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})


              }else{

                var str = "Failed to send an invitation email to the address you indicated."
                bus.$emit('craeteaToast', {content:str,title:str1, variant:'warning',append:true, sup: ''})


              }

              
              
              

            }else{

              var str = "Failed to send an invitation email to the address you indicated."

               bus.$emit('craeteaToast', {content:str,title:str1, variant:'warning',append:true, sup: ''})

            }

        }).catch( function(){


          /*

.catch( function( err ){

                console.log("rollback")

              })
              
          */
          self.popToast(false, "Failed to send an invitation email to the address you indicated.")
        })
       

        this.ajustlengthup()
      },


      availableusersupdate( search, loading ){

        let self = this

        this.alreadyonlist = false

        if( this.searchbounce ){
          clearTimeout(this.searchbounce)
        }

        this.searchbounce = setTimeout( function(){

          if( search ){
            self.searchquery = search
          }        

          if( search && search.length > 1 ){

            loading(true)



            Cockpitsapi.Getusers( search, self.cockpit.userids )
            .then( function(response){      

              if( response.data ){

                self.sendaninvite = response.data.sendaninvite
                self.alreadyonlist = response.data.alreadyonlist

                if( self.sendaninvite == true ){
                  self.sendaninvitetouser = response.data.inviteuser
                }                

                self.availableusers = response.data.users


              //console.log(self.availableusers)            
            }

          })
            .catch( function(){

              //self.cockpitsettingsmodalshown = false
            })
            .finally( () => {
              loading(false)
              self.ajustlengthdown()

             //ajustlengthup
           })

          }else{

            self.availableusers = []
            self.ajustlengthdown()
            self.searchquery = ""

          }

        }, 500)
        

      },


      switchrole( user, role, confirm ){

        var sharedtousers = []
       
        if( this.loggedinuser._id == user._id && role != 'Owner' && !confirm ){

          this.removerole = true
          this.activeuser = user
          this.torole = role
          //user.role = "Owner"
          return false

        }

        this.activeuser = {}
        this.torole = ""

        this.numowners = 0
        user.role = role

        for( var i in this.cockpit.users ){

          var newrole = this.cockpit.users[i].role

          if( user._id == this.cockpit.users[i]._id ){
            newrole = role
          }
          sharedtousers.push( { "id": this.cockpit.users[i]._id , "role":newrole, "status":this.cockpit.users[i].status } )

          if( this.cockpit.users[i].role.toLowerCase() == "owner"){
            this.numowners++
          }
        }

        //console.log( sharedtousers )

        this.$emit('onupdated', "users", sharedtousers, {"action":"switchrole"} )

        //this.cockpitupdate( "users", sharedtousers ) 

         
        //alert('')
      },

      userSelect( selected, force ){


        var sharedtousers = []

        //let self = this

        if( selected ){

          var newavailableusers = []
          var existingids = []

          for( i in this.cockpit.userids ){
            existingids.push( this.cockpit.userids[i] )
          }

          //console.log( existingids, selected._id, existingids.indexOf(selected._id) )

          //alert( existingids.indexOf(selected._id) )

          if( existingids.indexOf(selected._id) == -1 ){

          for( var i in this.cockpit.users ){
            sharedtousers.push( { "id": this.cockpit.users[i]._id , "role":this.cockpit.users[i].role, "status":this.cockpit.users[i].status } )
          }

          for( i in this.availableusers ){

            if( this.availableusers[i]._id != selected._id ){

              newavailableusers.push( this.availableusers[i] )              

            }else{

              this.availableusers[i].role = "Viewer"

              this.cockpit.users.push( this.availableusers[i] )
              this.cockpit.userids.push( this.availableusers[i]._id )

              sharedtousers.push( { "id": this.availableusers[i]._id , "role":"Viewer"} )

            }

          }

          if( force == true ){
            selected.role = "Viewer"
            selected.mode = 'idle'
            this.cockpit.users.push( selected )
            sharedtousers.push( { "id": selected._id , "role":"Viewer"} )
            this.sendaninvitetouser = ""
          }

          this.availableusers = []
          this.availableusers = newavailableusers

          this.$emit('onupdated', "users", sharedtousers , { "action" : "adduser" })


          }

          


        }



        this.ajustlengthup()

      },


      tryremoveuser( user, action, removeself){

        var sharedtousers = []

        ///let self = this
       // console.log(this.loggedinuser._id == user._id, this.loggedinuser._id, user._id)
       if(this.loggedinuser._id == user._id  && !removeself){
        this.removeuser = true
        this.activeuser = user

        //alert('a')
      }else{
        //console.log( action, user )

        if(this.activeuser){
          this.activeuser.mode='idle'
        } 

        if( action=='tryremove'){
          this.approvalneed = 'remove'; 
          this.showapproval = true 
          this.activeuser = user
          user.mode = "deleting"
        }

        if( action=='cancel'){
          this.activeuser = user
          this.showapproval = false
          user.mode = "idle"
        }

        if( action=='confirm'){

          this.showapproval = false
          var newusers = []
          var newuserids = []

          this.numowners = 0

          for( var i in this.cockpit.users ){

           if( this.cockpit.users[i]._id != user._id ){
            newuserids.push( this.cockpit.users[i]._id )
            newusers.push( this.cockpit.users[i] )

            if( this.cockpit.users[i].role.toLowerCase() == "owner"){
              this.numowners++
            }

            sharedtousers.push( { "id": this.cockpit.users[i]._id , "role":this.cockpit.users[i].role, "status":this.cockpit.users[i].status } )
          }

        }

        this.cockpit.users = newusers
        this.cockpit.userids = newuserids
        //this.cockpitupdate( "users", sharedtousers, function(){
        //  self.loadusers()
        //})

        this.$emit('onupdated', "users", sharedtousers, {"action":"removeuser"} )


        if( removeself == true ){

          setTimeout( function(){


            location.href = '/boards/'          


          }, 1000)

        }

        this.removeuser = false

      }
    }

  },

  searchUsers( search){

        //loading(true)

        this.isworking = true

        let self = this
        
        Cockpitsapi.Getusers( search, this.cockpit.userids )
        .then( function(response){

          if( response.data ){

            self.availableusers = response.data.users
          }

        })
        .catch( function(){

          self.isworking = false

            //self.cockpitsettingsmodalshown = false
          })
        .finally( () => {
           // loading(false)
           self.isworking = false
         })


      },


      loadusers(){
        let self = this        
        Cockpitsapi.Getusers( "", this.cockpit.userids )
        .then( function(response){
          if( response.data ){
            self.availableusers = response.data.users
          }
        })


      },

      searchfocus(){

       // alert('')
       this.sendaninvite = false
       this.ajustlengthup()
     },


     ajustlengthdown(){

      /*this.$nextTick(function(){
        var height = $('.vs__dropdown-menu').height()
        var modalheight = $('.modal-body .innerbody').height()
        $('#membersmodal___BV_modal_body_').height(height + modalheight + 'px')
      })*/
    },
    ajustlengthup(){
    // $('#membersmodal___BV_modal_body_').height('auto')
   }



 }

}

</script>