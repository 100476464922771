<template>
	<div class="cockpitview2">





		<b-modal v-model="exportloading" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc>
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Export stakeholder management document</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="exportloading = false"></i>
	          </a>
	        </template>
	        <div>
	        	<p>Create a stakeholder report by exporting stakeholder information to a Microsoft Word document using a standardized project management framework template.</p><p>Select a template and click export to proceed.</p>


				<div class="dropdown">
				  <button class="btn btn-block text-left btn-secondary-light" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  	<div style="position:relative;">
				  	<i class="doc fas fa-file-word mr-2"></i> <span>{{ selectedforexport.label }}</span> 
				    <span style="position:absolute; right: 10px">
				    	<i class="fas fa-caret-down"></i>
				    </span>
					</div>
				  </button>
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    <div style="color:#000;" class="dropdown-item text-black" :key="key" v-for="( template, key) in exporttemplates" @click="setforexport(template)">
				    <i class="doc fas fa-file-word mr-2"></i> {{ template.label }}</div>
				  </div>
				</div>


				<p>&nbsp;</p>
				
	        </div>

	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Export" @no ="cancel" @yes = "doexport(selectedforexport.key)"></button-gruppe>
	        </template>
        </b-modal>

				<b-modal v-model="confirmstakeholder" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Delete stakeholder?</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="confirmstakeholder = false"></i>
	          </a>
	        </template>
	        <span class = "text-danger"> Warning:</span> Deleting a stakeholder may cause that stakeholder's contacts to lose access to the board. Do you want to proceed?
	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Yes proceed" @no ="cancel" @yes = "confirmdeletestakeholder(activestakeholder)"></button-gruppe>
	        </template>
        </b-modal>

        <b-modal @hide="addnewstakeholderformhidden" @shown="addnewstakeholderformshown" v-model="addnewstakeholder" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Add stakeholder</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="addnewstakeholder = false"></i>
	          </a>
	        </template>
	        <form onsubmit="return false" v-if="canwrite" class="mt-2">
						

		  			<div class="mb-3">
					    <label class="form-label">Stakeholder name</label>
					    <input class="form-control card p-2 noshadow readonly titletext mb-0" type="text" v-model="stakeholdername" ref="newstakeholdernameinput" placeholder="Assign a name to this person or group."> 
					    <span class="text-danger hide" v-if="!allowsave">name is required</span>
					  </div>

						<div class="mb-3">
					    <label class="form-label">Description</label>
					    <textarea class="form-control card p-2 noshadow readonly titletext mb-0 autogrow"  style="resize: vertical; min-height: 72px;" v-model="stakeholderdescription" ref="newstakeholderdescription" placeholder="Describe the interest and influence the stakeholder has in the project."></textarea>
					  </div>

					  <div class="mb-3">
					    <label class="form-label">Activities</label>
					    <textarea class="form-control card p-2 noshadow readonly titletext mb-0 autogrow"  style="resize: vertical; min-height: 72px;" v-model="stakeholderactivities" ref="newstakeholderactivities" placeholder="List project activities that will support the stakeholder."></textarea>
					  </div>


		  			<button @click="createnewstakeholder()" style="position: absolute; left: -100000px; height: 1px;" type="submit"></button>
		  			
		  		</form>
	        <template v-slot:modal-footer="{ cancel, ok }">

	        	<div v-if="withstakeholderupdates>0" class="text-success flex-fill">{{withstakeholderupdates}} stakeholder{{withstakeholderupdates>1?'s':''}} added</div>

	        	<button class="ml-1 btn btn-secondary" @click="addnewstakeholder = false" type="button">{{ withstakeholderupdates > 0 ? 'Close' :   'Cancel'}}</button>

	        	
	        	<button v-show="allowsave" class="ml-1 btn btn-secondary" @click="createnewstakeholder(false)" type="button">Save and close</button>

	        	<button v-show="allowsave" class="ml-1 btn btn-primary" @click="createnewstakeholder(true)" type="button">Save and add another</button>
	          	
	        	<button v-show="!allowsave" class="ml-1 btn btn-secondary" type="button" disabled="disabled">Save and close</button>
	        	<button v-show="!allowsave" class="ml-1 btn btn-primary" type="button" disabled="disabled">Save and add another</button>
	          	
	        </template>
        </b-modal>
	       	
	       	


	       <div class="">

	       	<pre v-if="1==2" style="position:absolute; right: 20; width: 300px;">{{ nearstakeholders }}</pre>

	     <div id="graphwrapper">

         <div id="interactivepowerslider"  @touchstart="viewtouch">

         	<div class="mb-2 mt-3"> 
	       	<button v-if="canwrite" class="btn btn-primary" @click="addnewstakeholder=true"> <i style="font-size:18px" aria-hidden="true" class="fa fa-plus-circle mr-2 "></i> Add stakeholder</button>

	       	<b-dropdown id="preExport" text="Export" :class="{'ml-1':canwrite}">
			    <b-dropdown-item :key="key" v-for="( template, key) in exporttemplates" @click="setforexport(template)"><i class="doc fas fa-file-word mr-2"></i> {{ template.label }}</b-dropdown-item>
			  </b-dropdown>

	        </div>


					<table style="float: right;" :class="'x_'+windowspec" class="axis">
				      <tr style="height:33%">
				        <td style="text-align: left; vertical-align: top;"><p class="pl-2 mt-2">
				        	<span v-if="windowspec!='sm'">High</span>
				        	<small v-else>High</small>
				        </p></td>
				      </tr>
				      <tr style="height:33%; position: relative;">
				        <td style=" text-align: left; transform: rotate(-90deg); position: absolute; top: 50%; " class="trait">Power</td>
				      </tr>
				      <tr style="height:33%">
				        <td style="text-align: left; vertical-align: bottom;"><p class="pl-2 mb-2"><span v-if="windowspec!='sm'">Low</span>
				        	<small v-else>Low</small></p></td>
				      </tr>
				    </table>

         <div :class="'x_'+windowspec+' y_'+windowspec" class="graph mb-1" style="float:left; border: solid 1px #000; position: relative;">

			    <table class="table-bordered" width="100%" height="100%" align="center">      
			      <tr>
			        <td class="text-muted action" width="50%" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;">Keep satisfied</td>
			        <td class="text-muted action" width="50%" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;">Manage closely</td>
			      </tr>
			       <tr>
			        <td class="text-muted action" width="50%" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;">Monitor</td>
			        <td class="text-muted action" width="50%" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;">Keep informed</td>
			      </tr>           
			    </table>


         	<div @touchmove.prevent.stop="touchhandler" @touchend.prevent.stop="touchhandler" @touchstart.prevent.stop="touchhandler" @click.prevent.stop="clickhandler(sh)" :id="'drag_'+sh._id" :key="sh._id" v-for="sh in sortedstakeholders" :style="'position:absolute; top:'+ powerposition(sh.power)+'px; margin-left:-7px; margin-top:-7px; left:'+ interestposition(sh.interest) + 'px;'" class="draggable touchable">
         		<div style="position: relative;">


         		<span v-if="sh.interest>65" :ref="'flipshlabel_'+sh._id" class="shlabel left" :style="'left:'+myleft(sh._id)+ '; width:'+mywidth(sh._id)" >
			      	<span v-if="sh._id==activestakeholder._id" class="p-1"><span style="color: #000; background-color: rgba(255, 255, 255, .9);">{{ sh.label }}</span></span>
			      	<span v-else class="p-1"><span style="color: #000;background-color: rgba(255, 255, 255, .9);">{{ sh.label }}</span></span>
			    	</span>

         		<i  @click="moodinfo($event)" @mouseover="onmouseover( 'in', sh)" @mouseleave="onmouseover( 'out', sh)" class="fas fa-circle marker float-right" :class="mood(sh.mood)" ></i>

         		<span v-if="sh.interest<=65" :ref="'shlabel_'+sh._id" class="shlabel">
			      	<span v-if="sh._id==activestakeholder._id" class="p-1"><span style="color: #000;background-color: rgba(255, 255, 255, .9);">{{ sh.label }}</span></span>
			      	<span v-else class="p-1"><span style="color: #000;background-color: rgba(255, 255, 255, .9);">{{ sh.label }}</span></span>
			    	</span>

			    	
			    </div>
        	</div>        			


					<b-popover custom-class="popoverstakeholders" :show.sync="showpopover" :placement="focusstakeholder.interest<=65?'right':'left'" animation="false" :target="'drag_'+focusstakeholder._id" triggers="manual" v-if="focusstakeholder._id"> 


						<div @mouseover="onmouseover( 'in', focusstakeholder)" @mouseleave="onmouseover( 'out' )"  v-if="nearstakeholders.length > 1 || isTruncated( focusstakeholder._id )" style="overflow: hidden;">
								<template v-for="nsh in nearstakeholders">
		          				<a v-if="focusstakeholder._id == nsh._id" :key="nsh._id" class="dropdown-item pl-1"  @click="onselected(nsh,true,true)"> 
									<i class="fas fa-circle marker" :class="mood(nsh.mood)" ></i> {{ nsh.label }}</a>
								<a v-else :key="nsh._id" class="dropdown-item pl-1" @click="onselected(nsh,true,true)"><i class="fas fa-circle marker" :class="mood(nsh.mood)"></i> {{ nsh.label }}</a>	
								</template>
			       		</div>

					</b-popover>

        	
         	
         </div>

         <table style="clear: both;" :class="'y_'+windowspec" class="axis">
			      <tr>
			        <td width="33%" style="text-align: left;"><p style="margin-left:10px"><span v-if="windowspec!='sm'">Low</span>
				        	<small v-else>Low</small></p></td>
			        <td width="33%" style="text-align:center;" class="trait">Interest</td>
			        <td width="33%" style="text-align: right;"><p style="margin-right:10px"><span v-if="windowspec!='sm'">High</span>
				        	<small v-else>High</small></p></td>
			      </tr>
			    </table>

				

       	 </div>

       	</div>

       	 <div id="stakeholdersidebar" :class="{ 'lg':windowsize>1005, 'border-left':windowsize>1005, 'sticky-top':windowsize>1005, 'pl-2 pr-2 pb-2':windowsize>1005, 'm-auto':windowsize<1006, 'mobile':ismobile  }">					
					<template v-if="activestakeholder._id && mode == 'edit'">

          <div> 

         	<div :class="{ 'p-0':ismobile }" >

         		<div class="d-flex align-items-center mt-2">
	  				<div class="flex-grow-1">

						<div v-if="canwrite" class="editable2">

							<div v-if="!editlabel">
								<h3 class="m-0 fw-bold" v-if="!activestakeholder.label"><span @click="doeditlabel">--</span></h3>
								<h3 class="m-0 fw-bold" v-else-if="activestakeholder.label==''"><span @click="doeditlabel">--</span></h3>
								<h3 class="m-0 fw-bold" v-else><span @click="doeditlabel">{{ activestakeholder.label }}</span></h3>
								<span class="text-danger" v-if="activestakeholder.label==''">name is required</span>
							</div>


							<div class="has-success mt-2" v-else>

								<input maxlength="60" type="" name="stakeholdername" class="form-control card p-2 noshadow readonly titletext mb-0" v-model="activestakeholderlabel" ref="inputstakeholderlabel">

								<div class="text-right mt-1">
									<button @click.stop.prevent="canceledit" class="btn btn-secondary mr-1" type="button">Cancel</button>
									<button @click.stop.prevent="updatestakeholder(activestakeholder,'label', activestakeholderlabel)" class="btn btn-primary" type="button">Save</button>
								</div>

							</div>

						</div>
						<div v-else class="">
							<h3 >{{ activestakeholder.label }} </h3>
						</div>

	  				</div>
  				<div>

  					<b-dropdown v-if="canwrite && !editlabel" id="deletecloserisk" text="..." class="m-md-2" right no-caret variant="link" toggle-class="text-decoration-none">
					  	 <template #button-content>
					      <i aria-hidden="true" class="far mr-0 fa-ellipsis-v"></i>
					    </template>
					    <b-dropdown-item  @click="trydeletestakeholder()"><span class="actiondelete">Delete stakeholder</span></b-dropdown-item>
					    
					  </b-dropdown>

  				</div>
  				</div>         	
         	
         	

         	<div class="mb-3" :class="{'editable2':editactivities==false}">

         	<label class="form-label h4label">Description</label>      	

         	<div class="has-success editable2" style="position: relative;" v-if="!editdescription">

         		<div @click.stop.prevent="doeditdescription" class="card noshadow" :class="{'pb-4':showmoredesc}">

         		<template>
         		<div id="inputstakeholderdescriptionviewer" ref="inputstakeholderdescriptionviewer" v-if="editdescription == false" class="p-2 readonly titletext mb-0 description" style="min-height: 52px;" v-html="nl2br(activestakeholderdescription)" :class="{'showless':!showmoredesc}">         		
         		</div>
         		</template>

         		<div :class="{'showlessbtnwrapper':isoverflowed(activestakeholderdescription)}"  >

	         	<div :class="{'grad':!showmoredesc}">&nbsp;</div>
	         	<div style="background: #fff;" class="p-1">
		         	<div @click.stop.prevent="showmoredesc=showmoredesc?false:true" v-if="isoverflowed(activestakeholderdescription) && !showmoredesc && editdescription == false" class="text-center showmorebtn p-1"><i style="font-size: 16px;" class="fal fa-angle-down"></i> Show more</div>
		         	<div @click.stop.prevent="showmoredesc=showmoredesc?false:true " v-if="isoverflowed(activestakeholderdescription) && showmoredesc && editdescription == false" class="text-center showlessbtn p-1"><i style="font-size: 16px;" class="fal fa-angle-up"></i> Show less</div>
	         	</div>
	         	</div>

	         </div>


         	</div>

         	<textarea placeholder="Describe the interest and influence the stakeholder has in the project." onfocus='this.style.height = "";this.style.height = ( this.scrollHeight + 10 ) + "px"' oninput='this.style.height = "";this.style.height = ( this.scrollHeight + 10 ) + "px"' style="resize: vertical; min-height: 123px;" v-if="editdescription==true" name="stakeholderdescription" class="form-control card p-2 noshadow readonly titletext mb-0 autogrow" v-model="activestakeholderdescription" ref="inputstakeholderdescription"></textarea>

         	<div class="text-right mt-1"  v-if="editdescription==true">
	         		<button @click.stop.prevent="canceledit" class="btn btn-secondary mr-1" type="button">Cancel</button>
	         		<button @click.stop.prevent="savedescription(activestakeholder,'description', activestakeholderdescription)" class="btn btn-primary" type="button">Save</button>
	         	</div>


         	</div>



         	<div class="mb-3" :class="{'editable2':editdescription==false}">

         	<label class="form-label h4label">Activities</label>

         	<div class="has-success editable2" style="position: relative;" v-if="!editactivities">

         		<div @click.stop.prevent="doeditactivities" class="card noshadow" :class="{'pb-4':showmoredesc}">

         		<template>
	         	<div id="inputstakeholderactivitiesviewer" ref="inputstakeholderactivitiesviewer" v-if="editactivities == false" class="p-2 readonly titletext mb-0 activities" style="min-height: 52px;" v-html="nl2br(activestakeholderactivities)" :class="{'showless':!showmoreactivities}">
	         	</div>
	         	</template>

         		<div :class="{'showlessbtnwrapper':isoverflowed(activestakeholderactivities)}"  >

	         	<div :class="{'grad':!showmoreactivities}">&nbsp;</div>
	         	<div style="background: #fff;" class="p-1">
		         	<div @click.stop.prevent="showmoreactivities=showmoreactivities?false:true" v-if="isoverflowed(activestakeholderactivities) && !showmoreactivities && editactivities == false" class="text-center showmorebtn p-1"><i style="font-size: 16px;" class="fal fa-angle-down"></i> Show more</div>
		         	<div @click.stop.prevent="showmoreactivities=showmoreactivities?false:true " v-if="isoverflowed(activestakeholderactivities) && showmoreactivities && editactivities == false" class="text-center showlessbtn p-1"><i style="font-size: 16px;" class="fal fa-angle-up"></i> Show less</div>
	         	</div>
	         	</div>


         		</div>


         	</div>

         	<textarea placeholder="List project activities that will support the stakeholder." onfocus='this.style.height = "";this.style.height = ( this.scrollHeight  + 10 ) + "px"' oninput='this.style.height = "";this.style.height = ( this.scrollHeight  + 10 ) + "px"' style="resize: vertical; min-height: 123px;" v-if="editactivities==true" name="stakeholderactivities" class="form-control card p-2 noshadow readonly titletext mb-0 autogrow" v-model="activestakeholderactivities" ref="inputstakeholderactivities"></textarea>

         	<div class="text-right mt-1"  v-if="editactivities==true">
	         		<button @click.stop.prevent="canceledit" class="btn btn-secondary mr-1" type="button">Cancel</button>
	         		<button @click.stop.prevent="onsaveactivities(activestakeholder,'activities', activestakeholderactivities)" class="btn btn-primary" type="button">Save</button>
	         	</div>


         	</div>



         	<div class="mb-3">
         		<label class="h4label">Stakeholder Attitude</label>

         	<div >

         		<template v-if="canwrite">

         		<vueselect id="pickmood" class="statuschooser mb-2" :searchable="false" :clearable = "false" :options = "attitudevalues" :autoscroll="false" @input="moodpick" v-model="attitudevalue">
					<template #open-indicator="{ attributes }">
			            <span v-bind="attributes"> <i class="fas fa-sort"></i></span>
			          </template>
			          <template #option="{ color, label }">
			          	<div class ="d-flex align-items-center p-1">
				            <div class ="icon-holder">
				              <span><i class="fas fa-circle" :class="{'red':color=='red','green':color=='green','yellow':color=='yellow'}"></i></span>
				            </div>
				            <div :class="{'droptext':ismobile}" >{{label}}</div>
				          </div>
			          </template>
			           <template #selected-option="{ color, label }">
			            <div class="d-flex align-items-center p-1">
			          		<div class="pl-2"><i class="fas fa-circle" :class="color"></i></div>
							<div class="flex-grow-1 pl-2">{{label}}</div>
						</div>
			          </template>
			          <template #selected-option-container="{ option, deselect, multiple, disabled }">
			          <div class="d-flex align-items-center p-1">
			          		<div class="pl-2"><i class="fas fa-circle" :class="option.color"></i></div>
							<div class="flex-grow-1 pl-2">{{option.label}}</div>
						</div>
			          </template>				
				</vueselect> 

				</template>
				<template v-else>

						<div class="d-flex">
			          		<div class="pl-2"><i class="fas fa-circle" :class="activestakeholder.mood"></i></div>
							<div class="flex-grow-1 pl-2">{{ getLabel(activestakeholder.mood) }}</div>
						</div>
					
				</template>
			</div>

         </div>
         	<div class="mb-3">


         		<label class="h4label">Contacts</label>        		

         		<contactsListTable :updatecontactlist="updatecontactlist" :activestakeholder="activestakeholder" @oncontactlistupdate="oncontactlistupdate" :canwrite="canwrite"></contactsListTable>
 
         	</div>

         	<div class="mb-1" v-if="canwrite && 1==2">
         	<button class="btn btn-danger" @click="trydeletestakeholder()">Delete stakeholder</button>
         	</div>

         	</div>
         </div>

         </template>

       </div>

         


     		<p class="clearfix">&nbsp;</p>
     </div>





      </div>
</template>

<style type="text/css">

	.fw-bold{
		font-weight: bold;
	}

	.statuschooser .vs__search{
   /* visibility: hidden;*/
    height: 1px;
    opacity: 0;
  }

	#interactivepowerslider{
		width: fit-content;
		margin: auto;
	}

	#stakeholdersidebar {
		background: #fff;
	  scrollbar-width: thin;          /* "auto" or "thin" */
	  scrollbar-color: var(--N200);
	}

	#stakeholdersidebar{
		min-width: 360px;
		max-width: 360px;
	}

	#stakeholdersidebar.mobile{
		min-width: auto;
		max-width: auto;
	}

	#stakeholdersidebar.lg{
		position: fixed;
		height: 90vh;
		top: 72px;		
		right: 0px;
		overflow-y: scroll;
	}

	/*tooltip.light .tooltip-inner, .tooltip.dark .tooltip-inner, .tooltip .tooltip-inner {
    font-size: 15px;
    font-weight: 400;
    height: 1px;
	}

	.tooltip .tooltip-inner {
    background-color: rgba(255, 255, 255, .7) !important;
    color: var(--N900);
	}

	.arrow {
    display: none !important;
	}*/

		.vs__selected{
	  /*display: none !important;*/
	  /*margin: 0 !important;
	  padding: 0 !important;*/
	  margin: 0 0 0 0 !important;
	  padding: 0 0 !important;
	}

	.vs__dropdown-option:hover{
      color: #000 !important;
    }

    .vs__open-indicator{
    color:#000 !important;
    margin: auto
  }

  .vs__dropdown-option--highlight{
    background-color: $N100;
    color:#000 !important;
  }

  #pickmood .vs__actions{
    display: block !important;
  }



	.popoverstakeholders .popover-body{
		padding: 0 !important;
	}

	.shlabel.left{
		text-align: right;
		position:absolute; 
		width: 100px;
		left: -100px; 
		top: -5px; 
		overflow: hidden; 
		text-overflow: ellipsis; 
		white-space: nowrap;
		display: block;
		offset-anchor: right;
	}

	.shlabel{
		position:absolute; 
		width: 100px;
		left: 16px; 
		top: -5px; 
		text-align: center; 
		overflow: hidden;
		text-overflow: ellipsis; 
		white-space: nowrap; 
		text-align: left;
	}

	.ui-selected .shlabel{
		width: auto;
		font-weight: bolder;
	}


	.ui-draggable-dragging .shlabel{
		width: auto;
	}

	.ui-selected .shlabel.left, .ui-draggable-dragging .shlabel.left{
		width: auto;
		text-align: right;
		/*left: myleft;*/
	}

	.draggable{
		cursor: pointer;
	}


</style>
<style type="text/css" scoped>

		.trait{
			font-weight: bold;
		}

		.lwidth_xl{
			/*float: left;*/
		}
		.rwidth_xl{
			/*float: left;
			width: 390px;*/
		}

		.lwidth_lg{
			float: left;
		}
		.rwidth_lg{
			float: left;
			width: 390px;
		}

		.lwidth_md{
			width: 400px;
			/*margin: auto;*/
		}
		.rwidth_md{
			/*margin: auto;*/
			width: 390px;
		}

		.lwidth_sm{
			/*margin: auto;*/
		}
		.rwidth_sm{
			margin: auto;
			/*width: 336px;*/
		}

		.iconactive_xl{
			font-size: 40px;
		}

		.iconactive_lg{
			font-size: 40px;
		}

		.iconactive_md{
			font-size: 36px;
		}
		
		.iconactive_sm{
			font-size: 30px;
		}

		.icon_xl{
			font-size: 32px;
		}

		.icon_lg{
			font-size: 32px;
		}

		.icon_md{
			font-size: 28px;
		}
		
		.icon_sm{
			font-size: 22px;
		}

		.x_sm{
			height: 300px;
		}
		.x_sm td{
	/*		font-size: 10px;*/
		}
		.x_sm.axis{
			width: 35px;
		}
		.x_sm.axis td{
			padding-right: 0px;
			/*transform: rotate(-90deg);*/
		}
		.x_sm .trait{
/*			font-size: 12px;*/
		}

		.y_sm{
			width: 300px;
		}
		.y_sm td{
	/*		font-size: 10px;*/
		}
		.y_sm.axis{
			margin-left: 0px;
		}
		.y_sm.axis td{
			/*padding-right: 10px;*/
		}
		.y_sm .trait{
/*			font-size: 12px;*/
		}
		.y_sm td.action{
/*			font-size: 15px;*/
		}


		.x_md{
			height: 400px;
		}
		.x_md td{
/*			font-size: 12px;*/
		}
		.x_md.axis{
			width: 40px;
		}
		.x_md.axis td{
			/*padding-right: 10px;*/
		}
		.x_md .trait{
/*			font-size: 15px;*/
		}


		.y_md{
			width: 400px;
		}
		.y_md td{
/*			font-size: 12px;*/
		}
		.y_md.axis{
			/*margin-left: 40px;*/
		}
		.y_md.axis td{
			/*padding-right: 10px;*/
		}
		.y_md .trait{
/*			font-size: 15px;*/
		}
		.y_md td.action{
/*			font-size: 18px;*/
		}

		.x_lg{
			height: 500px;
		}
		.x_lg td{
/*			font-size: 15px;*/
		}
		.x_lg.axis{
			width: 45px;
		}
		.narrow_lg{
			margin-top: 15px;
			margin-left: 10px;
		}
		.narrow_md{
			margin-top: 15px;
			margin-left: 10px;
		}
		.narrow_sm{
			margin-top: 15px;
		}

		.x_lg.axis td{
			/*padding-right: 10px;*/
		}
		.x_lg .trait{
/*			font-size: 20px;*/
		}


		.y_lg{
			width: 500px;
		}
		.y_lg td{
			font-size: 15px;
		}
		.y_lg.axis{
		margin-left: 5px;
		}
		.y_lg.axis td{
			/*padding-right: 10px;*/
		}
		.y_lg .trait{
	/*		font-size: 20px;*/
		}
		.y_lg td.action{
	/*		font-size: 22px;*/
		}


		.x_xl{
			height: 600px;
		}
		.x_xl td{
/*			font-size: 15px;*/
		}
		.x_xl.axis{
			width: 45px;
		}

		.y_xl{
			width: 600px;
		}
		.y_xl td{
			font-size: 15px;
		}
		.y_xl.axis{
		margin-left: 5px;
		}

		.x_xxl{
			height: 700px;
		}
		.y_xxl{
			width: 700px;
		}
		.x_xxxl{
			height: 800px;
		}
		.y_xxxl{
			width: 800px;
		}



		.minwidth_sm{
			max-width: 350px;
		}

		.minwidth_md{
			min-width: 510px;
		}

		.minwidth_lg{
			min-width: 580px;
		}

		.action{
			font-size: 140%;
			text-align: center;
		}


		.green{ color: var(--G500) }
		.red{ color: var(--R500) }
		.yellow{ color: var(--Y500) }

   .contact-selected{
   	border: solid 2px blue;
   }

	.stakeholder{
		border: solid 1px #000;
		padding: 3px;
		background-color: #fff;
	}

	.dragcontent{
		position:absolute; 
		width: auto;
		left: 20px;
		min-width: 100px;
	}

	.vdr.active::before{
		outline: none;
	}

	.ui-selectable:hover{
		cursor: pointer;
	}


	.highlight{
		border: solid 1px #000;
		padding: 5p ;
	}

	.table th, .table td{
		padding: 0;
	}

	.vs__selected{
	  display: none !important;
	}


	/*.ui-draggable-dragging{
		transform: scale(1.7);
	}*/

	.ui-selected .marker{
		transform: scale(1.2);
	}

	.touch-start{
		transform: scale(1.5);
	}

	/*.ui-selected span{
		position: relative;
		width: auto;
	}*/
</style>
<script type="text/javascript"></script>

<script>

	import Userapi from '@/services/api/users'
	import Teamsapi from '@/services/api/teams'

	import Emailsapi from '@/services/api/emails'
	import {bus} from '@/main'

	import contactsListTable from './contactsListTable'
	import memberlistfull from '@/components/members/memberlistfull'
	
	import vueselect from '@/views/tools/vueselect'

	//import autosize from 'autosize'

/*var Arc = function(x, y, radius, radians) {
  this.x = x;
  this.y = y;
  this.radius = radius;
  this.radians = radians;
  this.isDragging = false;

  this.render = function(ctx) {
    ctx.save();


    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, this.radians, false);
    ctx.fillStyle = '#2793ef';
    ctx.fill();

    ctx.restore();
  }
}*/

	export default{
		name: 'stakeholders',
		data(){
			return{
				descriptionboxoverflown : false,
				activitiesboxoverflown : false,
				attitudevalue:{"color":"green","label":"Supportive"},
				attitudevalues:[{"color":"green","label":"Supportive"},{"color":"yellow","label":"Neutral"},{"color":"red","label":"Critical"}],
				cancelevent:false,
				selectedforexport : { 'label':"Projektgrundlag interresentstyring (DK)", 'key':"dk1" } ,
				exporttemplates : [ { 'label':"Projektgrundlag interresentstyring (DK)", 'key':"dk1" } , { 'label':"Stakeholder management (EN)", 'key':"en1"  } ],				
				stakeholderactivities:"",
				activestakeholderactivities : "",
				editactivities: false,
				stakeholdername:"",
				stakeholderdescription:"",
				stakeholderactivities:"",
				width: 100,
				height: 0,
				top: 0,
				left: 0,
				isdragging: false,
				stakeholders : [],
				activestakeholder : {},
				tempstakeholder : {},
				focusstakeholder : {},		
				activestakeholderdescription : "",
				activestakeholderlabel : "",
				textcontact:"",
				textemail:"",
				windowsize:0,
				showtooltip:true,
				showpopover:false,
				showpopovermood:false,
				nofade:true,
				sortedstakeholders:[],
				mode:"idle",
				confirmstakeholder:false,
				mouseovertimer:0,
				mouseovert : null,
				addnewstakeholder: false,
				addanother: false,
				boardusers : [],
				editlabel: false,
				editdescription: false,
				exportloading: false,
				imgbody : null,
				touchevent : "",
				touchtimer : null,
				apidomain:"",
				uiupdate:0,
				updatecontactlist:0,
				withstakeholderupdates:0,
				showmoredesc:false,
				showmoreactivities:false,
			}
		},

		props:{
		},

		watch:{

			'activestakeholder.label' : function( e, ev ){

				if( e == '' ){

					this.activestakeholder.label = ev
				}

			},

			addnewstakeholder : function( e ){
				this.activestakeholder = {}
				this.showpopover = false
			},

			stakeholders : function( e ){

				this.sortedstakeholders = e 
			},
			activestakeholder : function( e ){

   			this.updateboardusers()
			},

			cockpit : function( e ){

				if( !e.stakeholders ){

					let currentroute = this.$router.history.current.path
					let newroute = currentroute.replace("stakeholders", "board")
					location.href=newroute

					//this.$router.push( 'board' )
				}
			}
		},

		computed:{

			ismobile(){

				return this.$store.state.isMobile
			},
			

			/*descheight(){
					return $('#inputstakeholderdescriptionviewer').height()
			
			},/**/

			allowsave(){

				let notallowed = 0 
				if( this.stakeholdername == '' ){
					notallowed += 1
				}

				if( notallowed == 0 ){
					return true
				}else{
					return false
				}
			},

			nearstakeholders(){

				let newstakeholders = []

				////////console.log( this.focusstakeholder )

				for( var i in this.sortedstakeholders){

					let power = this.focusstakeholder.power - this.sortedstakeholders[i].power
					let interest = this.focusstakeholder.interest - this.sortedstakeholders[i].interest

					//console.log( power, interest)

					let proceed = 0

					let gap = 5

					if( power < gap && power > -(gap) ){
						proceed += 1
					}

					if( interest < gap && interest > -(gap) ){
						proceed += 1
					}

					let sh = this.sortedstakeholders[i]
					//{ power:this.sortedstakeholders[i].power, interest:this.sortedstakeholders[i].interest, label:this.sortedstakeholders[i].label}

					if( proceed == 2 && this.focusstakeholder._id != this.sortedstakeholders[i]._id){
						newstakeholders.push( sh )
					}

					if( this.focusstakeholder._id == this.sortedstakeholders[i]._id ){
						newstakeholders.push( sh )
					}
					
				}

				if( newstakeholders.length > 0 ){
					return newstakeholders
				}else{
					return []
				}				

			},

			canwrite(){

				let toreturn = false

				if( this.cockpit && ( this.cockpit.myrole.toLowerCase()=='owner' || this.cockpit.myrole.toLowerCase()=='contributor')){
					toreturn = true
				}
				if( this.cockpit && ( this.cockpit.myrole.toLowerCase()!='owner' && this.cockpit.myrole.toLowerCase()!='contributor')){
					toreturn = false
				}

				if( !this.cockpit.isactive ){
					toreturn = false
				}

				//toreturn  = false
				return toreturn
			},

			loggedinuser(){
			    return this.$store.getters.user
			},

			team(){
				return this.cockpit.stakeholders
			},
		

			activestakeholdercontacts(){

				var toreturn = this.activestakeholder.contacts

				toreturn.push( { _id:"createnewcid", state:'idle', firstname:"", lastname:"", email:"", externalemail:"", "status":"stakeholder" } )

				return toreturn
			},

			singlecol(){

				if( this.windowsize < 1087 ){
					return 'narrow'
				}else{
					return 'wide'
				}
				 
			},

			windowspec(){

				var ismobile = this.$store.state.isMobile

				var winsize = ""

				if(  ismobile || this.windowsize < 460 ){
					winsize = 'sm'
				}

				if( this.windowsize >= 461 && this.windowsize < 1050 ){
					winsize = 'md'
				}

				if( this.windowsize >= 1050){
					winsize = 'lg'
				}

				if( this.windowsize >= 1200){
					winsize = 'xl'
				}

				if( this.windowsize >= 1400){
					winsize = 'xxl'
				}

				if( this.windowsize >= 1600){
					winsize = 'xxxl'
				}

				return winsize

			},

			cockpitid(){
        return this.$route.params.cid
      },

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

      token(){
        return this.$store.getters.token
      },

      cockpit(){
				return this.$store.getters.GetCockpit
			},

		}, 

		created() {
		  window.addEventListener("resize", this.windowOnresize);
		},
		destroyed() {
		  window.removeEventListener("resize", this.windowOnresize);
		},

		mounted(){


			this.stakeholdername = ""
			this.stakeholderdescription = ""
			this.stakeholderactivities = ""


		/*this.$root.$on('bv::tooltip::hide', bvEvent => {
			//this.showtooltip = true
      //falert('')
      //this.$root.$emit('bv::show::tooltip')
      bvEvent.preventDefault()
    })*/

			this.windowOnresize()


			this.getallstakeholders()



			

/*
			setTimeout( function(){


			var canvas = document.getElementById("mycanvas");
			var con = canvas.getContext("2d");

      con.fillStyle = "#000";
      con.fillRect(50,50, 400, 400);
      con.fillStyle = "#fff";
      con.fillRect(51,51, 398, 398);
      // draw font in red
      con.fillStyle = "#000";
      con.font = "16px sans-serif";
      con.fillText("High", 10, 90);
      con.fillText("High", 400, 480);
      con.fillText("Low", 10, 420);
      con.fillText("Low", 80, 480);	
	

      con.fillText("Keep satisfied", 120, 120);
      con.fillText("Monitor closely", 280, 120);
      con.fillText("Monitor", 120, 380);
      con.fillText("Keep informed", 280, 380);

      con.fillStyle = "#000";
      con.font = "24px sans-serif";

      con.fillText("Interest", 220, 480);

      con.save()
      con.rotate(-Math.PI/2)
      con.fillText("Power", -280, 30);
      con.restore()


      var circle = new Arc(200, 140, 6, Math.PI * 2);
			circle.render(con);

			},10)*/


				if( !this.cockpit.stakeholders ){

					let currentroute = this.$router.history.current.path
					let newroute = currentroute.replace("stakeholders", "board")
					location.href=newroute
					//this.$router.push( 'board' )
				}


		},

		filters: {
  			nl2br ( text ){

				let toreturn = ""

				if( text ){
					toreturn = text.replaceAll("\n","<br>")
				}

				return toreturn
		    },
		},

		methods:{

			isoverflowed( strtext ){

				let text = strtext

				let words = 0
				let newlines = 0
				//let chars = 0

				if( text ){
					//chars = text.length
					words = text.split(" ").length 
					newlines = text.split("\n").length 
					words = words + ( newlines * 8)
				}
				
				return words > 45 ? true : false
			},

			isdescriptionboxoverflown(){

				let toreturn = ""				

				let el = document.getElementById('inputstakeholderdescriptionviewer');

				if( el ){
					toreturn =  el.scrollHeight != el.clientHeight
				}				

				this.descriptionboxoverflown = toreturn

			},

			isactivitiesboxoverflown(){

				let toreturn = ""				

				let el = document.getElementById('inputstakeholderactivitiesviewer');

				if( el ){
					toreturn =  el.scrollHeight != el.clientHeight
				}				

				this.activitiesboxoverflown = toreturn

			},


			getLabel( color ){

				let toreturn = ""

				this.attitudevalues.forEach( function( val ){

					if( val.color == color ){
						toreturn = val.label
					}
				})

				return toreturn

			},

			moodpick( opt ){
				this.updatestakeholder( this.activestakeholder, 'mood', opt.color )
			},


			moodinfo(){

				this.showpopovermood = true

				const exampleEl = document.getElementById('example')
				const popover = new bootstrap.Popover(exampleEl, options)


			},

			nl2br ( text ){

				let toreturn = ""

				if( text ){
					toreturn = text.replaceAll("\n","<br>")
				}

				return toreturn
		    },

			onblursaveactivities( activestakeholder, field, activestakeholderactivities ){


				let self = this

				setTimeout( function(){

					if( self.cancelevent == false ){
						self.updatestakeholder( activestakeholder,'activities', activestakeholderactivities )
					}

				}, 200)	

			},

			onsaveactivities( activestakeholder, field, activestakeholderactivities ){

				this.cancelevent = true
				this.updatestakeholder(activestakeholder,'activities', activestakeholderactivities)

			},

			onblursavedescription( activestakeholder, field, activestakeholderdescription ){

				let self = this

				setTimeout( function(){

					if( self.cancelevent == false ){
						self.updatestakeholder(activestakeholder,'description', activestakeholderdescription)
					}

				}, 200)	
			},

			savedescription( activestakeholder, field, activestakeholderdescription ){

				this.cancelevent = true
				this.updatestakeholder(activestakeholder,'description', activestakeholderdescription)
			},

			setforexport( template ){

				//this.selectedforexport = template
				this.doexport(template.key)

				//console.log( template )

			},

			mywidth( iid ){

				let el = this.$refs['flipshlabel_'+iid]

				if(  el && el[0] ){

				return el[0].scrollWidth + 'px'
					
				}

				
			},

			myleft( iid ){

				let el = this.$refs['flipshlabel_'+iid]

				if(  el &&  el[0] ){

				return '-' + el[0].scrollWidth + 'px'
					
				}

				
			},

			viewtouch(){

				this.showpopover = false
			},

			clickhandler( sh ){

				var ismobile = this.$store.state.isMobile
				if( ismobile )
				return false


				this.onselected( sh , true )

			},

			touchhandler( touch ){

				this.touchevent = touch.type
						

				let self = this

				let id = $(touch.target).parents('.touchable').get(0).id.replace('drag_','')			


				//this.showpopover = false
				

				if( touch.type == "touchstart" && touch.currentTarget ){

					//var ismobile = this.$store.state.isMobile
					/**/

					self.focusstakeholder = {}
					self.showpopover = false

					for( var i in self.stakeholders ){

						//////console.log( self.stakeholders, self.stakeholders[i]._id, id )

						if( self.stakeholders[i]._id == id ){

							let sh = self.stakeholders[i]

							this.focusstakeholder = sh

							setTimeout( function(){
								self.showpopover = true
							}, 500)

							
 //
							//if( this.nearstakeholders.length > 1 ){												
							//	self.showpopover = true
								//return false
							//}else{

								self.onselected( self.stakeholders[i] , true )
							//}
							
						}						
					}



					this.touchtimer = setTimeout( function(){						

						$(touch.target).parents('.touchable').addClass("touch-start")
						self.touchevent = "touchhold"
						self.showpopover = false

					}, 900)


					
				}

				if( touch.type == "touchend" && touch.currentTarget ){

					clearTimeout(this.touchtimer)

					$('.touch-start').removeClass("touch-start")

				}
				
				if( touch.type == "touchmove" && touch.currentTarget ){
					this.showpopover = false
				}
				
			},

			doinitexport(){

				this.exportloading = true

			},

			doexport( template ){

				let self = this

				/*this.exportloading = true

				//window.location.href="/static/export.docx"

				var node = document.getElementById('interactivepowerslider');

				domtoimage.toPng(node)
				    .then(function (dataUrl) {
				        //var img = new Image();
				        //img.src = dataUrl;
				        //document.body.appendChild(img);
				        self.$refs.exportimg.src = dataUrl;
				    })
				    .catch(function (error) {
				        console.error('oops, something went wrong!', error);
				    });*/

				
				

				Userapi.Exportstakeholder( template, this.cockpitid, this.token ).then( function(response){

					self.exportloading = false
					self.apidomain = response.data.apidomain
					location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
				})
				


			},

			

			canceledit(){

				this.cancelevent = true

				this.activestakeholderlabel = this.activestakeholder.label
				this.activestakeholderdescription = this.activestakeholder.description

				if( this.activestakeholder.activities ){
					this.activestakeholderactivities = this.activestakeholder.activities
				}else{
					this.activestakeholderactivities = ""
				}	
				

				this.editlabel = false
				this.editdescription = false
				this.editactivities = false

			},

			doeditactivities(){

				if( !this.canwrite ){
					return false
				}

				this.cancelevent = false

				if( this.editdescription == true ){
					return false
				}

				let self = this

				let hh = $('#inputstakeholderactivitiesviewer').height()

				this.editactivities = true
				this.editlabel = false
				this.editdescription = false

				setTimeout( function(){

					//autosize($('.autogrow'));

					$('#inputstakeholderactivitieseditor').height(hh+10)
					//alert(hh)
					//self.$refs.inputstakeholderdescription.offsetHeight = "500px"
					self.$refs.inputstakeholderactivities.focus()
				},50)

			},


			doeditdescription(){

				if( !this.canwrite ){
					return false
				}

				this.cancelevent = false

				if( this.editactivities == true ){
					return false
				}

				let self = this

				let hh = $('#inputstakeholderdescriptionviewer').height()

				this.editdescription = true
				this.editlabel = false

				setTimeout( function(){

					//autosize($('.autogrow'));

					$('#inputstakeholderdescriptioneditor').height(hh+10)
					//alert(hh)
					//self.$refs.inputstakeholderdescription.offsetHeight = "500px"
					self.$refs.inputstakeholderdescription.focus()
				},50)

			},


			doeditlabel(){

				let self = this

				this.editlabel = true
				this.editdescription = false
				this.editactivities = false

				setTimeout( function(){
					self.$refs.inputstakeholderlabel.focus()


				},100)

				
			},

			updateboardusers(){


					let teamids = []

	   			for( var i in this.cockpit.sharedtoteams ){
						teamids.push( this.cockpit.sharedtoteams[i].userid )
					}

					 let boardusers = []

	        if( this.cockpit.sharedtoteams && teamids.indexOf( this.team._id ) > -1){	       

						for( var i in this.team.sharedtousers ){

								if( boardusers.indexOf( this.team.sharedtousers[i].userid ) == -1 ){
									boardusers.push( this.team.sharedtousers[i].userid )
								}
								
						}

	        }

					this.boardusers = boardusers

			},
			closeotherbox( el ){


				this.activestakeholder = {}
			},

			isTruncated( iid ) {

				let el = this.$refs['shlabel_'+iid]

				if( el && el[0] ){
					return el[0].offsetWidth < el[0].scrollWidth
				}else{
					return false
				}
			   
			},

			savestakeholder( sh ){

				//console.log( sh,  'savestakeholder')

				var updatevalue = { "t":this.token, "action":"updatestakeholder", "field":'updatestakeholder', "label":sh.label, "description":sh.description, "activities":sh.activities, "mood":sh.mood, "objectid": sh._id }

				Userapi.UpdateStakeholder( updatevalue ).then( function(response){

				})
	
			},

			addnewstakeholderformhidden(){

				this.withstakeholderupdates = 0

				if( this.stakeholders ){
					this.onselected( this.stakeholders[  this.stakeholders.length - 1 ], false )
				}
				
			},


			addnewstakeholderformshown(){

				this.withstakeholderupdates = 0

				this.stakeholdername = ""
				this.stakeholderdescription = ""
				this.stakeholderactivities = ""

				this.$refs.newstakeholdernameinput.focus()

				//autosize($('.autogrow'));
				//alert('')
			},


			sortstakeholders(){

				let stakeholders = this.sortedstakeholders

				let newstakeholders = []

				
				let current = {}

				for( var i in stakeholders ){

					if( this.activestakeholder._id != stakeholders[i]._id ){
						newstakeholders.push( stakeholders[i] )
					}else{
						current = stakeholders[i]
					}
					
				}

				newstakeholders.push( current )

				

				this.sortedstakeholders = newstakeholders
				//return newstakeholders
			},

			mood( mood ){

				if(!mood || mood == ''){
					mood = 'green'
				}
				return mood

			},

			validateEmail(email) {

    			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			return re.test(String(email).toLowerCase());

			},

			removeuserfromteam( userid, isforced , callback ){


					let self = this

					Teamsapi.Removeuser( this.token, this.team._id, userid, true, isforced, "checkstakeholders" ).then( function( response ){

						if( response.data.ok ){
							//self.$emit('onuserisaddedtostakeholderteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey, "remove" ).then( function( response2 ){

								if( callback ){
									callback()
								}
							})
							
						}
						//Teamsapi.Updateuserstatus( option._id, self.orgkey , 'add').then( function( response2 ){						
						//		self.getteams(response.data.team._id)					
						//})

					})

			},

			addusertoteam( userid , callback){


					let self = this

					Teamsapi.Adduser( this.token, this.team._id, userid ).then( function( response ){

						if( response.data.ok ){
							//self.$emit('onuserisaddedtostakeholderteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey , 'add').then( function( response2 ){	

								if( callback ){
									callback()
								}					
							//		self.getteams(response.data.team._id)					
							})

						}


					})

			},


			windowOnresize(){

				let self = this

				this.windowsize = window.innerWidth

				setTimeout( function(){

					var box = document.getElementById("graphwrapper");
					

					let width = self.windowsize - 360 - 140

					box.style.width = width + 'px'
					/*if( width < 769 ){
						width = 768
					}*/

					if( self.windowsize < 1006 ){
						width = 545
						box.style.width = 'auto'
					}

					
					

				}, 10)

			},

			trydeletestakeholder(){

				this.confirmstakeholder = true
			},



			confirmdeletestakeholder( sh ){

				let self = this


				var contacts = []

				if( sh.contacts ){
					for( var i in sh.contacts ){
						//alert( sh.contacts[i].userid )
						contacts.push( sh.contacts[i]._id )
					}
				} 

				Userapi.Deletestakeholder( this.cockpitid, sh._id, this.token ).then( function(response){

					if( response.data.ok ){
						self.stakeholders = response.data.stakeholders
						self.activestakeholder = {}
						self.confirmstakeholder = false


						for( var i in contacts ){
							self.removeuserfromteam( contacts[i], false )
						}

						setTimeout( function(){

							if( self.stakeholders[0] ){
								self.onselected( self.stakeholders[0] , true, true)
							}

						}, 200)

						
					}

				})
			},

			oncontactlistupdate( update ){

				let self = this

				this.updatestakeholder( this.activestakeholder, update.act, update.value, function( response ){

					if( response.ok ){
						self.updatecontactlist++						
					}

				} )
			},

			updatestakeholder( sh, act, value, callback){



				let self = this

				var updatevalue = { "t":this.token, "action":"update", "field":act, "objectid": sh._id }

				if( act == 'label' && value != '' ){
					updatevalue.label = value
					sh.label = value
				}

				if( act == 'description' ){
					updatevalue.description = value
				}

				if( act == 'activities' ){
					updatevalue.activities = value
				}

				if( act == 'mood' ){
					updatevalue.mood = value
				}

				if( act == 'contacts' ){

					/*var newcontacts = []

					if( sh.contacts != null )  {
						newcontacts = sh.contacts
					}					

					if( value.firstname && value.lastname && value.email && value.type ){
						newcontacts.push( value )

						this.textcontact = ""
						this.textemail = ""
					}
					*/

					
					//console.log( value._id , value.firstname ,value.lastname , value.externalemail ,value.type )

					if( value._id && value.firstname ){						

						let tosubmit = { "cid":value._id , "firstname":value.firstname , "lastname":value.lastname, "externalemail":value.externalemail, "email":"" } 

						updatevalue.contacts = JSON.stringify( tosubmit )
					}

					
				}

				if( act == 'removecontact' ){


					updatevalue.replacecontact = JSON.stringify( value )


				}

				if( act == 'removefromboard' ){

					updatevalue.removefromboard = JSON.stringify( value )

					this.removeuserfromteam( value.userid, true , function(){

						self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
							if( response.data.ok){
								self.updateboardusers()
							}
						})

					})

				}


				if( act == 'replacecontact' ){

					updatevalue.replacecontact = JSON.stringify( value )

					


				}

				if( act == 'invitecontact' ){


					updatevalue.replacecontact = JSON.stringify( value )


				}

				if( act == 'updatecontact' ){


					updatevalue.replacecontact = JSON.stringify( value )


				}

				if( act == 'addcontact' ){


					updatevalue.replacecontact = JSON.stringify( value )


				}

				

				Userapi.UpdateStakeholder( updatevalue ).then( function(response){

					//if( act == 'contacts' ){

						self.cancelevent = false

						if( response.data.stakeholder ){
							self.activestakeholder = self.formatstakeholder( response.data.stakeholder ) 


							for( var i in self.sortedstakeholders ){
								if( self.sortedstakeholders[i]._id == response.data.stakeholder._id ){
									self.sortedstakeholders[i] = response.data.stakeholder
								}
							}
						}

						if( act == 'label' ){

							self.editlabel = false

							$( self.$refs.inputstakeholderlabel ).addClass("form-control-success")

							setTimeout( function(){
								$( self.$refs.inputstakeholderlabel ).removeClass("form-control-success")
							}, 1000)
						}

						if( act == 'description' ){

							self.editdescription = false

							$( self.$refs.inputstakeholderdescription ).addClass("form-control-success")

							setTimeout( function(){
								$( self.$refs.inputstakeholderdescription ).removeClass("form-control-success")
							}, 1000)
						}	

						if( act == 'activities' ){

							self.editactivities = false

							$( self.$refs.inputstakeholderactivities ).addClass("form-control-success")

							setTimeout( function(){
								$( self.$refs.inputstakeholderactivities ).removeClass("form-control-success")
							}, 1000)
						}	
					//}


						if( response.data.stakeholders ){
						//	self.newstakeholders( response.data )
						}

						//addusertoteam

						/*if( act == 'addcontact' ){

							let uid = value.userid ? value.userid : ( value.cid ? value.cid : null )

							self.addusertoteam( uid , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										self.updateboardusers()
									}
								})

							})
							
						}*/

						/*if( act == 'replacecontact' ){

							let uid = value.userid ? value.userid : ( value.cid ? value.cid : null )


							self.addusertoteam( uid , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										self.updateboardusers()
									}
								})

							})
							
						}*/

						//alert(act)

						/*if( act == 'invitecontact' ){
							

							if( response.data.contact && response.data.contact.externalemail ){

								self.addusertoteam( value.userid )
								Emailsapi.Invitationemail( self.loggedinuser._id, response.data.contact.externalemail, self.orgkey)
					     		var str = "We have sent an invitation to the email address you indicated."
					      		bus.$emit('craeteaToast', {content:str,title:'Invite sent', variant:'info',append:true, sup:''})

							}
							

						}*/

						if( act == 'removecontact' ){

							/*console.log(  )

							let numstakeholders = 0

							for( var i in this.stakeholders ){
								let sh = this.stakeholders[i]

								if( sh.contacts )
								for( var j in sh.contacts ){
									if( sh.contacts[j].userid == value.userid ){

									}
								}
							}*/

							self.removeuserfromteam( value.userid, false , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										self.updateboardusers()
									}
								})


							})
						}

						if( callback ){
							callback( {"ok":"isnow"} )
						}

				})


				//console.log( sh, act, value )
			},

			unselect(){
				this.activestakeholder = {}
			},


			onmouseover( act, sh ){

				//this.showpopovermood = false

				var ismobile = this.$store.state.isMobile

				if( ismobile )
				return false

				clearTimeout(this.mouseovert)

				let self = this


				if( act == 'out' ){

					this.mouseovert = setTimeout( function(){

						self.focusstakeholder = {}
						self.showpopover = false

					}, 100)
					
				}				

				if( this.showpopover == false ){						

						this.mouseovert = setTimeout( function(){

							if( self.focusstakeholder ){
								self.focusstakeholder = sh
							}else{
								self.focusstakeholder = {}
							}
							
							self.showpopover = true
							clearTimeout(this.mouseovert)
						}, 20)

				}
				
			},
			onselected( sh , showinfo, isforced ){	

				this.editlabel = false
				this.editdescription = false
				this.editactivities = false
				
				this.showpopover = false

				let self = this


				let obj = $('#drag_'+sh._id).get(0)


				$('.ui-selected').not(obj).removeClass('ui-selected').removeClass('bg-info')
				$(obj).addClass('ui-selected')


					// if( $(obj).hasClass('ui-selected') ){

						this.mode = "edit"	


						this.activestakeholder = sh		



						this.attitudevalue = { "color": sh.mood, "label": this.getLabel(sh.mood)  }	

						self.sortstakeholders()		

						if( showinfo ){
							Userapi.Stakeholder( sh._id, this.token ).then( function(response){

								if( response.data.ok ){


									self.activestakeholder = self.formatstakeholder( response.data.stakeholder ) 
									self.activestakeholderlabel = self.activestakeholder.label
									self.activestakeholderdescription = self.activestakeholder.description
									self.activestakeholderactivities = self.activestakeholder.activities

									/**/

									/*setTimeout( function(){
										self.isactivitiesboxoverflown()
										self.isdescriptionboxoverflown()
									}, 100)*/
													

									

								}

							})
						}		

					//}else{

						//this.activestakeholder = {}

						//$( '#drag_'+sh._id ).css('z-index', 2500)
					//}

					setTimeout( function(){
						//self.$root.$emit('bv::show::tooltip')
					}, 100)


			},

			positioninterest( pos ){

				var div = 0


				switch(this.windowspec) {
				  case 'sm':
				    div = 3
				    break;
				  case 'md':
				    div = 4
				    break;
				  case 'lg':
				    div = 5
				    break;
				  case 'xl':
				    div = 6
				    break;
				  case 'xxl':
				    div = 7
				    break;
				   case 'xxxl':
				    div = 8
				    break;				    
				  default:
				    // code block
				}

				/*var div = this.windowspec == 'sm' ? 3 : ( this.windowspec == 'md' ? 4 : ( this.windowspec == 'lg' ? 5 : ( this.windowspec == 'xl' ? 6 : ( this.windowspec == 'xxl' ? 7 : ( this.windowspec == 'xxl' ? 8 : 9 ) ) ) ) )*/

				return ( pos+0 ) / div

			},

			positionpower( pos ){

				var div = 0


				switch(this.windowspec) {
				  case 'sm':
				    div = 3
				    break;
				  case 'md':
				    div = 4
				    break;
				  case 'lg':
				    div = 5
				    break;
				  case 'xl':
				    div = 6
				    break;
				  case 'xxl':
				    div = 7
				    break;
				   case 'xxxl':
				    div = 8
				    break;				    
				  default:
				    // code block
				}

				return ( ( div * 100 ) - pos ) / div
			},

			interestposition( value ){

				var div = 0


				switch(this.windowspec) {
				  case 'sm':
				    div = 3
				    break;
				  case 'md':
				    div = 4
				    break;
				  case 'lg':
				    div = 5
				    break;
				  case 'xl':
				    div = 6
				    break;
				  case 'xxl':
				    div = 7
				    break;
				   case 'xxxl':
				    div = 8
				    break;			
				}

				return Math.round(value) * div

				//return value + "%; margin-left: -7px;"

			},
			powerposition( value ){

				var div = 0

				switch(this.windowspec) {
				  case 'sm':
				    div = 3
				    break;
				  case 'md':
				    div = 4
				    break;
				  case 'lg':
				    div = 5
				    break;
				  case 'xl':
				    div = 6
				    break;
				  case 'xxl':
				    div = 7
				    break;
				   case 'xxxl':
				    div = 8
				    break;			
				}

				return  ( div * 100 ) - ( Math.round(value) * div )

				//return value + "%; margin-left: -7px;"

			},

			ondragging( ){

				//console.log( i.width, "xxxx" )

				//this.isdragging = true
				//i.width = 20
			},

			offdragging( i, ev ){

				/////////console.log( ev, i, "xxxx" )

				/*Userapi.Stakeholder( ] ).then( function(response){

						console.log( response, "xxxxxxxx")
					})*/


			},

			formatstakeholder( stakeholder ){


				var newcontacts = []

				if( stakeholder.contacts != null ){
				
					var contacts = stakeholder.contacts
					
					for( var i in contacts ){
						contacts[i].errormessage = ""
						contacts[i].state = 'idle'
						newcontacts.push( contacts[i] ) 
					}

				}

				stakeholder.contacts = newcontacts

				return stakeholder
			},

			newstakeholders( data ){


				var newstakeholders = []
				for( var i in this.stakeholders ){																

					if( this.stakeholders[i]._id == data.stakeholder._id ){
						newstakeholders.push( data.stakeholder )
						this.activestakeholder = data.stakeholder
					}else{

						newstakeholders.push( this.stakeholders[i] )
					}
				}

				this.stakeholders = newstakeholders

			},

			bindDraggables(){

				if( this.canwrite ){

				let self = this


							setTimeout( function(){

								if( self.sortedstakeholders ){

									/////*//////for( var i in self.sortedstakeholders ){
										//grid: [ 32, 32 ],

										//alert( self.stakeholders[i]._id )

										//console.log( $( '#drag_' + self.sortedstakeholders[i]._id ) , "init")

										var ismobile = self.$store.state.isMobile

										$( '.draggable' ).draggable( {  delay: ( ismobile ? 800 : 100 ), zIndex: 2500, containment: "parent", drag: function( j,k ){

												const power = self.positionpower( k.position.top )
												const interest = self.positioninterest( k.position.left )

												if( self.activestakeholder ){

													self.activestakeholder.power = power
													self.activestakeholder.interest = interest

													if( self.activestakeholder._id == self.focusstakeholder._id ){
														self.focusstakeholder.power = power
														self.focusstakeholder.interest = interest
													}
													
												}

												//console.log( self.activestakeholder, power, interest )

											j.stopPropagation();

										}, start: function( j,k ){

											let currentsh = j.currentTarget.id.replace('drag_','')

											for( var i in self.sortedstakeholders ){
												if( currentsh ==  self.sortedstakeholders[i]._id ){
													self.onselected( self.sortedstakeholders[i], false )
												}
											}

											//self.onselected( self.sortedstakeholders[i] )

											//self.activestakeholder = self.sortedstakeholders[i]

											//console.log( j.currentTarget )

											//

											//alert('dragstart')

											

											$('.ui-selected').removeClass('ui-selected').removeClass('bg-info')

											$(j.currentTarget).addClass('ui-selected')

											/*if( self.stakeholders[i] ){

												if( self.$refs['tooltip_'+self.stakeholders[i]._id] ){

													//console.log(self.$refs['tooltip_'+self.stakeholders[i]._id][0]  )
													self.$refs['tooltip_'+self.stakeholders[i]._id][0].$emit('close')
												}

												//self.$refs['tooltip_'+self.stakeholders[i]._id].$emit('close')
												//self.$root.$emit( 'bv::hide::tooltip', 'tooltip_'+self.stakeholders[i]._id )
											}*/


											j.stopPropagation();
											

										}, stop: function( j,k ) {		

											const objectid =  j.target.id.replace('drag_', '')



												for( var i in self.sortedstakeholders ){
													if( objectid ==  self.sortedstakeholders[i]._id ){
														self.onselected( self.sortedstakeholders[i], true )
														self.focusstakeholder = self.sortedstakeholders[i]
													}
												}

												/*for( var i in self.stakeholders ){
													if( objectid ==  self.stakeholders[i]._id ){
														self.stakeholders[i] = self.focusstakeholder
													}
												}		*/

												
												const power = self.positionpower( k.position.top )
												const interest = self.positioninterest( k.position.left )

												Userapi.UpdateStakeholder( { "t":self.token, "action":"update", "field":"values", "objectid": objectid, "power":power, "interest":interest } ).then( function(response){

															if( response.data.ok ){

																self.uiupdate++



																//self.newstakeholders( response.data )


																//sh = response.data.stakeholder
															}


															//self.$root.$emit('bv::show::tooltip', 'tooltip_'+self.stakeholders[i]._id)

												})


												j.stopPropagation();



							      } } );

									//////*//}
								}							


								//self.$root.$emit('bv::show::tooltip')  

							}, 100 )

				}

			},

			getallstakeholders(  ){


				let self = this

				Userapi.Stakeholders( this.cockpitid, this.token ).then( function(response){

						//this.getallstakeholders

						if( response.data.stakeholders ){						

							self.stakeholders = response.data.stakeholders




							//var containment = document.querySelector('#interactivepowerslider');

							self.bindDraggables()

							setTimeout( function(){
								//self.$root.$emit('bv::show::tooltip')

								if( self.stakeholders[0] ){
									self.onselected( self.stakeholders[0] , true, true)
								}

							}, 200)
							

						}
				
					})




			},

			createnewstakeholder( addanother ){

				let self = this

				if( this.stakeholdername != '' ){

					let newstakeholder = { "label":this.stakeholdername, "description":this.stakeholderdescription, "activities":this.stakeholderactivities, "power":50.0, "interest":50.0 }

					//this.stakeholders.push( newstakeholder )

					this.stakeholdername = ""
					this.stakeholderdescription = ""
					this.stakeholderactivities = ""

					//NewStakeholder

					Userapi.NewStakeholder( { "t":this.token, "action":"new", "boardid":this.cockpitid, "label": newstakeholder.label, "description": newstakeholder.description, "activities": newstakeholder.activities, "power":newstakeholder.power, "interest":newstakeholder.interest } ).then( function(response){

						self.stakeholders = response.data.stakeholders

						self.bindDraggables()

						if( addanother == false ){
							self.addnewstakeholder = false
						}else{

							self.withstakeholderupdates += 1

							setTimeout( function(){

								self.$refs.newstakeholdernameinput.focus()
								
							}, 100)
						}

						for( var i in self.stakeholders ){

						}

						setTimeout( function(){

							if( self.stakeholders[ self.stakeholders.length - 1 ] ){
								self.onselected( self.stakeholders[ self.stakeholders.length - 1 ] , true, true)
							}
								
						}, 100)


						
						
						
						//console.log( response, "xxxxxxxx")
					})

				}

				//$('.tinydraggable').tinyDraggable();


			}
		},

		components:{ contactsListTable, vueselect }

	}
</script>