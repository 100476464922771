<template>
	<div class="cockpitview2">

		<b-modal @hide="exportwindowclosed" v-model="exportloading" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc>
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Export risk report</h3>
	          <a class = "close-modal dialog-close-bustaketton mr-2">
	            <i class="fal fa-times fa-lg " @click="exportloading = false"></i>
	          </a>
	        </template>
	        <div>
	        	<p>Create a risk report by exporting risk information, using a standard project management framework template.</p><p>Select a template and click export to proceed.</p>


				<div class="dropdown">
				  <button class="btn btn-block text-left btn-secondary-light" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  	<div style="position:relative;">
				  	<i v-if="selectedforexport.type=='doc'" class="doc fas fa-file-word mr-2"></i>
				  	<i v-if="selectedforexport.type=='xls'" class="xls fas fa-file-excel mr-2"></i>
				    <span>{{ selectedforexport.label }}</span> 
				    <span style="position:absolute; right: 10px">
				    	<i class="fas fa-caret-down"></i>
				    </span>
					</div>
				  </button>
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    <div style="color:#000;" class="dropdown-item text-black" :key="key" v-for="( template, key) in exporttemplates" @click="setforexport(template)"><i v-if="template.type=='doc'" class="doc fas fa-file-word mr-2"></i><i v-if="template.type=='xls'" class="xls fas fa-file-excel mr-2"></i> {{ template.label }}</div>
				  </div>
				</div>


				<p>&nbsp;</p>
				<p>&nbsp;</p>
				
	        </div>

	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Export" @no ="cancel" @yes = "doexport(selectedforexport.key)"></button-gruppe>
	        </template>
        </b-modal>


         <b-modal v-model="showclosedrisk" id="closingriskpanel" size="md" hide-footer @hide="oncloseclosedrisks">
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Closed risks</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="showclosedrisk = false"></i>
	          </a>
	        </template>

	        <table class="table table-borderless">
			  <tbody v-if="closedrisks.length > 0">
			    <tr  v-bind:key="cntr" v-for="(item, cntr) in closedrisks" class="border-bottom">
			      <td style="width:50%;" class="pb-3 pt-2">{{ item.label }} </td>
			      <td style="width:25%;" class="text-right pb-3 pt-2"><span class="badge badge-light">{{ item.occured ? 'occured' : 'not occured' }}</span></td>
			      <td v-if="canwrite" style="width:25%;" class="text-right pb-3 pt-2"><a tabindex="-1" class="actionprimary" @click.prevent="reopenrisk( item )">Reopen</a></td>
			    </tr>
			  </tbody>
			  <tbody v-else>
			  	<tr>
			  		<td colspan="3">No closed risks</td>
			  	</tr>
			  	
			  </tbody>
			</table>


        </b-modal>

        <b-modal v-model="closingrisk" id="closingriskpanel" size="sm">
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Close this risk</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="closingrisk = false"></i>
	          </a>
	        </template>

	        <div class="mb-2">You are about to close the following risk:</div>

	        <div class="B100-b p-2 mb-1">{{activerisk.label}}</div>

	       

	        <div class="mb-2">Why is the risk being closed?</div>

			<div class="form-check p-0" >
			  <input class="form-check-input p-0 m-0"  type="radio" name="Riskoccured" id="Riskoccured" value="true" v-model="closingvalue">
			  <label class="form-check-label" for="Riskoccured">
			    Occured
			  </label>
			</div>

			<div class="form-check p-0 mb-2">
			  <input class="form-check-input p-0 m-0"  type="radio" name="Riskoccured" id="RiskNotoccured" value="false" v-model="closingvalue" >
			  <label class="form-check-label" for="RiskNotoccured">
			    Not Occured
			  </label>
			</div>

			<div class="mb-2">

			<p class="">Add a note (optional):</p>

			 <textarea style="height:90px;" class="form-control border-normal" v-model="closingremarks" placeholder="Give some context to explain why the risk is being closed." >
	         </textarea>

	        </div>

			<template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "OK" @no ="cancel" @yes="closewithselection()" :disabled2="closingvalue == null"></button-gruppe>
	        </template>

        </b-modal>

		<b-modal v-model="confirmrisk" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Confirm delete risk</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="confirmrisk = false"></i>
	          </a>
	        </template>
	        <span class = "text-danger"> Warning:</span> You are about to delete a risk, this may cause the risk owner to lose access to the board. Do you want to proceed?
	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Delete risk" @no ="cancel" @yes = "confirmdeleterisk(activerisk)"></button-gruppe>
	        </template>
        </b-modal>

        <b-modal v-model="explainvalues" id ="explainvalues" size="sm-p" no-close-on-backdrop no-close-on-esc hide-footer >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Help</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="explainvalues = false"></i>
	          </a>
	        </template>

		<div>
			<h3>Consequence</h3>
			<p>
			The scale 1 to 5 should be interpreted as follows:<br>
			1: Insignificant consequences - It will be annoying and result in extra work, but will not affect the project deliverables<br>
			3: Significant consequences - One or more of the project dimensions will be negatively affected (Budget, scope, time, quality)<br>
			5: Critical consequences - Project is in danger of closing. Project targets and business case can not be met.
			</p>
		</div>

		<div class="mb-2">

			<h3>Probability</h3>
			<p>
			Indicates the probability that the risk occurs, using the scale <br>1:(0-20%), 2:(20-40%), 3:(40-60%), 4:(60-80%), 5:(80-100%)</p>			
		</div>


        </b-modal>

        <b-modal @hide="addnewriskformhidden" @shown="addnewriskformshown" v-model="addnewrisk" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Add risk</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="addnewrisk = false"></i>
	          </a>
	        </template>
	        <form onsubmit="return false" v-if="canwrite" class="mb-2">
						

		  			<div class="mb-3">
					    <label class="form-label">Risk name</label>
					    <input class="form-control card p-2 noshadow readonly titletext mb-0" type="text" v-model="riskname" ref="newrisknameinput" placeholder="Give it a name that identifies the potential risk or issue."> 
					    <span class="text-danger hide" v-if="!allowsave">name is required</span>
					  </div>

						<div class="mb-3">
					    <label class="form-label">Description</label>
					    <textarea class="form-control noshadow readonly titletext mb-0 autogrow"  style="resize: vertical; min-height: 72px;" v-model="riskdescription" ref="newriskdescription" placeholder="Briefly describe the risk and its potential impact."></textarea>
					  </div>


		  			<button @click="createnewrisk()" style="position: absolute; left: -100000px; height: 1px;" type="submit"></button>
		  			
		  		</form>
	        <template v-slot:modal-footer="{ cancel, ok }">
	        	

	        	<div v-if="withriskupdated>0" class="text-success flex-fill">{{withriskupdated}} risk{{withriskupdated>1?'s':''}} added</div>

	        	<button class="ml-1 btn btn-secondary" @click="addnewrisk = false" type="button">{{ withriskupdated > 0 ? 'Close' :   'Cancel'}}</button>


	        	
	        	<button v-show="allowsave" class="ml-1 btn btn-secondary" @click="createnewrisk(false)" type="button">Save and close</button>

	        	<button v-show="allowsave" class="ml-1 btn btn-primary" @click="createnewrisk(true)" type="button">Save and add another</button>
	          	
	        	<button v-show="!allowsave" class="ml-1 btn btn-secondary" type="button" disabled="disabled">Save and close</button>
	        	<button v-show="!allowsave" class="ml-1 btn btn-primary" type="button" disabled="disabled">Save and add another</button>
	          	
	        </template>
        </b-modal>
	       	
	       	

	       <div class=""> 

	       	<pre v-if="1==2" style="position:absolute; right: 20; width: 300px;">{{ nearrisks }}</pre>

	     <div id="riskwrapper">

         <div id="interactivepowerslider" @touchstart="viewtouch" class="">

         	<div class="mb-1 mt-2" >
	       	<button ref="addnewriskbutton" v-if="canwrite" class="btn btn-primary" @click="addnewrisk=true"> <i style="font-size:18px" aria-hidden="true" class="fa fa-plus-circle mr-2 "></i> Add risk</button>

	       	<button tabindex="-1" type="button" @click="openclosed()" class="btn btn-secondary ml-1">Closed <span> ({{ closedrisks.length }})</span></button>

	       	<b-dropdown id="prepexport" text="Export" class="m-md-2 ml-1">
			    <b-dropdown-item :key="key" v-for="( template, key) in exporttemplates" @click="setforexport(template)"><i v-if="template.type=='doc'" class="doc fas fa-file-word mr-2"></i><i v-if="template.type=='xls'" class="xls fas fa-file-excel mr-2"></i> {{ template.label }}</b-dropdown-item>
			  </b-dropdown>


	        </div>


					<table style="float: right;" :class="'x_'+windowspec" class="axis">
				      <tr>
				      	<td class="text-right pl-2">5</td>
				      </tr>
				      <tr>
				     
				      	<td class="text-right pl-2">4</td>
				      </tr>
				      <tr>
				      	<td class="text-right pl-2" nowrap="nowrap">3
				      	<div  style="position:relative;">
				      	<p class="trait" style="transform: rotate(-90deg); position: absolute; left: -25px;" @click.stop.prevent="explainvalues=true"><a href="#" style="color: #000">Consequence <i  class="fal fa-info-circle"></i></a></p>
				      	</div>
				      	</td>
				      </tr>
				      <tr>
				      	<td class="text-right pl-2">2</td>
				      </tr>
				      <tr>
				      	<td class="text-right pl-2">1</td>
				      </tr>
				    </table>

         <div :class="'x_'+windowspec+' y_'+windowspec" class="graph mb-1" style="float:left; position: relative;">

			    <table class="table-bordered" width="100%" height="100%" align="center">      
			      <tr>
			      	<td class="lv2">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      </tr>
			      <tr>
			      	<td class="lv1">&nbsp;</td>
			      	<td class="lv2">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      </tr>
			      <tr>
			      	<td class="lv1">&nbsp;</td>
			      	<td class="lv2">&nbsp;</td>
			      	<td class="lv2">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      </tr>
			      <tr>
			      	<td class="lv1">&nbsp;</td>
			      	<td class="lv1">&nbsp;</td>
			      	<td class="lv2">&nbsp;</td>
			      	<td class="lv2">&nbsp;</td>
			      	<td class="lv3">&nbsp;</td>
			      </tr>
			      <tr>
			      	<td class="lv1">&nbsp;</td>
			      	<td class="lv1">&nbsp;</td>
			      	<td class="lv1">&nbsp;</td>
			      	<td class="lv1">&nbsp;</td>
			      	<td class="lv2">&nbsp;</td>
			      </tr>           
			    </table>


         	<div @touchmove.prevent.stop="touchhandler" @touchend.prevent.stop="touchhandler" @touchstart.prevent.stop="touchhandler" @click.prevent.stop="clickhandler(sh)" :id="'drag_'+sh._id" :key="sh._id" v-for="sh in sortedrisks" :style="'position:absolute; top:'+ consequenceposition(sh.consequence)+'px; margin-left:-7px; margin-top:-7px; left:'+ probabilityposition(sh.probability) + 'px;'" class="draggablerisk touchable">
         		<div style="position: relative; color:ffffff;">


         		<span v-if="sh.probability>65" :ref="'flipshlabel_'+sh._id" class="shlabel left" :style="'left:'+myleft(sh._id)+ '; width:'+mywidth(sh._id)" >
			      	<span v-if="sh._id==activerisk._id" class="p-1">{{ sh.label }}</span>
			      	<span v-else class="p-1">{{ sh.label }}</span>
			    	</span>

         		<i v-if="sh.owner._id && loggedinuser._id == sh.owner._id"  @mouseover="onmouseover( 'in', sh)" @mouseleave="onmouseover( 'out', sh)" class="fas fa-dot-circle marker float-right" style="color:#ffffff"></i>
         		<i v-else @mouseover="onmouseover( 'in', sh)" @mouseleave="onmouseover( 'out', sh)" class="fas fa-circle marker float-right" :class="mood()" ></i>


         		<span v-if="sh.probability<=65" :ref="'shlabel_'+sh._id" class="shlabel">
			      	<span v-if="sh._id==activerisk._id" class="p-1">{{ sh.label }}</span>
			      	<span v-else class="p-1">{{ sh.label }}</span>
			    	</span>

			    	
			    </div>
        	</div>

					<b-popover custom-class="popoverrisks" :show.sync="showpopover" :placement="focusrisk.probability<=65?'right':'left'" animation="false" :target="'drag_'+focusrisk._id" triggers="manual" v-if="focusrisk._id"> 

						<div @mouseover="onmouseover( 'in', focusrisk)" @mouseleave="onmouseover( 'out' )"  v-if="nearrisks.length > 1 || isTruncated( focusrisk._id )" style="overflow: hidden;">
								<template v-for="nsh in nearrisks"> 
		          				<a v-if="focusrisk._id == nsh._id" :key="nsh._id" class="dropdown-item pl-1"  @click="onselected(nsh,true,true)"> 
								<i class="fas fa-circle marker" :class="mood()"></i> {{ nsh.label }}</a>
								<a v-else :key="nsh._id" class="dropdown-item pl-1" @click="onselected(nsh,true,true)"><i class="fas fa-circle marker" :class="mood()"></i> {{ nsh.label }}</a>	
								</template>
			       		</div>

					</b-popover>

        	
         	
         </div>

         <table style="clear: both;" :class="'y_'+windowspec" class="axis">

         	<tr>
         		<td class="text-center" style="width: 20%;">1</td>
         		<td class="text-center" style="width: 20%;">2</td>
         		<td class="text-center" style="width: 20%;">3</td>
         		<td class="text-center" style="width: 20%;">4</td>
         		<td class="text-center" style="width: 20%;">5</td>
         	</tr>
         	<tr>
         		<td colspan="5" style="text-align: center;" class="trait" ><a style="color: #000" class="" @click.stop.prevent="explainvalues=true" href="">Probability <i  class="fal fa-info-circle"></i></a></td>
         	</tr>
			    </table>

				

       	 </div>

       	 </div>

       	 <div id="risksidebar" :class="{ 'lg':windowsize>1005, 'border-left':windowsize>1005, 'sticky-top':windowsize>1005, 'pl-2 pr-2 pb-2':windowsize>1005, 'm-auto':windowsize<1006, 'mobile':ismobile }">	
			


					<template v-if="activerisk._id && mode == 'edit'">


			

          <div> 

         	<div :class="{ 'p-0':ismobile }" >

				<div class="d-flex align-items-center mt-2">
					<div class="flex-grow-1" >

						<div v-if="canwrite"  >   

							<div v-if="!editlabel">
								<h3 v-if="!activerisk.label" class="m-0 fw-bold">--</h3>
								<h3 v-else-if="activerisk.label==''" class="m-0 fw-bold">--</h3>
								<h3 v-else class="m-0 fw-bold"><span class="editable2" @click="doeditlabel">{{ activerisk.label }}</span></h3>
								<span class="text-danger" v-if="activerisk.label==''">name is required</span>
							</div>

							<div class="has-success  mt-2" v-else>

								<input type="" name="trackname" class="form-control card p-2 noshadow readonly titletext mb-0" v-model="activerisklabel" ref="inputtracklabel">

								<div class="text-right mt-1">
									<button @click.stop.prevent="canceledit" class="btn btn-secondary mr-1 btn-sm" type="button">Cancel</button>
									<button @click.stop.prevent="updaterisk(activerisk,'label', activerisklabel)" class="btn btn-primary" type="button">Save</button>
								</div>

							</div>

						</div>
						<div v-else class="">
							<h3 >{{ activerisk.label }} </h3>
							</div>
						</div>
					<div>

						<b-dropdown v-if="canwrite && !editlabel" id="deletecloserisk" text="..." class="m-md-2" right no-caret variant="link" toggle-class="text-decoration-none">
							<template #button-content>
							<i aria-hidden="true" class="far mr-0 fa-ellipsis-v" style="font-size: 15px;"></i>
							</template>
							<b-dropdown-item  @click="closingrisk = true"><span class="">Close risk</span></b-dropdown-item>
							<b-dropdown-item  @click="trydeleterisk()"><span class="actiondelete">Delete risk</span></b-dropdown-item>

							
						</b-dropdown>	
					</div>
				</div>   
         	

         	<div class="mb-3">

         	<label class="form-label h4label">Description</label>

         	<div class="has-success editable2" style="position: relative;" v-if="editdescription==false">

	         	<div @click.stop.prevent="doeditdescription" class="card noshadow" :class="{'pb-4':showmoredesc}"> 


	         	<div v-if="editdescription == false" id="inputriskdescriptionviewer" ref="inputriskdescriptionviewer" class="p-2 readonly titletext mb-0 description" style="min-height: 52px;" v-html="nl2br(activeriskdescription)" :class="{'showless':!showmoredesc}"></div>


         		<div :class="{'showlessbtnwrapper':isoverflowed(activeriskdescription)}"  >

	         	<div :class="{'grad':!showmoredesc}">&nbsp;</div>
	         	<div style="background: #fff;" class="p-1">
		         	<div @click.stop.prevent="showmoredesc=showmoredesc?false:true" v-if="isoverflowed(activeriskdescription) && !showmoredesc && editdescription == false" class="text-center showmorebtn p-1"><i style="font-size: 16px;" class="fal fa-angle-down"></i> Show more</div>
		         	<div @click.stop.prevent="showmoredesc=showmoredesc?false:true " v-if="isoverflowed(activeriskdescription) && showmoredesc && editdescription == false" class="text-center showlessbtn p-1"><i style="font-size: 16px;" class="fal fa-angle-up"></i> Show less</div>
	         	</div>
	         	</div>      		
	         		
	         	</div>

         	</div>

         	<textarea placeholder="Briefly describe the risk and its potential impact." id="inputriskdescriptioneditor" oninput='this.style.height = "";this.style.height = ( this.scrollHeight + 10 ) + "px"' onfocus='this.style.height = "";this.style.height = ( this.scrollHeight + 10 ) + "px"' style="resize: vertical; min-height: 123px;" v-if="editdescription==true" name="riskdescription" class="form-control card p-2 noshadow readonly titletext mb-0 autogrow" v-model="activeriskdescription" ref="inputriskdescription"></textarea>

         	<div class="text-right mt-1"  v-if="editdescription==true">
	         		<button @click.stop.prevent="canceledit" class="btn btn-secondary mr-1" type="button">Cancel</button>
	         		<button @click.stop.prevent="onsavedescription(activerisk,'description', activeriskdescription)" class="btn btn-primary" type="button">Save</button>
	         	</div>


         	</div>


         	<div class="mb-3"> 

         	

         	<div>
         	<template v-if="!canwrite">

         		<b-input-group class="" style="width: 100%;">

				<template #prepend>
				  <b-input-group-text class="plain ml-0 pl-0">Risk proximity</b-input-group-text>
				</template>

				<div class="input-group-text plain">
					{{ proximityrisk }}
				</div>  

		        
		    </b-input-group>


         	</template>
			<template v-if="canwrite">	




			<datepicker class="mb-2" :canwrite="canwrite && editaction==false && editdescription==false" @ondatepicked="dodatepicked" :datevalue="proximityrisk" :defvalue="proximityrisk" :allowempty="true" :prependstr="'Risk proximity'"></datepicker>


			
			</template>	
			</div>

         	</div>


         	<div class="mb-3" >

         		<label class="form-label h4label">Mitigating actions</label>

         		<div class="" style="position: relative;" v-if="editaction==false">

	         		<div class="has-success editable2">

	         		<div @click.stop.prevent="doeditaction" class="card noshadow" :class="{'pb-4':showmoredesc}">

	         		<div v-if="editaction == false" id="inputriskactionviewer" ref="inputriskactionviewer" class="p-2 readonly titletext mb-0 description" style="min-height: 52px;" v-html="nl2br(activeriskaction)" :class="{'showless':!showmoreacts}">	         		
	         		</div>

         			<div :class="{'showlessbtnwrapper':isoverflowed(activeriskaction)}"  >
		         	<div :class="{'grad':!showmoreacts}">&nbsp;</div>
		         	<div style="background: #fff;" class="p-1">
			         	<div @click.stop.prevent="showmoreacts=showmoreacts?false:true" v-if="isoverflowed(activeriskaction) && !showmoreacts && editaction == false" class="text-center showmorebtn p-1"><i style="font-size: 16px;" class="fal fa-angle-down"></i> Show more</div>
			         	<div @click.stop.prevent="showmoreacts=showmoreacts?false:true " v-if="isoverflowed(activeriskaction) && showmoreacts && editaction == false" class="text-center showlessbtn p-1"><i style="font-size: 16px;" class="fal fa-angle-up"></i> Show less</div>
		         	</div>
		         	</div> 

         			</div>      

	         		</div>

         		</div>

         		<textarea placeholder="List steps to minimize risk impact." id="inputriskactioneditor" oninput='this.style.height = "";this.style.height = ( this.scrollHeight + 10 ) + "px"' onfocus='this.style.height = "";this.style.height = ( this.scrollHeight + 10 ) + "px"' style="min-height: 72px; resize: vertical;" v-if="editaction==true" name="riskaction" class="form-control card p-2 noshadow readonly titletext mb-0 autogrow" v-model="activeriskaction" ref="inputriskaction"></textarea>

         		<div class="text-right mt-1"  v-if="editaction==true">
	         		<button @click.stop.prevent="canceledit" class="btn btn-secondary mr-1" type="button">Cancel</button>
	         		<button @click.stop.prevent="onsaveaction(activerisk,'action', activeriskaction)" class="btn btn-primary" type="button">Save</button>
	         	</div>


         	</div>


         	<div  class="mb-2" :key="connectkey" v-if="1==2">

         		<div v-if="getissuelink()" class="border p-2">
         			<a :href="getissuelink()" target="_blank" >{{ getissuelink() }}</a>
         		</div>        		
         		
         		<addtojira  v-else :renderButton="'addto'" @onjirasignin="onjirasignin" @onissuecreated="issuecreated" :key="connectkey" :objectid="activerisk._id"></addtojira>
         	</div>  

         	<div class="mb-3">


         		<label class="h4label">Risk owner</label>  

         		<ownerPicker :isdisabled="editdescription==true || editaction==true" :singleuser="true" :activeriskobject="activerisk" :team="team" @onupdaterisk="updaterisk" :canwrite="canwrite" :boardusers="boardusers" :limittoboard="cockpit._id" ></ownerPicker>
 
         	</div>


         	</div>
         </div>

         </template>

         

       </div>

         


     		<p class="clearfix">&nbsp;</p>
     </div>



     <div id="floating" class="card" style="position:absolute; background: #ffffff;" v-if="1==2">
     	
     	<div class="card-body">	
     	<p>Test</p>

     	<table class="table table-striped">

     		<tr :key="sh._id" v-for="sh in sortedrisks">
     			<td>{{ sh.owner._id }} </td>
     			<td> {{ prod( sh ) }} </td>
     		</tr>
     		
     	</table>
     	</div>
     </div>





      </div>
</template>

<style type="text/css">

	#interactivepowerslider{
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.input-group-prepend .input-group-text{
		width: 120px;
	}

	.plain{
	background: none !important;
	border: none;
	width: 100px;
}

	body, div, html, #risksidebar, #inputriskdescriptioneditor, #inputriskactionviewer, #inputriskactioneditor{
	}

	#risksidebar{
		min-width: 360px;
		max-width: 360px;
		background: #fff;
	}

	#risksidebar.mobile{
		min-width: auto;
		max-width: auto;
	}

	#risksidebar.lg{
		height: 90vh;
		top: 72px;
		position: fixed;
		right: 0px;
		overflow-y: scroll;
	}

	.border-normal{
		border-color: var(--N200) !important;
	}

	.input-group-append, .btn-n200, .b-calendar-grid{
		border-color: var(--N200);;
	}

	.btn-outline-secondary{
		color: var(--B700);
	}

	/*tooltip.light .tooltip-inner, .tooltip.dark .tooltip-inner, .tooltip .tooltip-inner {
    font-size: 15px;
    font-weight: 400;
    height: 1px;
	}

	.tooltip .tooltip-inner {
    background-color: rgba(255, 255, 255, .7) !important;
    color: var(--N900);
	}

	.arrow {
    display: none !important;
	}*/

	.b-calendar-grid{
		display: block;
		border-color: var(--N200) !important;
	}

	.form-control{
		border-color: var(--N200);
	}

	.form-control-astext{

		padding: 0;
		border-bottom: 1px solid ;
		height: 10px;
		width: 100px;
	}

	.lv1{
		background-color: var(--G500); ;		
	}
	.lv2{
		background-color: var(--Y500);
	}
	.lv3{
		background-color: var(--R500);
	}

	.popoverrisks .popover-body{
		padding: 0 !important;
	}

	.shlabel.left{
		text-align: right;
		position:absolute; 
		width: 100px;
		left: -100px; 
		top: -5px; 
		overflow: hidden; 
		text-overflow: ellipsis; 
		white-space: nowrap;
		display: block;
		offset-anchor: right;
	}

	.shlabel{
		position:absolute; 
		width: 100px;
		left: 16px; 
		top: -5px; 
		text-align: center; 
		overflow: hidden;
		text-overflow: ellipsis; 
		white-space: nowrap; 
		text-align: left;
		color: var(--N100);
	}

	.ui-selected .shlabel{
		width: auto;
		font-weight: bolder;
	}


	.ui-draggable-dragging .shlabel{
		width: auto;
	}

	.ui-selected .shlabel.left, .ui-draggable-dragging .shlabel.left{
		width: auto;
		text-align: right;
		/*left: myleft;*/
	}

	.draggablerisk i{
		cursor: pointer;
	}


</style>
<style type="text/css" scoped>

		.trait{
			font-weight: bold;
		}

		.lwidth_xl{
			float: left;
		}
		.rwidth_xl{
			float: left;
			width: 390px;
		}

		.lwidth_lg{
			float: left;
		}
		.rwidth_lg{
			float: left;
			width: 390px;
		}

		.lwidth_md{
			width: 400px;
			/*margin: auto;*/
		}
		.rwidth_md{
			/*margin: auto;*/
			width: 390px;
		}

		.lwidth_sm{
			/*margin: auto;*/
		}
		.rwidth_sm{
			margin: auto;
			/*width: 336px;*/
		}

		.iconactive_xl{
			font-size: 40px;
		}

		.iconactive_lg{
			font-size: 40px;
		}

		.iconactive_md{
			font-size: 36px;
		}
		
		.iconactive_sm{
			font-size: 30px;
		}

		.icon_xl{
			font-size: 32px;
		}

		.icon_lg{
			font-size: 32px;
		}

		.icon_md{
			font-size: 28px;
		}
		
		.icon_sm{
			font-size: 22px;
		}

		.x_sm{
			height: 300px;
		}
		.x_sm td{
	/*		font-size: 10px;*/
		}
		.x_sm.axis{
			width: 25px;
		}
		.x_sm.axis td{
			padding-right: 0px;
			/*transform: rotate(-90deg);*/
		}
		.x_sm .trait{
/*			font-size: 12px;*/
		}

		.y_sm{
			width: 300px;
		}
		.y_sm td{
	/*		font-size: 10px;*/
		}
		.y_sm.axis{
			/*margin-left: 35px;*/
		}
		.y_sm.axis td{
			/*padding-right: 10px;*/
		}
		.y_sm .trait{
/*			font-size: 12px;*/
		}
		.y_sm td.action{
/*			font-size: 15px;*/
		}


		.x_md{
			height: 400px;
		}
		.x_md td{
/*			font-size: 12px;*/
		}
		.x_md.axis{
			width: 25px;
		}
		.x_md.axis td{
			/*padding-right: 10px;*/
		}
		.x_md .trait{
/*			font-size: 15px;*/
		}


		.y_md{
			width: 400px;
		}
		.y_md td{
/*			font-size: 12px;*/
		}
		.y_md.axis{
			/*margin-left: 40px;*/
		}
		.y_md.axis td{
			/*padding-right: 10px;*/
		}
		.y_md .trait{
/*			font-size: 15px;*/
		}
		.y_md td.action{
/*			font-size: 18px;*/
		}

		.x_lg{
			height: 500px;
		}
		.x_lg td{
/*			font-size: 15px;*/
		}
		.x_lg.axis{
			width: 25px;
		}
		.narrow_lg{
			margin-top: 15px;
			margin-left: 10px;
		}
		.narrow_md{
			margin-top: 15px;
			margin-left: 10px;
		}
		.narrow_sm{
			margin-top: 15px;
		}

		.x_lg.axis td{
			/*padding-right: 10px;*/
		}
		.x_lg .trait{
/*			font-size: 20px;*/
		}


		.y_lg{
			width: 500px;
		}
		.y_lg td{
			font-size: 15px;
		}
		.y_lg.axis{
		margin-left: 1px;
		}
		.y_lg.axis td{
			/*padding-right: 10px;*/
		}
		.y_lg .trait{
	/*		font-size: 20px;*/
		}
		.y_lg td.action{
	/*		font-size: 22px;*/
		}


		.x_xl{
			height: 600px;
		}
		.x_xl td{
/*			font-size: 15px;*/
		}
		.x_xl.axis{
			width: 25px;
		}
		.narrow_xl{
			margin-top: 15px;
			margin-left: 10px;
		}


		.x_xl.axis td{
			/*padding-right: 10px;*/
		}
		.x_xl .trait{
/*			font-size: 20px;*/
		}


		.y_xl{
			width: 600px;
		}
		.y_xl td{
			font-size: 15px;
		}
		.y_xl.axis{
		margin-left: 1px;
		}
		.y_xl.axis td{
			/*padding-right: 10px;*/
		}
		.y_xl .trait{
	/*		font-size: 20px;*/
		}
		.y_xl td.action{
	/*		font-size: 22px;*/
		}

		.x_xxl{
			height: 700px;
		}
		.x_xxl.axis{
			width: 25px;
		}

		.y_xxl{
			width: 700px;
		}

		.x_xxxl{
			height: 800px;
		}
		.x_xxxl.axis{
			width: 25px;
		}
		
		.y_xxxl{
			width: 800px;
		}


		.minwidth_sm{
			max-width: 350px;
		}

		.minwidth_md{
			min-width: 510px;
		}

		.minwidth_lg{
			min-width: 580px;
		}

		.minwidth_xl{
			min-width: 580px;
		}

		.action{
			font-size: 140%;
			text-align: center;
		}


		.green{ color: var(--G500) }
		.red{ color: var(--R500) }
		.yellow{ color: var(--Y500) }

		.white{ color: #ffffff; }

   .contact-selected{
   	border: solid 2px blue;
   }

	.risk{
		border: solid 1px #000;
		padding: 3px;
		background-color: #fff;
	}

	.dragcontent{
		position:absolute; 
		width: auto;
		left: 20px;
		min-width: 100px;
	}

	.vdr.active::before{
		outline: none;
	}

	.ui-selectable:hover{
		cursor: pointer;
	}


	.highlight{
		border: solid 1px #000;
		padding: 5p ;
	}

	.table th, .table td{
		padding: 0;
	}

	.vs__selected{
	  display: none !important;
	}


	/*.ui-draggable-dragging{
		transform: scale(1.7);
	}*/

	.ui-selected .marker{
		transform: scale(1.2);
	}

	.touch-start{
		transform: scale(1.5);
	}

	/*.ui-selected span{
		position: relative;
		width: auto;
	}*/
</style>
<script type="text/javascript">
	

</script>

<script>

	import Userapi from '@/services/api/users'
	import Teamsapi from '@/services/api/teams'

	import Emailsapi from '@/services/api/emails'
	import {bus} from '@/main'

	import ownerPicker from './ownerPicker'
	import memberlistfull from '@/components/members/memberlistfull'
	import datepicker from '@/components/advanced/datepicker'

	
	import connectWith from '@/views/tools/connectwith'
	import addtojira from '@/views/tools/addtojira'	
	
	//import autosize from 'autosize'

/*var Arc = function(x, y, radius, radians) {
  this.x = x;
  this.y = y;
  this.radius = radius;
  this.radians = radians;
  this.isDragging = false;

  this.render = function(ctx) {
    ctx.save();


    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, this.radians, false);
    ctx.fillStyle = '#2793ef';
    ctx.fill();

    ctx.restore();
  }
}*/

	export default{
		name: 'risks',
		data(){
			return{
				descriptionboxoverflown : false,
				reactsboxoverflown : false,
				showmoredesc:false,
				showmoreacts:false,
				preparejiraform: false,
				connectkey: 0,
				datepickerstate : 0,
				showclosedrisk : false,
				explainvalues : false,
				closingremarks : "",
				closingvalue : null,
				closingrisk : false,
				proximityrisk:"",
				dtepicker: { disableMobile:true, maxDate:'', minDate:null, altFormat: 'Y-m-d', altInput: true, dateFormat: 'Y-m-d', showMonths:1, monthSelectorType:'static'},
				selectedforexport : { 'label':"Projektgrundlag risikostyring (DK)", 'type':'doc', 'key':"dk1" } ,
				exporttemplates : [ { 'label':"Projektgrundlag risikostyring (DK)", 'type':'doc', 'key':"dk1" } , { 'label':"Risk management (EN)", 'type':'doc', 'key':"en1"  }, { 'label':'All risks', 'type':'xls', 'key':'csv'} ],
				riskname:"",
				riskdescription:"",
				width: 100,
				height: 0,
				top: 0,
				left: 0,
				isdragging: false,
				risks : [],
				closedrisks : [],
				activerisk : {},
				temprisk : {},
				focusrisk : {},
				activeriskconsequence : 0,
				activeriskprobability : 0,
				activeriskdescription : null,
				activeriskaction : null,
				activerisklabel : "",
				textcontact:"",
				textemail:"",
				windowsize:400,
				showtooltip:true,
				showpopover:false,
				nofade:true,
				sortedrisks:[],
				mode:"idle",
				confirmrisk:false,
				mouseovertimer:0,
				mouseovert : null,
				addnewrisk: false,
				addanother: false,
				//boardusers : [],
				editlabel: false,
				editdescription: false,
				editaction:false,
				exportloading: false,
				imgbody : null,
				touchevent : "",
				touchtimer : null,
				apidomain:"",
				withriskupdated:0
			}
		},

		props:{
		},

		watch:{

			'activerisk.label' : function( e, ev ){

				if( e == '' ){

					this.activerisk.label = ev
				}

			},

			addnewrisk : function( e ){
				this.activerisk = {}
				this.showpopover = false
			},

			risks : function( e ){

				if( e.length == 0 ){
					this.activerisk = {}
				}

				this.sortedrisks = e 
			},
			//activerisk : function( e ){

   				//this.updateboardusers()
			//},

			cockpit : function( e ){

				if( !e.risks ){

					//redir
					let currentroute = this.$router.history.current.path
					let newroute = currentroute.replace("riskmanagement", "board")
					location.href=newroute

					//this.$router.push( 'board' )
				}
			}
		},

		computed:{


			ismobile(){

				return this.$store.state.isMobile
			},


			boardusers(){


				let teamids = []

   				for( var i in this.cockpit.sharedtoteams ){
					teamids.push( this.cockpit.sharedtoteams[i].userid )
				}

				//alert('xx')
				

				let boardusers = []

		        if( this.team && this.cockpit.sharedtoteams && teamids.indexOf( this.team._id ) > -1){	       

					for( var i in this.team.sharedtousers ){

						//console.log( teamids, this.team.sharedtousers[i].userid , "lllllllllll")

						if( boardusers.indexOf( this.team.sharedtousers[i].userid ) == -1 ){
							boardusers.push( this.team.sharedtousers[i].userid )
						}
							
					}

		        }

				return boardusers

			},

			

			/*descheight(){
					return $('#inputriskdescriptionviewer').height()
			
			},/**/

			allowsave(){

				let notallowed = 0 
				if( this.riskname == '' ){
					notallowed += 1
				}

				if( notallowed == 0 ){
					return true
				}else{
					return false
				}
			},

			nearrisks(){

				

				let newrisks = []

				////////console.log( this.focusrisk )

				for( var i in this.sortedrisks){

					//console.log("aaa")

					let consequence = this.focusrisk.consequence - this.sortedrisks[i].consequence
					let probability = this.focusrisk.probability - this.sortedrisks[i].probability

					//console.log( consequence, probability)

					let proceed = 0

					let gap = 5

					if( consequence < gap && consequence > -(gap) ){
						proceed += 1
					}

					if( probability < gap && probability > -(gap) ){
						proceed += 1
					}



					let sh = this.sortedrisks[i]
					//{ consequence:this.sortedrisks[i].consequence, probability:this.sortedrisks[i].probability, label:this.sortedrisks[i].label}

					if( proceed == 2 && this.focusrisk._id != this.sortedrisks[i]._id){
						newrisks.push( sh )
					}

					if( this.focusrisk._id == this.sortedrisks[i]._id ){
						newrisks.push( sh )
					}


					//console.log("bbb")	
					//console.log("bbb")	
					
				}

				if( newrisks.length > 0 ){
					return newrisks
				}else{
					return []
				}			



			},

			canexport(){

				let toreturn = false

				if( this.risks.length > 0 ){
					toreturn = true
				}

				return toreturn

			},

			canwrite(){

				let toreturn = false

				if( this.cockpit && ( this.cockpit.myrole.toLowerCase()=='owner' || this.cockpit.myrole.toLowerCase()=='contributor')){
					toreturn = true
				}
				else if( this.cockpit && ( this.cockpit.myrole.toLowerCase()!='owner' && this.cockpit.myrole.toLowerCase()!='contributor')){
					toreturn =  false
				}

				if( this.isboardusers( this.loggedinuser._id ) ){
					toreturn = true
				}

				if( !this.cockpit.isactive ){
					toreturn = false
				}

				//toreturn  = false

				return toreturn
			},

			loggedinuser(){
			    return this.$store.getters.user
			},

			team(){
				return this.cockpit.risks
			},
		

			activerisksharedtousers(){

				var toreturn = this.activerisk.sharedtousers

				toreturn.push( { _id:"createnewcid", state:'idle', firstname:"", lastname:"", email:"", externalemail:"", "status":"risk" } )

				return toreturn
			},

			singlecol(){

				if( this.windowsize < 1087 ){
					return 'narrow'
				}else{
					return 'wide'
				}
				 
			},

			windowspec(){

				var ismobile = this.$store.state.isMobile

				var winsize = ""

				if(  ismobile || this.windowsize < 460 ){
					winsize = 'sm'
				}

				if( this.windowsize >= 461 && this.windowsize < 1185 ){
					winsize = 'md'
				}

				if( this.windowsize >= 1185){
					winsize = 'lg'
				}

				if( this.windowsize >= 1300){
					winsize = 'xl'
				}

				if( this.windowsize >= 1500){
					winsize = 'xxl'
				}

				if( this.windowsize >= 1700){
					winsize = 'xxxl'
				}



				return winsize

			},

			cockpitid(){
        return this.$route.params.cid
      },

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

      token(){
        return this.$store.getters.token
      },

      cockpit(){
				return this.$store.getters.GetCockpit
			},

		}, 

		created() {
		  window.addEventListener("resize", this.windowOnresize);
		},
		destroyed() {
		  window.removeEventListener("resize", this.windowOnresize);
		},

		mounted(){

			


			this.riskname = ""
			this.riskdescription = ""


		/*this.$root.$on('bv::tooltip::hide', bvEvent => {
			//this.showtooltip = true
      //falert('')
      //this.$root.$emit('bv::show::tooltip')
      bvEvent.preventDefault()
    })*/

			this.windowOnresize()

			let self = this

			setTimeout( function(){

				self.getallrisks()
			}, 50)
			



			

/*
			setTimeout( function(){


			var canvas = document.getElementById("mycanvas");
			var con = canvas.getContext("2d");

      con.fillStyle = "#000";
      con.fillRect(50,50, 400, 400);
      con.fillStyle = "#fff";
      con.fillRect(51,51, 398, 398);
      // draw font in red
      con.fillStyle = "#000";
      con.font = "16px sans-serif";
      con.fillText("High", 10, 90);
      con.fillText("High", 400, 480);
      con.fillText("Low", 10, 420);
      con.fillText("Low", 80, 480);	
	

      con.fillText("Keep satisfied", 120, 120);
      con.fillText("Monitor closely", 280, 120);
      con.fillText("Monitor", 120, 380);
      con.fillText("Keep informed", 280, 380);

      con.fillStyle = "#000";
      con.font = "24px sans-serif";

      con.fillText("probability", 220, 480);

      con.save()
      con.rotate(-Math.PI/2)
      con.fillText("consequence", -280, 30);
      con.restore()


      var circle = new Arc(200, 140, 6, Math.PI * 2);
			circle.render(con);

			},10)*/


				if( !this.cockpit.risks ){

					//redir

					//let currentroute = this.$router.history.current.path
					//let newroute = currentroute.replace("risks", "board")
					//location.href=newroute
					//this.$router.push( 'board' )
				}


			$( '#floating' ).draggable()


		},

		filters: {
  			nl2br ( text ){

				let toreturn = ""

				if( text ){
					toreturn = text.replaceAll("\n","<br>")
				}

				return toreturn
		    },
		},

		methods:{

			isoverflowed( strtext ){

				let text = strtext

				let words = 0
				let newlines = 0
				//let chars = 0

				if( text ){
					//chars = text.length
					words = text.split(" ").length 
					newlines = text.split("\n").length 
					words = words + ( newlines * 8)
				}

				
				return words > 45 ? true : false
			},


			isdescriptionboxoverflown(){

				let toreturn = ""				

				let el = document.getElementById('inputriskdescriptionviewer');

				if( el ){
					toreturn =  el.scrollHeight != el.clientHeight
				}				

				this.descriptionboxoverflown = toreturn

			},

			isreactsboxoverflown(){

				let toreturn = ""				

				let el = document.getElementById('inputriskactionviewer');

				if( el ){
					toreturn =  el.scrollHeight != el.clientHeight
				}				

				this.reactsboxoverflown = toreturn

			},

			nl2br ( text ){

				let toreturn = ""

				if( text ){
					toreturn = text.replaceAll("\n","<br>")
				}

				return toreturn
		    },

			onblursavedescription( activerisk,field,activeriskdescription ){

				let self = this

				setTimeout( function(){

					if( self.cancelevent == false ){
						//activerisk,'description', activeriskdescription
						self.updaterisk( activerisk,'description', activeriskdescription )
					}

				}, 200)	

			},
			onsavedescription( activerisk,field,activeriskdescription ){

				this.cancelevent = true
				this.updaterisk( activerisk,'description', activeriskdescription )

			},

			onblursaveaction( activerisk,field,activeriskaction ){

				let self = this

				setTimeout( function(){

					if( self.cancelevent == false ){
						self.updaterisk( activerisk,'action', activeriskaction )
					}

				}, 200)	

			},
			onsaveaction( activerisk,field,activeriskaction ){

				this.cancelevent = true
				this.updaterisk( activerisk,'action', activeriskaction )

			},


			onjirasignin(){

				this.connectkey = this.connectkey + 1

			},

			createonjirawithoptions(){

				//createnewissuewithform

				bus.$emit('createnewissuewithform')

				//this.preparejiraform = true
			},

			issuecreated(){

				let self = this


				Userapi.Risk( this.activerisk._id, this.token ).then( function(response){

					self.activerisk.connect = response.data?.risk?.connect
					//console.log( , "connect")
					self.connectkey = self.connectkey + 1
				})
			},


			getissuelink( ){


				let issuelink = ""
				let payload = {}

				if( this.activerisk?.connect?.jira?.payload ){
					payload =  JSON.parse(this.activerisk?.connect?.jira?.payload)

					if( payload.issueurl ){
						issuelink = payload.issueurl
					}

				}

				return issuelink

			},

			

			createonjira(){

				console.log("aa")

				let self = this
				bus.$emit('createnewissue', "", self.activerisk.label, self.activerisk.description, self.activerisk._id )

			},


			cardcreated( card ){

				alert('card')
			},

			createontrello(){

				let self = this

				Userapi.Risk( this.activerisk._id, this.token ).then( function(response){
					let cardid = response.data.risk.connect?.trello?.trellocardid

					bus.$emit('createnewcard', cardid, self.activerisk.label, self.activerisk.description, self.activerisk._id )
				})		

				

			},

			/*ondateblur( e , el){

				this.ondatepicked(el)
			},*/

			exportwindowclosed( e ){

				let self = this

				setTimeout( function(){
					self.$refs['initexportbutton'].blur()
				}, 800)
				//alert('xe')			
				
				//initexportbutton

			},

			

			

			openclosed(){

				let self = this

				this.updateclosedrisks( function(){

				self.showclosedrisk=true
	
				})
				

			},


			removeentry(){

				this.proximityrisk = ""
				this.updaterisk( self.activerisk, 'proximity', "")

			},


			oncloseclosedrisks(){

				let self = this

				setTimeout( function(){

					self.$refs['addnewriskbutton'].focus()
				}, 500)
			},

			isboardusers( userid ){

		        let toreturn = false

		        if( this.boardusers ){

		          if( this.boardusers.indexOf( userid ) > -1){
		            toreturn = true
		          }

		        }

		        return toreturn
		    },

			prod( sh ){

				let prob = sh.probability / 20  
				let cons = sh.consequence / 20

				return prob * cons

			},

			reopenrisk( closedrisk ){

				this.updaterisk( closedrisk, 'openstatus', "open" )

			},

			closewithselection(){


				//if( confirm('Close this risk?') ){


					this.updaterisk( this.activerisk, 'occured', this.closingvalue )
					this.updaterisk( this.activerisk, 'closingremarks', this.closingremarks )
					this.closingrisk = false

					let self = this

					setTimeout( function(){

							self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
								if( response.data.ok){
									//self.updateboardusers()

									self.getallrisks()

									self.closingremarks = ""
									self.closingvalue = null
								}
							})



					}, 100)


				//}

			},

			setforexport( template ){

				//this.selectedforexport = template
				this.doexport(template.key)
			},

			mywidth( iid ){

				//addnewriskbutton



				let el = this.$refs['flipshlabel_'+iid]

				if(  el && el[0] ){

				return el[0].scrollWidth + 'px'
					
				}

				
			},

			myleft( iid ){

				let el = this.$refs['flipshlabel_'+iid]

				if(  el &&  el[0] ){

				return '-' + el[0].scrollWidth + 'px'
					
				}

				
			},

			viewtouch(){

				this.showpopover = false
			},

			clickhandler( sh ){

				var ismobile = this.$store.state.isMobile
				if( ismobile )
				return false


				this.onselected( sh , true )

			},

			touchhandler( touch ){

				this.touchevent = touch.type
						

				let self = this

				let id = $(touch.target).parents('.touchable').get(0).id.replace('drag_','')			


				//this.showpopover = false
				

				if( touch.type == "touchstart" && touch.currentTarget ){

					//var ismobile = this.$store.state.isMobile
					/**/

					self.focusrisk = {}
					self.showpopover = false

					for( var i in self.risks ){

						//////console.log( self.risks, self.risks[i]._id, id )

						if( self.risks[i]._id == id ){

							let sh = self.risks[i]

							this.focusrisk = sh

							setTimeout( function(){
								self.showpopover = true
							}, 500)

							
 //
							//if( this.nearrisks.length > 1 ){												
							//	self.showpopover = true
								//return false
							//}else{

								self.onselected( self.risks[i] , true )
							//}
							
						}						
					}



					this.touchtimer = setTimeout( function(){						

						$(touch.target).parents('.touchable').addClass("touch-start")
						self.touchevent = "touchhold"
						self.showpopover = false

					}, 900)


					
				}

				if( touch.type == "touchend" && touch.currentTarget ){

					clearTimeout(this.touchtimer)

					$('.touch-start').removeClass("touch-start")

				}
				
				if( touch.type == "touchmove" && touch.currentTarget ){
					this.showpopover = false
				}
				
			},

			doinitexportcsv(){

				let self = this

				self.exportloading = false

				Userapi.Exportriskcsv( "", this.cockpitid, this.token ).then( function(response){					
					self.apidomain = response.data.apidomain
					location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
				})

			},

			doinitexport(){

				this.exportloading = true

			},

			doexport( template ){

				let self = this

				if( template == 'csv' ){

					this.doinitexportcsv()
				}else{

					Userapi.Exportrisk( template, this.cockpitid, this.token ).then( function(response){

						self.exportloading = false
						self.apidomain = response.data.apidomain
						location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
					})

				}
				

				
				


			},

			

			canceledit(){

				this.cancelevent = true

				this.activerisklabel = this.activerisk.label
				this.activeriskdescription = this.activerisk.description
				this.proximityrisk = this.activerisk.proximity
				this.activeriskaction = this.activerisk.mitigatingaction

				this.editlabel = false
				this.editdescription = false
				this.editaction = false

			},

			doeditaction(){

				if( !this.canwrite ){
					return false
				}

				this.cancelevent = false

				if( this.editdescription == true ){
					return false
				}

				let self = this

				let hh = $('#inputriskactionviewer').height()

				this.editdescription = false
				this.editlabel = false
				this.editaction = true

				this.activeriskaction = this.activerisk.mitigatingaction
				

				setTimeout( function(){

					//////////////////////autosize($('.autogrow'));

					$('#inputriskactioneditor').height(hh+10)
					//alert(hh)
					//self.$refs.inputriskdescription.offsetHeight = "500px"
					self.$refs.inputriskaction.focus()
					//self.activeriskaction = null

				},50)

			},

			doeditdescription(){

				if( !this.canwrite ){
					return false
				}

				this.cancelevent = false

				if( this.editaction == true ){
					return false
				}

				let self = this

				let hh = $('#inputriskdescriptionviewer').height()

				this.editdescription = true
				this.editlabel = false
				this.editaction = false

				this.activeriskdescription  = this.activerisk.description

				setTimeout( function(){
					/////////////////////autosize($('.autogrow'));
					$('#inputriskdescriptioneditor').height(hh+10)
					self.$refs.inputriskdescription.focus()
					//self.activeriskdescription = null
				},50)

			},


			doeditlabel(){

				if( this.editdescription == true || this.editaction == true ){
					return false
				}

				let self = this

				this.editlabel = true
				this.editdescription = false
				this.editaction = false

				setTimeout( function(){
					self.$refs.inputrisklabel.focus()


				},100)

				
			},

			
			closeotherbox( el ){


				this.activerisk = {}
			},

			isTruncated( iid ) {

				let el = this.$refs['shlabel_'+iid]

				if( el && el[0] ){
					return el[0].offsetWidth < el[0].scrollWidth
				}else{
					return false
				}
			   
			},

			saverisk( sh ){

				//console.log( sh,  'saverisk')

				var updatevalue = { "t":this.token, "action":"updaterisk", "field":'updaterisk', "label":sh.label, "description":sh.description, "proximity":sh.proximity, "objectid": sh._id }

				Userapi.UpdateRisk( updatevalue ).then( function(response){

				})
	
			},

			addnewriskformhidden(){

				this.withriskupdated = 0

				if( this.risks ){
					this.onselected( this.risks[  this.risks.length - 1 ], false )
				}
				
			},


			addnewriskformshown(){

				this.withriskupdated = 0

				this.riskname = ""
				this.riskdescription = ""

				this.$refs.newrisknameinput.focus()

				/////////////////////autosize($('.autogrow'));


				//alert('')
			},


			sortrisks(){

				let risks = this.sortedrisks

				let newrisks = []

				
				let current = {}

				for( var i in risks ){

					if( this.activerisk._id != risks[i]._id ){
						newrisks.push( risks[i] )
					}else{
						current = risks[i]
					}
					
				}

				newrisks.push( current )

				

				this.sortedrisks = newrisks
				//return newrisks
			},

			mood( mood ){

				/*if(!mood || mood == ''){
					mood = 'green'
				}
				return mood*/

				return 'white'

			},

			validateEmail(email) {

    			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			return re.test(String(email).toLowerCase());

			},

			removeuserfromteam( userid, isforced , callback ){


					let self = this

					Teamsapi.Removeuser( this.token, this.team._id, userid, true, isforced, "checkrisks" ).then( function( response ){

						if( response.data.ok ){
							//self.$emit('onuserisaddedtoriskteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey, "remove" ).then( function( response2 ){

								if( callback ){
									callback()
								}
							})
							
						}
						//Teamsapi.Updateuserstatus( option._id, self.orgkey , 'add').then( function( response2 ){						
						//		self.getteams(response.data.team._id)					
						//})

					})

			},

			addusertoteam( userid , callback){


					let self = this

					Teamsapi.Adduser( this.token, this.team._id, userid ).then( function( response ){

						if( response.data.ok ){
							//self.$emit('onuserisaddedtoriskteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey , 'add').then( function( response2 ){	

								if( callback ){
									callback()
								}					
							//		self.getteams(response.data.team._id)					
							})

						}


					})

			},


			windowOnresize(){

				let self = this

				this.windowsize = window.innerWidth

				setTimeout( function(){

					var box = document.getElementById("riskwrapper");

					let width = self.windowsize - 360 - 120

					box.style.width = width + 'px'

					/*if( self.windowsize > 1400 ){
						width = 1400 - 340 - 120
					}

					if( self.windowsize > (1400+140) ){
						width = 1200
					}

					if( self.windowsize > (1400+360) ){
						width = 1400
					}*/

					
					
					if( self.windowsize < 1006 ){
						width = 545
						box.style.width = 'auto'
					}

				}, 10)

			},

			trydeleterisk(){

				this.confirmrisk = true
			},



			confirmdeleterisk( sh ){

				let self = this


				var sharedtousers = []

				if( sh.sharedtousers ){
					for( var i in sh.sharedtousers ){
						//alert( sh.contacts[i].userid )
						sharedtousers.push( sh.sharedtousers[i]._id )
					}
				} 

				Userapi.Deleterisk( this.cockpitid, sh._id, this.token ).then( function(response){

					if( response.data.ok ){


						self.risks = [] 
						self.closedrisks = []

						for( let i in response.data.risks ){

							if( response.data.risks[i].status && response.data.risks[i].status == "closed" ){
								self.closedrisks.push( response.data.risks[i] )
							}else{
								self.risks.push( response.data.risks[i] )									
							}
						}

						
						//self.risks = response.data.risks


						self.activerisk = {}
						self.confirmrisk = false


						for( var i in sharedtousers ){
							self.removeuserfromteam( sharedtousers[i], false )
						}

						setTimeout( function(){

							if( self.risks[0] ){
								self.onselected( self.risks[0] , true, true)
							}

						}, 200)

						
					}

				})
			},

			dodatepicked( e ){

				this.updaterisk( this.activerisk, 'proximity', e)
				
			},

			ondateselect( e ){

				if( e == "" ){
					this.updaterisk( this.activerisk, 'proximity', "")
					this.datepickerstate = 1
					setTimeout( ()=>this.datepickerstate=0, 2500)
					return false
				}
				
				//alert(newdate)

				let self = this

				setTimeout( function(){

					let newdate = new Date(self.proximityrisk)
					if( (newdate instanceof Date && !isNaN(newdate.valueOf())) == true ){
						self.updaterisk( self.activerisk, 'proximity', self.proximityrisk)

						self.datepickerstate = 1
						setTimeout( ()=>self.datepickerstate=0, 2500)
					}else{
						self.datepickerstate = 2
					}

				}, 100)

			},

			updaterisk( sh, act, value, callback){

				///console.log( sh, act, value , "plplplpl")

				let self = this

				var updatevalue = { "t":this.token, "action":"update", "field":act, "objectid": sh._id }				
				
				if( act == 'openstatus' && value != null ){
					updatevalue.status = "open"
				}

				if( act == 'occured' && value != null ){
					updatevalue.occured = value
					updatevalue.status = "closed"
				}

				if( act == 'closingremarks' && value != null ){
					updatevalue.closingremarks = value
					updatevalue.status = "closed"
				}

				if( act == 'label' && value != '' ){
					updatevalue.label = value
					sh.label = value
				}

				if( act == 'description' ){
					updatevalue.description = value
					//this.tempactiveriskdescription = value
				}

				if( act == 'action' ){
					updatevalue.mitigatingaction = value
					//this.tempactiveriskaction = value
				}

				if( act == 'proximity' ){
					updatevalue.proximity = value
				}

				if( act == 'removeowner' ){


					updatevalue.removeowner = JSON.stringify( { "cid":"", "newid":value.newid  } )

				}
				if( act == 'addowner' ){


					if( value._id && value.firstname ){						

						let tosubmit = { "cid":value._id , "firstname":value.firstname , "lastname":value.lastname, "externalemail":value.externalemail, "email":"" } 

						updatevalue.addowner = JSON.stringify( tosubmit )
					}

					
				}

				if( act == 'owneruser' ){


					updatevalue.owneruser = JSON.stringify( value )

					/*Userapi.Updateprofile( updatevalue ).then( function(response){

					})

					return false*/

				}

				if( act == 'removefromboard' ){

					//updatevalue.removefromboard = JSON.stringify( value )

					this.removeuserfromteam( value.userid, false , function(){

						self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
							if( response.data.ok){
								//self.updateboardusers()
							}
						})

					})

					return false

				}


				if( act == 'addusertoboard' ){

					updatevalue.addusertoboard = JSON.stringify( value )			


				}

				if( act == 'inviteusertoboard' ){


					updatevalue.addusertoboard = JSON.stringify( value )


				}

				if( act == 'setowner' ){


					updatevalue.setowner = JSON.stringify( value )


				}

				

				Userapi.UpdateRisk( updatevalue ).then( function(response){					//if( act == 'contacts' ){

						self.cancelevent = false

						if( response.data.risk ){

							for( var i in self.sortedrisks ){
								if( self.sortedrisks[i]._id == response.data.risk._id ){
									self.sortedrisks[i] = response.data.risk
								}
							}
						}

						if( act == 'proximity' ){
							self.activerisk = response.data.risk
						}

						if( act == 'label' ){

							self.editlabel = false

							$( self.$refs.inputrisklabel ).addClass("form-control-success")

							setTimeout( function(){
								$( self.$refs.inputrisklabel ).removeClass("form-control-success")
							}, 1000)
						}

						if( act == 'description' ){

							console.log(  'description up', response.data.risk.description , "onpost")

							self.editdescription = false
							self.activeriskdescription = response.data.risk.description
							self.activerisk = response.data.risk

							$( self.$refs.inputriskdescription ).addClass("form-control-success")

							setTimeout( function(){
								$( self.$refs.inputriskdescription ).removeClass("form-control-success")
							}, 1000)
						}	


						if( act == 'action' ){

							self.editaction = false
							self.activeriskaction =  response.data.risk.mitigatingaction
							self.activerisk = response.data.risk

							$( self.$refs.inputriskaction ).addClass("form-control-success")

							setTimeout( function(){
								$( self.$refs.inputriskaction ).removeClass("form-control-success")
							}, 1000)
						}

						if( act == 'owneruser' ){

							self.activerisk.owner = response.data.risk.owner
						}	
					//}


						//if( response.data.risks ){
						//	self.newrisks( response.data )
						//}

						//addusertoteam

						if( act == 'replacecontact' ){
							self.addusertoteam( value.userid , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										//self.updateboardusers()
									}
								})

							})
							
						}

						if( act == 'inviteusertoboard' ){

							self.activerisk.owner = response.data.risk.owner


							self.addusertoteam( self.activerisk.owner._id , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										

										Emailsapi.Invitationemail( self.loggedinuser._id, self.activerisk.owner.email, self.orgkey)
							     		var str = "We have sent an invitation to the email address you indicated."
							      		bus.$emit('craeteaToast', {content:str,title:'Invite sent', variant:'info',append:true, sup:''})


									}
								})


							})


													
							

						}
						

						if( act == 'addusertoboard' ){


							self.activerisk.owner = response.data.risk.owner

							

							self.addusertoteam( self.activerisk.owner._id , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										//self.updateboardusers()

										//console.log( response.data , "addusertoboard")
									}
								})

							})

							

							/*if( response.data.risk.owner.status == "invited" ){


								Emailsapi.Invitationemail( self.loggedinuser._id, self.activerisk.owner.email, self.orgkey)
					     		var str = "We have sent an invitation to the email address you indicated."
					      		bus.$emit('craeteaToast', {content:str,title:'Invite sent', variant:'info',append:true, sup:''})

							}*/

							//self.activerisk.owner = response.data.risk.owner

							//self.updateboardusers()
						}

						if( act == 'addowner' ){

						}

						if( act == 'addowner' ){

							self.activerisk.owner = response.data.risk.owner
						}

						if( act == 'setowner' ){
							//console.log( response.data.risk.owner, "zzzzzzzp")
							self.activerisk.owner = response.data.risk.owner

							self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										//self.updateboardusers()
									}
								})
						}


						if( act == 'removeowner' ){

							self.activerisk.owner = response.data.risk.owner


							//console.log(  value.newid , "activerisk")

							self.removeuserfromteam( value.newid, false , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										//self.updateboardusers()
									}
								})

							})
						}

						if( act == 'openstatus' ){
							self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										self.getallrisks()

										if( self.closedrisks && self.closedrisks.length < 1 ){
											self.showclosedrisk = false
										}
									}
								})
						}

						if( callback ){
							callback( {"ok":"isnow"} )
						}

				})


				//console.log( sh, act, value )
			},

			unselect(){
				this.activerisk = {}
			},


			onmouseover( act, sh ){

				var ismobile = this.$store.state.isMobile

				if( ismobile )
				return false

				clearTimeout(this.mouseovert)

				let self = this


				if( act == 'out' ){

					this.mouseovert = setTimeout( function(){

						self.focusrisk = {}
						self.showpopover = false

					}, 100)
					
				}				

				if( this.showpopover == false ){						

						this.mouseovert = setTimeout( function(){
							self.focusrisk = sh
							self.showpopover = true
							clearTimeout(this.mouseovert)
						}, 20)

				}
				
			},
			onselected( sh , showinfo, isforced, shouldfocus ){	



				if( sh ){


				this.editlabel = false
				this.editdescription = false
				
				this.showpopover = false

				let self = this


				let obj = $('#drag_'+sh._id).get(0)


				$('.ui-selected').not(obj).removeClass('ui-selected').removeClass('bg-info')
				$(obj).addClass('ui-selected')


					// if( $(obj).hasClass('ui-selected') ){

						this.mode = "edit"	


						this.activerisk = sh	

						this.activeriskconsequence = this.activerisk.consequence
						this.activeriskprobability = this.activerisk.probability

						this.activerisklabel = ""
						this.activeriskdescription = ""
						this.proximityrisk = ""
						this.activeriskaction = ""
						this.activerisk.owner = ""			

						self.sortrisks()		

						if( showinfo ){

							Userapi.Risk( sh._id, this.token ).then( function(response){

								if( response.data.ok ){

									self.activerisk = response.data.risk ? response.data.risk : null

									self.activerisklabel = self.activerisk.label ? self.activerisk.label : null
									self.activeriskdescription = self.activerisk.description ? self.activerisk.description : null
									self.proximityrisk = self.activerisk.proximity ? self.activerisk.proximity : null
									self.activeriskaction = self.activerisk.mitigatingaction ? self.activerisk.mitigatingaction : null

									self.activerisk.owner = response.data.risk.owner ? response.data.risk.owner : null

									sh.owner = response.data.risk.owner ? response.data.risk.owner : null
													

								}

							})

							//console.log("vvvvvvvvvvvvv")
						}		

					//}else{

						//this.activerisk = {}

						//$( '#drag_'+sh._id ).css('z-index', 2500)
					//}

					setTimeout( function(){
						//self.$root.$emit('bv::show::tooltip')
					}, 100)


				}else{

					this.activerisk = {}
				}	


			},

			positionprobability( pos ){

				var div = 0


				switch(this.windowspec) {
				  case 'sm':
				    div = 3
				    break;
				  case 'md':
				    div = 4
				    break;
				  case 'lg':
				    div = 5
				    break;
				  case 'xl':
				    div = 6
				    break;
				  case 'xxl':
				    div = 7
				    break;
				   case 'xxxl':
				    div = 8
				    break;				    
				  default:
				    // code block
				}


				return ( pos+0 ) / div

			},

			positionconsequence( pos ){

				var div = 0


				switch(this.windowspec) {
				  case 'sm':
				    div = 3
				    break;
				  case 'md':
				    div = 4
				    break;
				  case 'lg':
				    div = 5
				    break;
				  case 'xl':
				    div = 6
				    break;
				  case 'xxl':
				    div = 7
				    break;
				   case 'xxxl':
				    div = 8
				    break;				    
				  default:
				    // code block
				}

				return ( ( div * 100 ) - pos ) / div
			},

			probabilityposition( value ){

				var div = 0


				switch(this.windowspec) {
				  case 'sm':
				    div = 3
				    break;
				  case 'md':
				    div = 4
				    break;
				  case 'lg':
				    div = 5
				    break;
				  case 'xl':
				    div = 6
				    break;
				  case 'xxl':
				    div = 7
				    break;
				   case 'xxxl':
				    div = 8
				    break;				    
				  default:
				    // code block
				}

				let offset = this.windowspec == 'sm' ? 6 : 10 

				return ( Math.round(value) * div ) - offset

				//return value + "%; margin-left: -7px;"

			},
			consequenceposition( value ){

				var div = 0


				switch(this.windowspec) {
				  case 'sm':
				    div = 3
				    break;
				  case 'md':
				    div = 4
				    break;
				  case 'lg':
				    div = 5
				    break;
				  case 'xl':
				    div = 6
				    break;
				  case 'xxl':
				    div = 7
				    break;
				   case 'xxxl':
				    div = 8
				    break;				    
				  default:
				    // code block
				}

				let offset = this.windowspec == 'sm' ? 4 : 10 


				return ( ( div * 100 ) - ( Math.round(value) * div ) ) + offset

				//return value + "%; margin-left: -7px;"

			},

			ondragging( ){

				//console.log( i.width, "xxxx" )

				//this.isdragging = true
				//i.width = 20
			},

			offdragging( i, ev ){

				/////////console.log( ev, i, "xxxx" )

				/*Userapi.risk( ] ).then( function(response){

						console.log( response, "xxxxxxxx")
					})*/


			},

			newrisks( data ){


				if( this.risks ){

					var newrisks = []
					for( var i in this.risks ){																

						if( this.risks[i]._id == data.risk._id ){
							newrisks.push( data.risk )
							this.activerisk = data.risk
						}else{

							newrisks.push( this.risks[i] )
						}
					}

					this.risks = newrisks


				}


			},

			bindDraggables(){

				if( this.canwrite ){

				let self = this

				let offset = this.windowspec == 'sm' ? 6 : 10 


							setTimeout( function(){

								if( self.sortedrisks ){

									/////*//////for( var i in self.sortedrisks ){
										//grid: [ 32, 32 ],

										//alert( self.risks[i]._id )

										//console.log( $( '#drag_' + self.sortedrisks[i]._id ) , "init")

										var ismobile = self.$store.state.isMobile

										$( '.draggablerisk' ).draggable( {  delay: ( ismobile ? 800 : 100 ), zIndex: 2500, revert: false, handle: "i", containment: "parent", drag: function( j,k ){

												const consequence = self.positionconsequence( k.position.top - offset )
												const probability = self.positionprobability( k.position.left + offset)

												if( self.activerisk ){

													self.activeriskconsequence = consequence
													self.activeriskprobability = probability

													if( self.activerisk._id == self.focusrisk._id ){
														self.focusrisk.consequence = consequence
														self.focusrisk.probability = probability
													}
													
												}

												//console.log( self.activerisk, consequence, probability )

											j.stopPropagation();

										}, start: function( j,k ){

											let currentsh = j.currentTarget.id.replace('drag_','')

											for( var i in self.sortedrisks ){
												if( currentsh ==  self.sortedrisks[i]._id ){
													self.activerisk = self.sortedrisks[i]
											//		self.onselected( self.sortedrisks[i], false )
												}
											}

											//self.onselected( self.sortedrisks[i] )

											//self.activerisk = self.sortedrisks[i]

											//console.log( j.currentTarget )

											//

											//alert('dragstart')

											

											$('.ui-selected').removeClass('ui-selected').removeClass('bg-info')

											$(j.currentTarget).addClass('ui-selected')

											/*if( self.risks[i] ){

												if( self.$refs['tooltip_'+self.risks[i]._id] ){

													//console.log(self.$refs['tooltip_'+self.risks[i]._id][0]  )
													self.$refs['tooltip_'+self.risks[i]._id][0].$emit('close')
												}

												//self.$refs['tooltip_'+self.risks[i]._id].$emit('close')
												//self.$root.$emit( 'bv::hide::tooltip', 'tooltip_'+self.risks[i]._id )
											}*/


											j.stopPropagation();
											

										}, stop: function( j,k ) {		

											const objectid =  j.target.id.replace('drag_', '')



												/*for( var i in self.sortedrisks ){
													if( objectid ==  self.sortedrisks[i]._id ){
														self.onselected( self.sortedrisks[i], true )
														self.focusrisk = self.sortedrisks[i]
													}
												}*/

												/*for( var i in self.risks ){
													if( objectid ==  self.risks[i]._id ){
														self.risks[i] = self.focusrisk
													}
												}		*/

												

												
												
												const consequence = self.positionconsequence( k.position.top - offset )
												const probability = self.positionprobability( k.position.left + offset)

												//alert( probability )
												//alert( consequence )

												//self.activeriskprobability = probability
												//self.activeriskconsequence = consequence

												Userapi.UpdateRisk( { "t":self.token, "action":"update", "field":"values", "objectid": objectid, "consequence":consequence, "probability":probability } ).then( function(response){

															if( response.data.ok ){

																//alert( response.data.risk.probability )
																//alert( response.data.risk.consequence )

																/*self.activeriskprobability = response.data.risk.probability
																self.activeriskconsequence = response.data.risk.consequence

*/

																//self.newrisks( response.data )


																//sh = response.data.risk
															}


															//self.$root.$emit('bv::show::tooltip', 'tooltip_'+self.risks[i]._id)

												})


												j.stopPropagation();



							      } } );

									//////*//}
								}							


								//self.$root.$emit('bv::show::tooltip')  

							}, 100 )

				}

			},

			updateclosedrisks( callback ){

				let self = this

				Userapi.Risks( this.cockpitid, this.token, "open" ).then( function(response){

					if( response.data.risks ){

						self.closedrisks = []


						for( let i in response.data.risks ){

							if( response.data.risks[i].status && response.data.risks[i].status == "closed" ){
								self.closedrisks.push( response.data.risks[i] )
							}

						}

						if( callback ){

							callback()
						}

					}
				})
			},

			getallrisks( ){

				//console.log( {}, "selecting")


				let self = this

				//alert('')
				Userapi.Risks( this.cockpitid, this.token, "open" ).then( function(response){


						

						//this.getallrisks

						if( response.data.risks ){						

							self.risks = [] 
							self.closedrisks = []

							for( let i in response.data.risks ){

								if( response.data.risks[i].status && response.data.risks[i].status == "closed" ){
									self.closedrisks.push( response.data.risks[i] )
								}else{
									self.risks.push( response.data.risks[i] )									
								}
							}

							self.bindDraggables()

							setTimeout( function(){
								//self.$root.$emit('bv::show::tooltip')

								if( self.risks[0] ){
									//self.activerisk = self.risks[0]
									self.onselected( self.risks[0] , true, true)
								}

							}, 300)
							

						}
				
					})




			},

			createnewrisk( addanother ){

				let self = this

				if( this.riskname != '' ){

					let newrisk = { "label":this.riskname, "description":this.riskdescription, "consequence":50.0, "probability":50.0 }

					//this.risks.push( newrisk )

					this.riskname = ""
					this.riskdescription = ""

					//Newrisk

					Userapi.NewRisk( { "t":this.token, "action":"new", "boardid":this.cockpitid, "label": newrisk.label, "description": newrisk.description, "consequence":newrisk.consequence, "probability":newrisk.probability } ).then( function(response){

						//self.risks = response.data.risks
						self.getallrisks()

						self.bindDraggables()

						if( addanother == false ){
							self.addnewrisk = false
						}else{

							self.withriskupdated += 1

							setTimeout( function(){

								self.$refs.newrisknameinput.focus()
								
							}, 300)
						}

						for( var i in self.risks ){

						}

						setTimeout( function(){

							if( self.risks[ self.risks.length - 1 ] ){
								self.onselected( self.risks[ self.risks.length - 1 ] , true, true, false)
							}
								
						}, 1000)


						/*if( self.cockpit.connect && self.cockpit.connect.trello ){
							bus.$emit('createnewcard', newrisk.label,newrisk.description )
						}*/
						
						
						
						
						//console.log( response, "xxxxxxxx")
					})

				}

				//$('.tinydraggable').tinyDraggable();


			}
		},

		components:{ ownerPicker, datepicker, connectWith, addtojira}

	}
</script>