<template>
  <div> 

    <b-modal v-model="invalidlinkdetected" no-close-on-backdrop no-close-on-esc centered size = "l-x" >
     <template v-slot:modal-header>
      <h3 class="modal-title">Unable to create link</h3>
    </template>
    <div class="bootbox-body">Unable to create this link as it would cause an infinite loop.</div>
    <template v-slot:modal-footer="{ ok }">
      <b-button class="btn-primary" @click="invalidlinkdetected=false">Ok</b-button>                 
    </template>
  </b-modal>


    <b-modal v-model="closewhilediting" no-close-on-backdrop no-close-on-esc centered size = "l-x" >
     <template v-slot:modal-header>
      <h3 class="modal-title">Close without saving?</h3>
    </template>
    <div class="bootbox-body">Pending changes is not yet saved, press ok to close window.</div>
    <template v-slot:modal-footer="{ ok,cancel }">
      <b-button @click="closewhilediting = false">Cancel</b-button>      
      <b-button class="btn-primary" @click="closewhileditingfunction">ok</b-button>                 
    </template>
  </b-modal>

  <b-modal v-model="isshown" modal-class="modal-cockpit" size="md" title="Objective settings" @hidden="modalhidden" @shown="modalshown"  no-fade no-close-on-backdrop no-close-on-esc  hide-footer @hide="hideModal" id="objectivesettings">
    <template v-slot:modal-header>
      <h3 class="modal-title">Objective settings</h3>
      <a class = "close-modal dialog-close-button">
      <i class="fal fa-times fa-lg " @click="isshown = false"></i>
    </a>
    </template>
    <div>
      <div :class="{'hide':pagemode=='ready'}" > 
        <i class="fa fa-circle-o-notch fa-spin"></i>
      </div> 
      <div v-if = "pagemode== 'ready'" >
        <div class="d-flex flex-wrap align-items-center mb-2 mt-2">
          <div class="regular-text">
            <h3 class="overflow-string2 font-weight-bold mb-1">{{objective.name}} </h3>
          </div>
        
          <div class="ml-auto regular-text">
          <button v-if="objective.measuretype!='risk' && objective.measuretype!='masterplan'  && objective.measuretype!='stakeholder'" class="btn btn-warning-secondary" href="#" v-on:click="isshown = false; $emit('onmakeactive', 'no', 'objective', objective._id )">Make objective inactive</button> 
        </div>

          </div>

          <div class ="diverder" ></div>


        <div class="statusicons  form-group mt-3">
          
          <h4 class = "font-weight-bold" v-if="objective.measuretype == 'risk'">Risk icon explanations</h4>
          <h4 class = "font-weight-bold" v-else>Select status icons</h4>

          <div>

            <template >
              <div  v-for="statusoption in statusoptions" :key="statusoption._id">
              <template v-if="!(statusoption.indexvalue == 0 && isaggregator) ">
              

              <div class="d-flex align-items-center form-row form-group statusopts mb-2">

                <div class="col-1">
                  <span>
                    <input type="checkbox" class="statusoptiondefaults" ref="statuscheckbox" v-model="statusoption.isdefault" :value="statusoption._id" @change="statusoptiondefaultschanged( $event, statusoption)">
                    <label id="checkboxlabel" for="checkbox" tabindex=3></label>
                  </span>
                </div>

              <div class="col-1">
                <span><i class="statusoptionicon" :class="statusoption.icon + ' ' + statusoption.color"></i></span>
              </div>
              
              <base-input contanerclass = "col" isNotFrom hasConfirmImg type="text" :text="statusoption.label"   @input="value => { statusoption.label = value }" @change="statusoptiondefaultschanged( $event, statusoption)">
              </base-input>
              
            </div>

            <div class="d-flex align-items-center form-row statusdesc" v-if="objective.measuretype!='masterplan' && 1==2">
              <div class="col-1"><span class="spacer">&nbsp;</span></div>
              <div class="col" v-if="statusoption.showdesc == true">{{ statusoption.description }}</div>
            </div>

            </template>
            <!-- <p class="pl-3 pr-5" v-if="statusoption.showdesc">{{ statusoption.description }}</p> -->
            </div>
            </template>

            <template v-if="1==2">
            <div  v-for="statusoption in statusoptions" :key="statusoption._id">
            <template v-if="( !(statusoption.indexvalue == 0 && isaggregator) ) && ( statusoption.color != 'blue' ) ">
             

            <div class="d-flex align-items-center form-row form-group statusopts">

              <div class="col-1">
                <!-- <span><input ref="statuscheckbox" class="statusoptiondefaults" type="checkbox" v-model="statusoption.isdefault" :value="statusoption._id" @change="statusoptiondefaultschanged()">
                </span> -->
              </div>
              <div class="col-1"><span><i class="statusoptionicon" :class="statusoption.icon + ' ' + statusoption.color"></i></span></div>
              

               <base-input contanerclass = "col" isNotFrom hasConfirmImg type="text" :text="statusoption.label"   @input="value => { statusoption.label = value }" @change="statusoptiondefaultschanged( $event, statusoption)">
              </base-input>
              
            </div>
            <div class="d-flex align-items-center form-row statusdesc">
              <div class="col-1"><span class="spacer">&nbsp;</span></div>
              <div class="col" v-if="statusoption.showdesc == true">{{ statusoption.description }}</div>
            </div>
            </template>
            <!-- <p class="pl-3 pr-5" v-if="statusoption.showdesc">{{ statusoption.description }}</p> -->
            </div>
            </template>

          </div>


        </div>

      
        <div>
          

        </div>
        
        <div>

        <div v-if="objective.measuretype!='risk' && objective.measuretype!='masterplan' && objective.measuretype!='stakeholder'" class ="diverder"></div>
        <h4 v-if="objective.measuretype!='risk' && objective.measuretype!='masterplan' && objective.measuretype!='stakeholder'" class = "font-weight-bold">Linked objectives</h4>    
 
        <template  v-if="objective.measuretype!='risk' && objective.measuretype!='masterplan' && objective.measuretype!='stakeholder' ">


          <div class="noshadow drag-tables mt-2">            
            
          <ul class="list-group sectionitem" id="list-objlist" :key = "updatetable">


            <li class="list-group-item" v-for="measure in objective.measures" :id="'listitem_'+measure._id" :key="measure._id" >

               <div class="row section-item p-0" style =" flex-wrap: nowrap;" :class = "{'draghandle':draghandle}">  
                <div class="list-content d-flex align-items-center overflow-string" style ="min-width:0px" v-bind:class=" { 'text-muted': measure.mode=='deleting' } " > 
                  <base-icon icon = "fas fa-grip-lines fa-lg" :class = "{'draghandle':!draghandle}"/> 
                  <span class = "overflow-string mr-2 pr-2" style ="min-width:0px" v-text="measure.name"></span>
                </div>

                <div class="list-content pt-0 pl-2 ml-auto d-flex align-items-center">
                  <div class="actions " v-bind:id="'objactions_'+measure._id" >

                    <a href="#" v-if="measure.mode=='idle'" v-on:click.prevent.stop="tryremoveobjective(measure, 'tryremove')" class="actiondelete my-auto">Remove</a>

                     <div class ="my-auto ">
                    <b-button v-if="measure.mode=='deleting'" id="deletingbtn" :size ="btnsize" variant="danger"  @click = " tryremoveobjective(measure, 'confirm', $event)">Confirm?</b-button>
                    </div>

                    

                  </div>
                </div>
              </div> 

            </li>
          </ul>

        </div>
     

      <div class="text-labels">
        <div class="form-group link-objectives"> 
          <vueselect id="search-objusers-objectives" @search:focus="ajustlengthdown" @search:blur="ajustlengthup" :options="availableobjectives"  placeholder="Click to search and add..." label="name" @input="objectiveSelect" :resetOnOptionsChange="true" :clearSearchOnSelect="true" :closeOnSelect="true" @search="availableobjectivesupdate" :filterable="false">
            
            <div class="my-auto noresult" slot="no-options">
              <span>Sorry, no matching options.</span> 
            </div>

          </vueselect>
        </div>
      </div> 

    </template>

    </div>

    <div class="form-group mt-3" v-if="(objective.measures && objective.measures.length > 0) || objective.measuretype=='risk'">
    <div class ="diverder" v-if="objective.measuretype!='risk'"></div>
    
    <template v-if="objective.measuretype!='risk'">
    <h4 class = "font-weight-bold" v-if="objective.measuretype=='risk'">Risk aggregation method</h4>
    <h4 class = "font-weight-bold" v-else>Status calculation method</h4>
    

    <p v-if="objective.measuretype=='risk'"><a class="actionprimary" href="#" target="_blank">Learn how Flowe aggregates risks <i class="fal fa-external-link"></i></a></p>

          <p v-if="objective.measures && objective.measures.length > 0"><a @mouseover="aggregateslinkhover=true" @mouseout="aggregateslinkhover=false" class="actionprimary" href="https://goflowe.atlassian.net/wiki/x/E4BZRw" target="_blank">Learn how Flowe aggregates status <span class="external-link"  ><i class="fal fa-external-link" :class="{'fas' : aggregateslinkhover}"></i></span></a></p>
          <div class="">
          
             <b-table class ="aggregatorseletor" :items ="items" :fields="fields"> 
               <template v-slot:cell(selected)="row">
                     <span class="iconwrapper"><input name="objective_calc" type="radio" :id="row.item.id" :value="row.item.value" v-model="objective.aggregationmethod" @change="objectiveupdate('aggregationmethod', row.item.value)"/>
                      <label :for="row.item.id" class="font-weight-bold inputradio mb-0">{{row.item.name}}</label></span>
                </template>
                 <template v-slot:cell(details)="row">
                      <a @click="row.toggleDetails" class ="float-right">
                         <div class ="icon-holder" >
                            <span>
                              <i v-if="row.detailsShowing" class="far fa-angle-down"></i>

                              <i v-else class="far fa-angle-right"></i>
                            </span>
                          </div>
                      </a>
                </template>
                 <template v-slot:row-details="row">
                    <div class ="smb-2">
                      <span v-if="objective.measuretype=='risk'">
                        {{ row.item.html2 }}
                      </span>
                      <span v-else>
                        {{ row.item.html }}
                      </span>

                    </div>
                  </template>
             </b-table>


          </div>

    </template>
        </div>
            
      </div>
    </div>
  </b-modal>
</div> 
</template>

<style lang= "scss" scoped>

  .inputradio{
    white-space: nowrap;
  }

  .list-group-item{
    padding-left: 10px;
  }
  
  .external-link{
    width: 24px;
    height: 24px; 
    display: inline-block; 
    text-align: center; 
    color: var( --B500 );
  }

  ul.list-group li div.row {
     padding-top: .25rem;
     padding-bottom: .25em;
  }

</style>


<script type="text/javascript"></script>

<script>

  import Sortable from 'sortablejs';

  import Cockpitsapi from '@/services/api/cockpits'
  import objectivesTable from './objectivesTable'
  import usersTable from './usersTable'

  import vueselect from '@/views/tools/vueselect'

  import {bus} from '@/main'


  export default {
    name:'objectivesettingsmodal',
    props:{
      objectiveid:String,
      shown:Boolean,
      checkforinvalidlink:Boolean
    },
    data(){
      return{
        excludedtypes : [ 'risk', 'masterplan', 'stakeholder' ],
        isshown : false,
        editorstate: {name: {state: 'idle', content:'', error:{}}, description: {state: 'idle',content:'' ,error:{}}},
        errors:[],
        closewhilediting: false,
        invalidlinkdetected : false,
        statusoptions : [],
        aggregateslinkhover: false,
      members:[],
      pagemode:"notready",
      objective:{},
      availableobjectives:[],
      linkInitControls : null,
      updatetable:0,
      fields: [
        {
          key:'selected', 
          thStyle: {
            display: 'none'
          },
          class: "w-75"
        }, 
        {
          key:'details', 
          thStyle: {
            display: 'none'
          },
          class: "w-25"
        }],
      
      
    }
  },
  computed:{


    items(){


    let items = []

    items.push( {
          hideon: 'none',
          isActive: true,
          name:'Best',
          id:'aggregator_best',
          value:'best',
          html:'The status of this objective is determined by taking the highest status of its linked objectives e.g. Red, Red, Red, Green = Green',
          html2:'The indicator is based on the risk with the lowest risk value'
        } )
    
    if( this.objective && this.objective.measuretype != "risk" ){

    items.push( {
          hideon: 'risk',
          isActive: true,
          name:'Average/High',
          id:'aggregator_optimisticaverage',
          value:'optimisticaverage',
          html:'The status of this objective is based on the average status of the linked objectives. If the average results in a tie, then the next higher status is chosen e.g. Red, Red, Green, Green = Green',
          html2:'Indicator is based on the average risk value of all the open risks'
        } )  


    items.push( {
          hideon: 'risk',
          isActive: true,
          name:'Average/Low',
          id:'aggregator_average',
          value:'average',
          html:'The status of this objective is based on the average status of the linked objectives. If the average results in a tie, then the next lower status is chosen e.g. Red, Red, Green, Green = Red',
          html2:'Indicator is based on the average risk value of all the open risks'
        } )


    }
        
    
    if( this.objective && this.objective.measuretype == "risk" ){


        items.push( {
          hideon: 'all',
          isActive: true,
          name:'Average',
          id:'aggregator_optimisticaverage',
          value:'optimisticaverage',
          html:'The status of this objective is based on the average status of the linked objectives. If the average results in a tie, then the next higher status is chosen e.g. Red, Red, Green, Green = Green',
          html2:'Indicator is based on the average risk value of all the open risks'
        } )


    }      
    items.push( {
          hideon: 'none',
          isActive: true,
          name:'Worst',
          id:'aggregator_worst',
          value:'worst',
          html:'The status of this objective is determined by taking the lowest status of its linked objectives e.g. Red, Green, Green, Green = Red',
          html2:'The indicator is based on the risk with the highest risk value'
         
        } )

         
     
    


      return items
    },
    orgkey(){
          return this.$route.params.orgname ? this.$route.params.orgname : null
        },  

      token(){
        return this.$store.getters.token
      },

          cockpit(){
        return this.$store.getters.GetCockpit
      },
    isaggregator(){ return this.objective.measuretype =='aggregator'},
    userinfo(){
        return this.$store.getters.user
      },
    draghandle(){
        return this.$store.state.hasTouch
      },
      btnsize(){
        return this.$store.state.screenType == 'mobile' ? '' : 'sm'
      }
  },
  watch:{

    /*objective: function( e ){

      
        let newitems = []
        for( let i in this.items ){

          let item = this.items[i]

          if( e._id && e.measuretype == 'risk'){

            /*if( item.hideon != 'risk' ){
            newitems.push( item )
            }    

            }

            if( e._id && e.measuretype != 'risk'){

            if( item.hideon != 'all' ){
            newitems.push( item )
            }   

            newitems.push( item )
          }

          


        }

        this.items = newitems
      

    },*/

    shown : function( e ){
      this.isshown = e
    },
    updatetable: function(e){
    //  console.log(e)
    },
    /*objective:function( e ){

      if( this.objective.measuretype == "risk" )  {
        $.each( this.$refs.statuscheckbox, function( j,k ){         

          //if( k.checked ){

            $(k).addClass('disabled').prop("disabled","disabled")
            //$            k.disabled = "c"
          //}
         })        
      }

      //}

    }*/
  },
  
  components:{objectivesTable, usersTable, vueselect },

  mounted(){

    let self = this
    this.objective = {}


    /*let newitems = []
    for( let i in this.items ){



    }*/

  },

  methods:{

    statusoptiondefaultschanged( ev, indicator){
      let self = this

      if(indicator.label != ''){
      var newstatusoptions = []

      var selectedcntr = 0

      $.each( this.statusoptions, function( i,o ){

          if( o.isdefault == true ){
            selectedcntr++
          }
          

          var obj = { "refid":o._id }

          var option = { 
            ...obj,
            ...o
          }

          delete option._id
          //delete option.isdefault
          delete option.description
          delete option.sortorder

          newstatusoptions.push( option )       


      })

      
      //alert( objective.measuretype )
      if( selectedcntr == 1 ){

         $.each( this.$refs.statuscheckbox, function( j,k ){         

          if( k.checked ){

            $(k).addClass('disabled').prop("disabled","disabled")
            //$            k.disabled = "c"
          }
         })
        
      }else{

        $.each( this.$refs.statuscheckbox, function( j,k ){
          if( k.checked ){
            k.disabled = false
            $(k).removeClass('disabled').removeProp("disabled")
          }
         })

      }


      setTimeout( function(){
        
        self.objectiveupdate( 'statusoptions', newstatusoptions, function(){
              })

      },800)
      }
    },

    bindsortables : function(){


      let self = this

      new Sortable( $('#list-objlist').get(0)   , {
          group: 'shared',
          animation: 150,
          handle: ".draghandle",
          delay:function(){return draghandle ? 0:500},
          chosenClass:"shadow",
          ghostClass:"ghostClass",


          onUpdate:function( event ){ 

            self.updatesort()
          },
      });

    },

    updatesort(){

      var newmeasureids = []

      $('.list-group-item' , '#list-objlist').each( function(i,o){

        newmeasureids.push( o.id.replace('listitem_','') )

      })

      this.objectiveupdate( 'measures', newmeasureids, function(){
           // sorting done
      })

     

    },


    onobjectiveupdated( field, measureids ){

      let self = this

      this.objectiveupdate( field, measureids, function(){

          //self.$emit('onobjectiveupdated')

          bus.$emit('onafterupdated', self.objective, self.token, self.userinfo )

        })

      
    },

    objectiveupdate( field, value, callback ){

      var savedvalue = value

      if( field == "measures"){
        savedvalue = JSON.stringify(value)
      }

      if( field == "users"){
        savedvalue = JSON.stringify(value)
      }

      if( field == "statusoptions"){
        savedvalue = JSON.stringify(value)
      }
      

      let self = this

      //var loggedinuser = this.userinfo

      Cockpitsapi.Objectivesave( { "field":field, "value":savedvalue, "t":this.token, "mid":this.objective._id } )
      .then( function(response){

        if( response.data.ok ){

          self.$emit('onobjectiveupdated')

          if( callback ){
            callback()
          }
        }


      })
      .catch( function(){
            //self.cockpitsettingsmodalshown = false
          })
      .finally( () => {
        this.editorstate.description.content = this.objective.description
        this.editorstate.name.content = this.objective.name
      })
      

    },

    objectivesettings( callback ){

      let self = this

      self.numowners = 0
      
      Cockpitsapi.Objectiveedit( this.objectiveid, this.token, this.orgkey )
      .then( function(response){

        if( response.data ){

          self.objective = response.data.measure
          self.editorstate.name.content = self.objective.name
          self.editorstate.description.content = self.objective.description
          

          self.statusoptions = response.data.statusoptions

          var selectedcntr = 0

          var statusoptions = []



          $.each( self.statusoptions, function( i,o ){

            
            var obj = { "showdesc":false }

            var option = { 
              ...obj,
              ...o
            }

            statusoptions.push(option)

          })

          self.statusoptions = []

          $.each( statusoptions, function( i,o ){

            o.isdefault = false

            $.each( self.objective.statusoptions, function( j,k ){

              if( k.refid == o._id ){

                o.label = k.label
                o.isdefault = k.isdefault
                selectedcntr++
              }

              //else{
              //  o.isdefault = false
              //}
              //console.log(  )
            })            

            self.statusoptions.push(o)
          })

          if( selectedcntr == 1 ){

         //   console.log( self.$refs.statuscheckbox ) 

             $.each( self.$refs.statuscheckbox, function( n,m ){
              if( m.checked ){
                m.disabled = true
              }
             })
            
          }

          /*if( self.objective.measuretype == "risk" ){

            alert('')

       
             $.each( self.$refs.statuscheckbox, function( n,m ){
              //if( m.checked ){
                m.addClass = "disabled"
              //}
             })

          }*/

          self.pagemode = "ready"    

          setTimeout( function(){
            self.bindsortables()
          }, 200)     

        }

      })
      .catch( function(){
            //self.cockpitsettingsmodalshown = false
          })
      .finally( () => {
        /*sself.objective.measures.sort(function(a,b){
              if(a.name > b.name){
                console.log('sort',a.name,'>',b.name)
                return 1
              } else if(a.name < b.name){
                console.log('sort',a.name,'<',b.name)
                return -1
              }else{
                console.log('sort',a.name+'='+b.name)
                return 0
              }
            })*/
        if( callback ){
          self.$nextTick(function(){
            callback()
          })
            
          }
      })

      
      
    },

    hideModal(bvModalEvt){

      if(this.editorstate.name.state == 'editing'){
        bvModalEvt.preventDefault()
        this.closewhilediting = true

      }else if (this.editorstate.description.state == 'editing'){
        bvModalEvt.preventDefault()
        this.closewhilediting = true


      }
    },

    modalshown(){

      let self = this        

      // this.linkInitControls.initialize()

      this.objectivesettings( function(){
        //console.log('emit')           

       // console.log( self.objective.measureids , "adasasdsa")
       // bus.$emit('objectivetableloadobjectives', self.objective, self.token, self.loggedinuser )
        self.loadobjectives()
        // bus.$emit('userstableloadusers', self.objective, self.token, self.loggedinuser )

      })
    },

    modalhidden(){

      bus.$off('objectivetableloadobjectives' )
      bus.$off('userstableloadusers' )
      bus.$off('onafterupdated' )

      this.$func.removeQuery(['page'],this.$route,this.$router)
      this.pagemode = "notready"
      this.objective = {}
      this.$emit('onmodalhidden')
      this.isshown = false
    },

    closewhileditingfunction(){
      
      this.editorstate = {name:{content:'',state: 'idle', error:{}}, description: {content:'', state: 'idle', error:{}}};
      this.closewhilediting = false;
      this.isshown = false;
    },

    savechanges(){

      this.cockpitupdate( 'name', this.cockpit.name);
      this.savedescription();
      this.editorstate.name.state = 'idle' 
      this.editorstate.description.state = 'idle'
      this.closewhilediting = false;
      this.isshown = false;

    },

    ///////

    tryremoveobjective( measure, action, event ){
        // let self = this

        if(this.activemeasure){
          this.activemeasure.mode='idle'
        } 
        
        if( action=='tryremove'){
          this.activemeasure = measure
          measure.mode = "deleting"
           $('html').on('click',function() {
                measure.mode = "idle"
              });
        }

        if( action=='cancel'){
          this.activemeasure = measure
          measure.mode = "idle"
          
        }

        if( action=='confirm'){

          var newmeasures = []
          var newmeasureids = []

          var removed = null

          var ids = []

          ids = this.objective.measures

          for( var i in ids ){
           
            if( ids[i]._id != measure._id ){
              newmeasureids.push( ids[i]._id )
              newmeasures.push( ids[i] )
            }else{
              removed = ids[i] 
            }

          }

         // console.log( this.availableobjectives, removed ), removed )

          this.availableobjectives.push( removed )
          this.objective.measureids = newmeasureids
          this.objective.measures = newmeasures


          this.objectiveupdate( 'measures', newmeasureids, function(){


            self.activemeasure.mode='idle'

                //self.objective.measureids = measureids

                //self.loadobjectives()
                // update available options
               })

          //console.log( newmeasureids, "newmeasureids")

          ///////this.$emit('onupdated', "measures", newmeasureids )
           $('html').off('click',function() {
                measure.mode = "idle"
              });
          event.stopPropagation();

        }
        
      },

    loadobjectives( callback ){

        let self = this        

        var mids = this.objective.measureids

        mids.push( this.objective._id )

        Cockpitsapi.Getobjectives( "", mids, this.token, this.orgkey )
        .then( function(response){
          if( response.data ){
            self.availableobjectives = response.data.measures


            if( callback ){
              //callback()
              //nexttick
            }

            setTimeout( function(){
              self.bindsortables()
            }, 200)   
            
          }
        })

      },

    objectiveSelect( selected ){

        let self = this

        if( selected ){

          Cockpitsapi.Verifylink( this.objective._id, selected._id )
          .then( function(response){

            if( response.data.ok ){             

              var measureids = []

              for( var i in self.objective.measures ){
                measureids.push( self.objective.measures[i]._id )
              }              

              self.objective.measures.push( selected )
              measureids.push( selected._id )

              self.objectiveupdate( 'measures', measureids, function(){

                self.objective.measureids = measureids

                self.loadobjectives()
                // update available options
               })

              //self.availableobjectives = response.data.measures
              //if( callback ){
              //  callback()
              //}

            }else{
              //alert("invalid link detected 1")
              self.invalidlinkdetected = true
            }

          }) 
        
            /*self.objective.measures.sort(function(a,b){
              if(a.name > b.name){
                console.log('sort',a.name,'>',b.name)
                return 1
              } else if(a.name < b.name){
                console.log('sort',a.name,'<',b.name)
                return -1
              }else{
                console.log('sort',a.name+'='+b.name)
                return 0
              }
            })*/
  
          this.updatetable++
        
          // self.doselected( selected._id )

        }     
      },

      availableobjectivesupdate( search, loading ){

        loading(true)

        let self = this

        var mids = this.objective.measureids


        Cockpitsapi.Getobjectives( search, mids, this.token, this.orgkey )
        .then( function(response){

          if( response.data ){
              self.availableobjectives = response.data.measures
              //console.log(self.availableobjectives)     
          }

        })
        .catch( function(){
          })
        .finally( () => {
          loading(false)
        })

      },

    ajustlengthdown(){
        /*this.$nextTick(function(){
          var height = $('.vs__dropdown-menu').height()
          var modalheight = $('.modal-body').height()

           $('.modal-body').height(height + modalheight + 'px')

          
        })*/
      },
      ajustlengthup(){
        // $('.modal-body').height('auto')
      }
  }

}

</script>