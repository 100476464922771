<template>
	<input type="text" class="form-control active-row" :value="row.name!='New option'?row.name:''" :name="row._id" :tabindex="0" @change="onchange" maxlength="70" :placeholder="row.name=='New option'?'New option':''">
</template> 
<script>

export default{
name:'portfolioFormInputtext',
props:{
	row:Object,
	rownum:Number,
	type:String
},

data(){
	return{
	}
},

mounted() {
},

watch:{			
},
methods:{

	onchange( event ){

		let op = "add"

		if( this.row.name != "" ){
			op = "edit"
		}

		this.$emit('onchange', event, this.type, op )	
	},
},
computed:{
},
components:{}
}
</script>