<script type="text/javascript"></script>

<template> 
	<div class="mb-3 editlink p-2" :id="rowkey">
	  <label :for="'textinput_'+item._id" class="form-label">
	  	{{ item.name }}
	  	<a href="#" v-if="editmode!=rowkey && canwrite" class="editicon" @click.stop.prevent="toeditmode(rowkey)"><i class="ml-2 far fa-pencil"></i></a>
	  </label>
	 
	  <input autocomplete="false" :readonly="editmode!=rowkey"  type="text" class="form-control" :id="'textinput_'+item._id" placeholder="" :name="inputname"  v-model="itemvalue" @change="withchange()" @click.stop.prevent="toeditmode(rowkey)">

	  <div class="text-right mb-2 mt-2" v-if="editmode==rowkey" >
			<button type="button" class="btn btn-secondary" @click.stop.prevent="canceledit">Cancel</button> <button type="button" class="btn btn-primary" @click.stop.prevent="onsavevalues">Save</button>
		</div>

	</div>
</template> 

<style type="text/css">
	.form-control{
		border-color: var(--N200);
	}
	.btn-secondary.dropdown-toggle{
		border-color: var(--N200);
	}
	.dropdown-menu{
		padding-top: 0;
	}
</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'

	export default{
		name:'pText',
		props:{
			canwrite:Boolean,
			viewmode:String,
			projectid:String,
			item:Object,
			editmode:String,
			rowkey:String
		},

		data(){
			return{
				portfolios:[],
				name:"",
				error:"",
				tmr:null,
				mode:'',
				itemvalue:'',
				origvalue:'',
				haschanges:0
			}
		},

		 mounted() {
		 	this.gettext()
		 },

		watch:{

			/*item : function( e ){
				alert(e)
			}*/
			
		},
		methods:{

			withchange(){
				this.haschanges++
			},

			toeditmode( toedit ){

				if( !this.canwrite ){

					if( this.editmode != this.rowkey ){
						let toview = document.getElementById(this.editmode)

						if( toview ){
							toview.classList.add("stillediting");
							toview.scrollIntoView();
						}
					}


					
					return false
				}

				this.$emit('oneditmode', toedit, 'small')
				//this.editmode=true
			},

			canceledit(){
				$('.stillediting').removeClass('stillediting')
				this.itemvalue = this.origvalue
				this.$emit('oneditmode', '')
			},

			onsavevalues(){
				let self = this
				//this.itemvalue = this.origvalue
				this.haschanges = 0

				$('.stillediting').removeClass('stillediting')

				this.$emit('onsavevalues', function(){
					self.$emit('oneditmode', '')
					self.gettext()
					self.toeditmode()
				})
			},

			/*onkeydown( element ){
				if( element.keyCode == 13 && element.keyCode == 27 ){
					this.editmode = false
				}
			},*/

			/*dosave( element ){

				this.editmode = false				
				this.$emit('onformsaved', {"value":this.itemvalue, "parameterid":this.item._id} )
				//this.itemvalue = element.target.value
			},*/

			getmounted(){

				let self = this

				/*setTimeout( function(){
					self.gettext()
				},100)*/

			},

			gettext(){

				let self = this

				if( this.item && this.item._id ){

					Portfolioapi.getFormvalue( { parameterid:this.item._id, projectid:this.projectid, token:this.token, context:this.orgkey } ).then( function( result ){

		                    if( result.data.ok ){
		                    	self.itemvalue = result.data.formvalue.value
		                    	self.origvalue = self.itemvalue
		                    }
	                })

				}				
				
			},

			gettext2( formvalue ){

				if( formvalue ){
					return formvalue.value
				}

								
				
			},

			textupdated( el ){
			}
		},

		computed:{

			inputname(){

				let toreturn = null

				if( this.haschanges > 0 ){					
					toreturn = this.item._id
				}

				return toreturn
			},

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{}
	}
	</script>