<script type="text/javascript"></script>

<template>  

	<div class="mb-3 p-2"> 
	  <label :for="'textinput_'+item._id" class="form-label">{{ item.name }}</label>
	    <div v-if="canwrite" class="pl-0">
			<b-dropdown no-caret :text="selectedoption.name" class="p-0 m-0 w-100">

				<template #button-content>
						<div class="d-flex"  >
						  <div class="flex-grow-1 text-left">	

						  {{ selectedoption.name }}				  	
						  </div>
						  <div class="">
						  	<i class="fa-solid fa-caret-down"></i>
						  </div>
						</div>
    				</template>

			  <b-dropdown-item @click="selected(defaultselectedoption)">&nbsp;</b-dropdown-item>	
			  <b-dropdown-item v-for="(option,key2) in item.options" :key="key2" @click="selected(option)">{{ option.name }}</b-dropdown-item>
			</b-dropdown>

			<input type="hidden" :name="inputname" :value="selectedoption._id">
		</div>

		<input v-else readonly type="text" class="form-control" style="border-color: var(--N200);" :value="selectedoption.name" @click="toeditmode">


	</div>

</template> 

<style type="text/css">
	

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'
	import {bus} from '@/main'

	const defaultdropdowntext = 'Value not set'

	export default{
		name:'pSelect',
		props:{
			canwrite:Boolean,
			viewmode:String,
			projectid:String,
			item:Object,
			editmode:String,
		},

		data(){
			return{
				selectedoptiontext:"",
				selectedoption:{_id:"",name:defaultdropdowntext},
				defaultselectedoption:{_id:"",name:defaultdropdowntext},
				portfolios:[],
				name:"",
				error:"",
				mode:'',
				formupdated:{},
				missingoption:{},
				inputname:null
			}
		},

		mounted() {
			 //this.gettext(this.item.formvalue.value)
			 this.getselected()
		},

		watch:{

			/*item : function( e ){
				alert(e)
			}*/
			
		},
		methods:{

			withchange(){
				this.inputname = this.item._id
			},

			toeditmode(){

				if( !this.canwrite ){

					let toview = document.getElementById(this.editmode)

					if( toview ){
						toview.classList.add("stillediting");
						toview.scrollIntoView();
					}

					return false
				}

				//this.editmode=true
			},

			selected( option ){

				let self = this

				this.selectedoption = option
				this.inputname = this.item._id

				 this.$nextTick(function(){
				 	self.$emit('onselectionupdated')
				 })

				

				//this.withchange()
				/*let sid = ""
				if( option && option._id ){
					sid = option._id
					this.gettext(option._id)
				}else{
					this.gettext('')
				}

				 
				
				this.$emit('onformsaved', {"value":sid, "parameterid":this.item._id} )

				this.editmode=false*/

			},

			gettext( value ){

				let item = this.item.options.find((item) => item._id === value);
				if( item ){
					this.selectedoptiontext = item.name
				}else{
					this.selectedoptiontext = defaultdropdowntext
				}
				
			},

			getselected(){

				let self = this

				if( this.item && this.item._id ){

					Portfolioapi.getFormvalue( { parameterid:this.item._id, projectid:this.projectid, token:this.token, context:this.orgkey } ).then( function( result ){

		                    if( result.data.ok ){								
								
		                    	let itemvalue = result.data.formvalue.value
		                    	let item = null

		                    	if( result.data.formvalue && result.data.formvalue.parameter && result.data.formvalue.parameter.options ){
		                    		item = result.data.formvalue.parameter.options.find((item) => item._id === itemvalue);
		                    	}


								if( item ){
									self.selectedoption = item
								}else{
									self.selectedoption = { _id:"",name:defaultdropdowntext }
								}
		                    }
	                })

				}
				
			},
		},

		computed:{

			/*updatedform( obj ){

				console.log( obj )
			},*/

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{}
	}
	</script>